import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProgramadorComponent } from "../../pages/programador/programador.component";
import { ThrowStmt } from "@angular/compiler";
import { UtilsService } from "../../global/utils/utils.service";
import { GLOBAL } from "src/app/global/constants/global.const";
// This lets me use jquery
declare var $: any;

@Component({
  selector: "app-modal-detalle-pedido-combinado",
  templateUrl: "./modal-detalle-pedido-combinado.component.html",
  styleUrls: ["./modal-detalle-pedido-combinado.component.css"],
})
export class ModalDetallePedidoCombinadoComponent implements OnInit, OnDestroy {
  orders: any;
  programador: ProgramadorComponent;
  motivosDesconfirmar: string[];
  comentarios: string;
  motivo: number;
  errorNoSelect: boolean;
  errorComments: boolean;
  warning: string;
  error: string;
  sessionEditPermission: boolean;

  private eventListener: any;

  constructor(private utils: UtilsService) {}

  ngOnInit() {
    this.motivosDesconfirmar = GLOBAL.MOTIVOS_DESCONFIRMACION;
    this.comentarios = "";
    this.motivo = -1;
    this.errorNoSelect = false;
    this.errorComments = false;
    this.sessionEditPermission = this.programador.sessionEditPermission;

    this.eventListener = window.addEventListener(
      "keyup",
      (e: KeyboardEvent) => {
        if (e.code == "Escape") {
          $("#oder-details-combinado-modal").modal("hide");
        }
      }
    );
  }

  show() {
    $("#oder-details-combinado-modal").modal("show");
  }
  close() {
    $("#oder-details-combinado-modal").modal("hide");
  }

  setOrderList(orders) {
    this.orders = orders;
    this.motivo = -1;
    this.comentarios = "";

    var warningsArr = [];
    orders.forEach((element) => {
      if (element["WARNING_MESSAGE"].trim() != "") {
        if (!warningsArr.includes(element["WARNING_MESSAGE"])) {
          warningsArr.push(element["WARNING_MESSAGE"]);
        }
      }
    });

    this.warning = " ";
    warningsArr.forEach((w) => {
      this.warning += w + " ";
    });

    var errorsArr = [];
    orders.forEach((element) => {
      if (element["ERROR_MESSAGE"].trim() != "") {
        if (!errorsArr.includes(element["ERROR_MESSAGE"])) {
          errorsArr.push(element["ERROR_MESSAGE"]);
        }
      }
    });

    this.error = " ";
    errorsArr.forEach((w) => {
      this.error += w + " ";
    });

    // this.error = order.ERROR_MESSAGE

    // this.warning = order.WARNING_MESSAGE
    // this.error = order.ERROR_MESSAGE
  }

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
  }

  // get the index of the array of orders. Modal can open with a given index.
  goDetail(orderIndex) {
    // lo comnto porque se cambio la firma y aun no veo esta parte
    this.programador.openOrderDetailsModal(this.orders[orderIndex]);
    // this.programador.openOrderDetailsModal(this.orders,orderIndex);
  }

  desconfirmarCombinado() {
    var plantDate = new Date(
      this.utils.swapDate(this.programador.plantData.date) + "T00:00:00"
    );
    var today = new Date();
    var commentAndReasonRequired = false;

    if (this.utils.sameDay(today, plantDate)) {
      commentAndReasonRequired = true;
    }

    this.errorNoSelect = false;
    this.errorComments = false;

    if (this.motivo == -1) {
      var dateCheck = this.programador.plantData.date;
      if (this.utils.isToday(dateCheck)) {
        this.errorNoSelect = true;
        return false;
      }
    }
    if (
      commentAndReasonRequired &&
      (this.comentarios == undefined || this.comentarios.trim() === "")
    ) {
      this.errorComments = true;
      return false;
    }

    var dataDesconfirmar = [];
    this.orders.forEach((ord) => {
      // this.programador.removeOrdersFromPlanning(this.orders)
      var infoDesconfirmar = {};
      // por lo visto con sap, en los pedidos combinados basta con uno de los
      // VBELN para desconfirmar el resto de los pedidos que conforman el p. combinado.
      infoDesconfirmar["VBELN"] = ord["VBELN"];
      infoDesconfirmar["VBTYP"] = ord["VBTYP"];
      infoDesconfirmar["MOTIVO"] = ""; // sacarla de motivos SAP
      infoDesconfirmar["SUBRC"] = "";
      infoDesconfirmar["MESSAGE"] = "";
      // infoDesconfirmar["DURACION_CALCULA"] = ""
      infoDesconfirmar["TIPO"] = "P";
      infoDesconfirmar["OBSERVACION"] = this.comentarios;
      ord.UNCONFIRM_MOTIVE = this.motivo;
      ord.UNCONFIRM_COMMENT = this.comentarios;

      infoDesconfirmar["OBJETO_PEDIDO"] = ord; // esto se lo paso al servicio y despues lo utilizo para guardar en dynamo.

      dataDesconfirmar.push(infoDesconfirmar);
    });

    this.programador.deconfirm(dataDesconfirmar);
    this.close();
  }

  darBajaCombinado() {
    this.errorNoSelect = false;
    this.errorComments = false;
    /*
    if(this.motivo==-1){
        this.errorNoSelect=true;
        return false;
    }
    */
    /* omitimos los comentarios para dar de baja
    if(this.comentarios==undefined || this.comentarios.trim()===""){
      this.errorComments=true;
      return false;
    }
    */
    var comentariosDarBaja = "";
    var motivoBaja = "";
    this.programador.darBaja(this.orders[0], comentariosDarBaja, motivoBaja);

    this.orders.forEach((ord) => {
      ord["VEHICLE"] = " ";
      ord["AGRUPADOR"] = " ";
      ord["POS_AGRUPADOR"] = "0";
    });

    this.close();
  }

  selectChanged() {
    // this.desconfirmarActivado=true;
  }
  textAreaChanged() {}
  desconfirmarActivado() {
    return this.motivo == -1 ? false : true;
  }
  isConfirmed() {
    var confirmado = true;
    this.orders.forEach((ord) => {
      if (ord["ESTADO"] != "3") {
        confirmado = false;
      }
    });
    return confirmado;
  }

  ngOnDestroy(): void {
    window.removeEventListener("keyup", this.eventListener);
  }
}
