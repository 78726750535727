import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import {
  ClearRequest,
  ConfirmChangeTurnDataRequest,
  GetPlanRequest,
  NewProposalDataRequest,
  PostPlanRequest,
  RegisterShiftTruckDataRequest,
  UnconfirmDataRequest,
  UnsubscribeTurn,
} from "../models/requests.model";
import { ENDPOINTS_PROGRAMADOR } from "../constants/endpoints.const";
import { catchError, timeout, retry, filter, finalize } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiProgramadorService {
  apiUrl = environment.apiURL;
  optimizeUrl = environment.optimizerUrl;

  constructor(private http: HttpClient) {}

  private handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  public clear(
    plantCode: string,
    date: string,
    token: string,
    sapUser: string,
    sapPwd: string
  ): Observable<any> {
    const data: ClearRequest = {
      codigo_fecha: date,
      codigo_planta: plantCode,
      sap_user: sapUser,
      sap_pwd: sapPwd,
    };

    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_CLEAR, data)
      .pipe(catchError(this.handleError));
  }

  public sendSqs(request: any): Observable<any> {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_RECEIVER_SQS, request)
      .pipe(catchError(this.handleError));
  }

  public getSqsResult(messageId: string): Observable<any> {
    const data = {
      msg_id: messageId,
    };

    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_CONSUMER_SQS, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getSqsModifications(payload): Observable<any> {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_modifications_SQS, payload)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getPlan(
    plantCode: string,
    date: string,
    sapUser: string,
    sapPwd: string
  ): Observable<any> {
    const data: GetPlanRequest = {
      codigo_fecha: date,
      codigo_planta: plantCode,
      sap_user: sapUser,
      sap_pwd: sapPwd,
    };

    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_GET_PLAN, data)
      .pipe(catchError(this.handleError));
  }

  public savePlan(data: PostPlanRequest) {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_SAVE_PLAN, data)
      .pipe(catchError(this.handleError));
  }

  public confirmar(data: PostPlanRequest) {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_CONFIRM, data)
      .pipe(catchError(this.handleError));
  }

  public confirmarCambioTurno(data: ConfirmChangeTurnDataRequest) {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_CONFIRM_TURN_CHANGE, data)
      .pipe(catchError(this.handleError));
  }

  public desconfirmar(data: UnconfirmDataRequest) {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_UNCONFIRM, data)
      .pipe(catchError(this.handleError));
  }

  public nuevaPropuesta(data: NewProposalDataRequest) {
    const minutes = 5;
    return this.http
      .post(this.optimizeUrl, data)
      .pipe(timeout(minutes * 60 * 1000), catchError(this.handleError));
  }

  public registerShiftTruckChange(data: RegisterShiftTruckDataRequest) {
    return this.http
      .post(
        this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_REG_SHIFT_TRUCK_CHANGE,
        data
      )
      .pipe(catchError(this.handleError));
  }

  public listaPedidosTruck(data: UnsubscribeTurn) {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_TRUCK_ORDER_LIST, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getResponse(url: string) {
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  private getTimes(origin, destination, plant_id) {
    return this.http
      .get(`${this.apiUrl}${ENDPOINTS_PROGRAMADOR.GET_TRAVEL_TIMES}`, {
        params: {
          origin,
          destination,
          plant_id,
        },
      })
      .pipe(retry(2), catchError(this.handleError))
      .toPromise();
  }

  public async getTimesForSchedule(origen, destino, plant_id) {
    try {
      const data = await this.getTimes(origen, destino, plant_id);
      return data;
    } catch (error) {
      throw error;
    }
  }

  public sessionState(payload) {
    return this.http
      .post(
        `${this.apiUrl}${ENDPOINTS_PROGRAMADOR.POST_SESSION_STATE}`,
        payload
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  public longTrips(payload) {
    return this.http
      .post(`${this.apiUrl}${ENDPOINTS_PROGRAMADOR.POST_LONG_TRIPS}`, payload)
      .pipe(retry(1), catchError(this.handleError));
  }

  public postApiConfirmaLectura(payload): Observable<any> {
    return this.http
      .post(this.apiUrl + ENDPOINTS_PROGRAMADOR.POST_CONFIRMA_LECTURA, payload)
      .pipe(retry(1), catchError(this.handleError));
  }
}
