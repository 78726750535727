import { Injectable } from "@angular/core";
import _ from "lodash";
import { MESSAGES } from "../constants/messages.const";
import { SessionService } from "src/app/global/services/session.service";
import { ApiProgramadorService } from "../../pages/programador/services/api-programador.service";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor(
    private sessionService: SessionService,
    private api: ApiProgramadorService
  ) {}

  minutesToMilliseconds(minutes) {
    return Math.trunc(minutes * 60 * 1000);
  }

  dateObjToYYYYMMDD(dateObj) {
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return this.dateToyyyymmdd(day + " " + month + "-" + year);
  }

  dateObjToHHMMSS(dateobj) {
    const colonHHMMSS = this.getHHMMSS(
      dateobj.getHours(),
      dateobj.getMinutes(),
      dateobj.getSeconds()
    );
    const hhmmssArr = colonHHMMSS.split(":");
    return hhmmssArr[0] + hhmmssArr[1] + hhmmssArr[2];
  }

  dateToTimestamp(ddmmyy) {
    const ddmmyySplitted = ddmmyy.split("-");
    const mm = ddmmyySplitted[1];
    const dd = ddmmyySplitted[0];
    const yy = ddmmyySplitted[2];
    const dateUS = mm + "-" + dd + "-" + yy;
    const date = new Date(dateUS);
    return date.getTime();
  }

  sameDay(date1, date2) {
    const d1Day = date1.getDate();
    const d1Month = date1.getMonth();
    const d1Year = date1.getFullYear();
    const d2Day = date2.getDate();
    const d2Month = date2.getMonth();
    const d2Year = date2.getFullYear();
    if (d1Day === d2Day && d1Month === d2Month && d1Year === d2Year) {
      return true;
    }
    return false;
  }

  estimarTiempoLlegada(duracion) {
    const time = this.hhmmssSeparateWithColons(duracion);
    const h = Number(time.split(":")[0]);
    const m = Number(time.split(":")[1]);
    const s = Number(time.split(":")[2]);

    const duracionMinutes = h * 60 + m * 1 + s / 60;
    return (duracionMinutes - 60) / 2 + 30;
  }

  obtenerDuracionTramoSinCarga(duracion) {
    return this.estimarTiempoLlegada(duracion) - 30;
  }

  getHHMMSS(h, m, s) {
    const hh = h < 10 ? "0" + h : h + "";
    const mm = m < 10 ? "0" + m : m + "";
    const ss = s < 10 ? "0" + s : s + "";
    return hh + ":" + mm + ":" + ss;
  }

  millisecondsToDateAndTimeSap(milliseconds) {
    const d = new Date(milliseconds);
    const hh = d.getHours() < 10 ? "0" + d.getHours() : d.getHours().toString();
    const mm =
      d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes().toString();
    const ss =
      d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds().toString();

    const timeToReturn = hh + mm + ss;

    const trueMonth = d.getMonth() + 1;
    const year = d.getFullYear();
    const month = trueMonth < 10 ? "0" + trueMonth : trueMonth.toString();
    const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate().toString();

    const dateToReturn = year + month + day;

    return {
      date: dateToReturn,
      time: timeToReturn,
    };
  }

  millisecondsToHHMMSS(milliseconds) {
    const seconds = milliseconds / 1000;
    const h = Math.floor(seconds / 3600);
    const secondsRemaining = seconds % 3600;

    const m = Math.floor(secondsRemaining / 60);
    const s = Math.trunc(secondsRemaining % 60);

    const hh = h < 10 ? "0" + h : h.toString();
    const mm = m < 10 ? "0" + m : m.toString();
    const ss = s < 10 ? "0" + s : s.toString();

    return hh + ":" + mm + ":" + ss;
  }

  hhmmssToMinutes(orderLength: string) {
    const splittedTime = orderLength.split(":");
    const hoursInMinutes = parseInt(splittedTime[0], 10) * 60;
    const minutes = parseInt(splittedTime[1], 10);
    const secondsInMinutes = parseInt(splittedTime[2], 10) >= 30 ? 1 : 0;

    const ret = hoursInMinutes + minutes + secondsInMinutes;
    return ret;
  }

  extractText(msgText: string) {
    let message = "";
    if (msgText === null) {
      return "";
    }

    const item = _.get(msgText, "item");
    if (Array.isArray(item)) {
      item.forEach((itm) => {
        let line = " ";
        if (_.get(itm, "Tdline") !== null) {
          line = _.get(itm, "Tdline");
        }
        let separador = " ";
        if (_.get(itm, "Tdformat") === "*") {
          separador = "\n";
        }

        message += line + separador;
      });
    } else {
      let line = " ";
      if (_.get(item, "Tdline") !== null) {
        line = _.get(item, "Tdline");
      }
      let separador = " ";
      if (_.get(item, "Tdformat") === "*") {
        separador = "\n";
      }

      message += line + separador;
    }

    return message;
  }

  swapDate(date: string) {
    const spl = date.split("-");
    return spl["2"] + "-" + spl["1"] + "-" + spl["0"];
  }

  dbDateTimeToTimestamp(yyyymmdd, hhmmss) {
    const date = this.yyyymmddToDate(yyyymmdd);
    const year = parseInt(date.split("-")[2]);
    const day = parseInt(date.split("-")[0]);
    const month = parseInt(date.split("-")[1]) - 1;

    const hour = this.hhmmmmToHour(hhmmss);
    const minute = this.hhmmmmToMinute(hhmmss);
    const seconds = this.hhmmmmToSeconds(hhmmss);

    const dateTime = new Date(year, month, day, hour, minute, seconds);
    return dateTime.getTime();
  }

  formatDataToSap(pedidoSrc: any) {
    let pedido = {};
    Object.assign(pedido, pedidoSrc);

    if (pedido["PRO_BEGDA"] != null) {
      pedido["PRO_BEGDA"] = this.yyyymmddToDateSap(pedido["PRO_BEGDA"]);
    }

    if (pedido["PRO_BEGTI"] != null) {
      pedido["PRO_BEGTI"] = this.hhmmssToSap(pedido["PRO_BEGTI"]);
    }

    if (pedido["PRO_ENDDA"] != null) {
      pedido["PRO_ENDDA"] = this.yyyymmddToDateSap(pedido["PRO_ENDDA"]);
    }

    if (pedido["PRO_ENDTI"] != null) {
      pedido["PRO_ENDTI"] = this.hhmmssToSap(pedido["PRO_ENDTI"]);
    }

    if (pedido["DURACION"] != null) {
      pedido["DURACION"] = this.hhmmssToSap(pedido["DURACION"]); // ahora no se escribe en sap, pero tal vez en un futuro.
    }

    if (pedido["DURACION_CALCULA"] != null) {
      pedido["DURACION_CALCULA"] = this.hhmmssToSap(pedido["DURACION_CALCULA"]);
    }

    if (pedido["DURACION_GRUPO"] != null) {
      pedido["DURACION_GRUPO"] = this.hhmmssToSap(pedido["DURACION_GRUPO"]);
    }

    if (pedido["CONFIR_START_DATE"] != null) {
      pedido["CONFIR_START_DATE"] = this.yyyymmddToDateSap(
        pedido["CONFIR_START_DATE"]
      );
    }

    if (pedido["CONFIR_START_TIME"] != null) {
      pedido["CONFIR_START_TIME"] = this.hhmmssToSap(
        pedido["CONFIR_START_TIME"]
      );
    }

    if (pedido["RUTA"] != null) {
      pedido["RUTA"] = this.hhmmssToSap(pedido["RUTA"]);
    }

    return pedido;
  }

  hhmmssToSap(hhmmss) {
    const h =
      this.hhmmmmToHour(hhmmss) < 10
        ? "0" + this.hhmmmmToHour(hhmmss)
        : this.hhmmmmToHour(hhmmss);
    const m =
      this.hhmmmmToMinute(hhmmss) < 10
        ? "0" + this.hhmmmmToMinute(hhmmss)
        : this.hhmmmmToMinute(hhmmss);
    const s =
      this.hhmmmmToSeconds(hhmmss) < 10
        ? "0" + this.hhmmmmToSeconds(hhmmss)
        : this.hhmmmmToSeconds(hhmmss);
    return h + ":" + m + ":" + s;
  }

  dateToSapformat(ddmmyyyyDate) {
    const splDate = ddmmyyyyDate.split("-");
    const year = splDate[2];
    const month = splDate[1];
    const day = splDate[0];

    return year + "-" + month + "-" + day;
  }

  yyyymmddToDateSap(yyyymmdd) {
    const year = yyyymmdd.substring(0, 4);
    const month = yyyymmdd.substring(4, 6);
    const day = yyyymmdd.substring(6, 8);
    return year + "-" + month + "-" + day;
  }

  yyyymmddToDate(yyyymmdd) {
    const year = yyyymmdd.substring(0, 4);
    const month = yyyymmdd.substring(4, 6);
    const day = yyyymmdd.substring(6, 8);
    return day + "-" + month + "-" + year;
  }

  hhmmmmToHour(hhmmss) {
    return hhmmss.substring(0, 2) * 1;
  }

  hhmmmmToMinute(hhmmss) {
    return hhmmss.substring(2, 4) * 1;
  }

  hhmmmmToSeconds(hhmmss) {
    return hhmmss.substring(4, 6) * 1;
  }

  yyyymmddToYear(yyyymmdd) {
    return yyyymmdd.substring(0, 4);
  }

  yyyymmddToMonth(yyyymmdd) {
    return yyyymmdd.substring(4, 6);
  }

  yyyymmddToDay(yyyymmdd) {
    return yyyymmdd.substring(6, 8);
  }

  hhmmssSeparateWithColons(hhmmss) {
    const h = hhmmss.substring(0, 2); // *1;
    const m = hhmmss.substring(2, 4); // *1;
    const s = hhmmss.substring(4, 6); // *1;
    return h + ":" + m + ":" + s;
  }

  dateToyyyymmdd(date) {
    let m = date.split("-")[1];
    let d = date.split("-")[0];
    let y = date.split("-")[2];
    if (d * 1 < 10) {
      d = "0" + d * 1;
    }
    if (m * 1 < 10) {
      m = "0" + m * 1;
    }

    let ret = y + m + d;
    return ret;
  }

  hhmmTohhmmss(hour, minute) {
    var h = hour;
    var m = minute;
    if (h * 1 < 10) {
      h = "0" + h * 1;
    }
    if (m * 1 < 10) {
      m = "0" + m * 1;
    }
    let ret = h + "" + m + "00";

    return ret;
  }

  generateDate(yyyymmdd, hhmmss) {
    let year = yyyymmdd.substring(0, 4) * 1;
    let month = yyyymmdd.substring(4, 6) * 1 - 1;
    let day = yyyymmdd.substring(6, 8) * 1;

    let hours = hhmmss.substring(0, 2) * 1;
    let minutes = hhmmss.substring(2, 4) * 1;
    let seconds = hhmmss.substring(4, 6) * 1;

    var date = new Date(year, month, day, hours, minutes, seconds);
    return date;
  }

  // recibe una fecha formato yyyymmdd, una hora hhmmss y una cantidad de horas hhmmss_delta a sumar
  // retorna la fecha calculada en formato yyyymmdd-hhmmss
  sumDates(yyyymmdd, hhmmss, hhmmss_delta) {
    let hours_add = hhmmss_delta.substring(0, 2) * 1;
    let minutes_add = hhmmss_delta.substring(2, 4) * 1;
    let seconds_add = hhmmss_delta.substring(4, 6) * 1;
    var date = this.generateDate(yyyymmdd, hhmmss);
    date.setHours(date.getHours() + hours_add);
    date.setMinutes(date.getMinutes() + minutes_add);
    date.setSeconds(date.getSeconds() + seconds_add);
    let date_end =
      date.getDate() +
      "-" +
      (date.getMonth() * 1 + 1) +
      "-" +
      date.getFullYear();
    let yyyymmdd_ret = this.dateToyyyymmdd(date_end);
    let hhmmss_ret = this.hhmmTohhmmss(date.getHours(), date.getMinutes());
    let ret = yyyymmdd_ret + "-" + hhmmss_ret;

    return ret;
  }

  // si un minuto es 0 a 29, entrega 0. Si minuto es 30 a 59, entrega 30
  // se usa para calculos con la grilla y su resolucion
  adjustMinuteToCellResolution(minute) {
    if (minute * 1 < 30) {
      return 0;
    }
    return 30;
  }

  floatMinutesTohhmmss(floatMinutes) {
    var h = Math.floor(floatMinutes / 60);
    var m = Math.floor(floatMinutes % 60);

    var s = Math.floor(((floatMinutes % 60) - m) * 60);

    var hh = h < 10 ? "0" + h : h + "";
    var mm = m < 10 ? "0" + m : m + "";
    var ss = s < 10 ? "0" + s : s + "";

    return hh + mm + ss;
  }

  minutesTohhmmss(mins) {
    var h = Math.floor(mins / 60);
    var m = mins % 60;
    var hh = h + "";
    var mm = m + "";
    if (h < 10) {
      hh = "0" + hh;
    }

    if (m < 10) {
      mm = "0" + mm;
    }
    var ret = hh + mm + "00";

    return ret;
  }

  /// sometimes date can have one or two digists in dd-mm-yyyy.
  // normalize always with 2 digits
  normalizeDateOneDigit(date) {
    var splt = date.split("-");
    var dd = splt[0];
    var mm = splt[1];
    var yyyy = splt[2];

    if (dd * 1 < 10) {
      dd = "0" + dd * 1;
    }
    if (mm * 1 < 10) {
      mm = "0" + mm * 1;
    }

    return dd + "-" + mm + "-" + yyyy;
  }

  modeldateToDate(modelDAte) {
    var dd = modelDAte["day"];
    var mm = modelDAte["month"];
    var yyyy = modelDAte["year"];

    if (dd * 1 < 10) {
      dd = "0" + dd * 1;
    }
    if (mm * 1 < 10) {
      mm = "0" + mm * 1;
    }

    return dd + "-" + mm + "-" + yyyy;
  }

  dateEquals(orderStartDate, plantDate) {
    if (
      this.normalizeDateOneDigit(orderStartDate) ==
      this.normalizeDateOneDigit(plantDate)
    ) {
      return true;
    }
    return false;
  }

  // truck capacity is an array with capacities. ['5','5/7'] etc..
  generateVolumenDetailMin(truckCapacity) {
    var arrCapacityDetail = [];
    truckCapacity.forEach((element) => {
      var spl = element.split("/");
      var cap = spl[0];
      if (spl.length > 1) {
        cap = Math.min(spl[0], spl[1]);
      }
      arrCapacityDetail.push(cap * 1000);
    });
    return arrCapacityDetail;
  }

  generateVolumenDetailMax(truckCapacity) {
    var arrCapacityDetail = [];
    truckCapacity.forEach((element) => {
      var spl = element.split("/");
      var cap = spl[0];
      if (spl.length > 1) {
        cap = Math.max(spl[0], spl[1]);
      }
      arrCapacityDetail.push(cap * 1000);
    });
    return arrCapacityDetail;
  }

  sumCapacity(capacityDetail) {
    var sum = 0;
    capacityDetail.forEach((e) => {
      sum += e;
    });
    return sum;
  }

  // Verifica que pedido que requiere de cuenta litros esta asignado a camion con cuenta litros.
  checkCuentaLitros(truck, plannedOrder) {
    if (
      truck.countLiter == false &&
      plannedOrder["CUENTA_LITROS"].toLowerCase() === "c"
    ) {
      return false;
    }
    return true;
  }

  // Verifica que volumen total de pedido no sea mayor al volumen soportado por camion
  checkConditionMaxTruckCapacity(truck, order) {
    // nos fijamos en la capacidad total que quepa.
    var truckCapacity = this.sumCapacity(
      this.generateVolumenDetailMax(truck.capacity)
    );
    var orderCapacity = 0;
    order.DETALLE_PEDIDO.forEach((e) => {
      if (order.ESTADO == 3 && order.DINAMO == "X") {
        orderCapacity += e.BMENG.split(".")[0] * 1;
      } else {
        orderCapacity += e.KWMENG.split(".")[0] * 1;
      }
    });
    if (truckCapacity < orderCapacity) {
      return false;
    }
    return true;
  }

  // Verifica que volumen total de pedido no sea distinto al volumen soportado por camion
  checkConditionTruckCapacity(truck, order) {
    // nos fijamos en la capacidad total que quepa.
    var truckCapacity = this.sumCapacity(
      this.generateVolumenDetailMax(truck.capacity)
    );
    var orderCapacity = 0;
    order.DETALLE_PEDIDO.forEach((e) => {
      orderCapacity += e.KWMENG.split(".")[0] * 1;
    });
    if (truckCapacity != orderCapacity) {
      return false;
    }
    return true;
  }

  checkToShowRedisButton(truck, order): boolean {
    var truckCapacity = this.sumCapacity(
      this.generateVolumenDetailMax(truck.capacity)
    );
    var orderCapacity = 0;
    order.DETALLE_PEDIDO.forEach((e) => {
      orderCapacity += e.KWMENG.split(".")[0] * 1;
    });
    if (truckCapacity >= orderCapacity) {
      return true;
    } else {
      return false;
    }
  }

  // Verifica que el pedio se encuentra dentro del turno. Si una parte del pedido se encuentra fuera del turno (en un lugar no disponible), arroja el warning
  checkOrderInsideShift(parentCell, plannedOrder) {
    // si la orden cuya cell padre es no disponible, es porque la orden se encuentra fuera de un turno.
    if (parentCell.available === false) {
      return false;
    }

    var progEndDate = plannedOrder.PRO_ENDDA;
    var progEndTime = plannedOrder.PRO_ENDTI;
    var progEndTimestamp = new Date(
      this.yyyymmddToYear(progEndDate),
      this.yyyymmddToMonth(progEndDate) * 1 - 1,
      this.yyyymmddToDay(progEndDate),
      this.hhmmmmToHour(progEndTime),
      this.hhmmmmToMinute(progEndTime)
    );

    var pcEndYear = parentCell.endDate.split("-")[2];
    var pcEndMonth = parentCell.endDate.split("-")[1];
    var pcEndDay = parentCell.endDate.split("-")[0];

    var shiftEndTime = new Date(
      pcEndYear,
      pcEndMonth * 1 - 1,
      pcEndDay,
      parentCell.endHour,
      parentCell.endMinute
    ); // Ok

    if (progEndTimestamp > shiftEndTime) {
      return false;
    }

    return true;
  }

  getTimestampFromDateTime(date_, time_) {
    var theTimestamp = new Date(
      this.yyyymmddToYear(date_),
      this.yyyymmddToMonth(date_) * 1 - 1,
      this.yyyymmddToDay(date_),
      this.hhmmmmToHour(time_),
      this.hhmmmmToMinute(time_)
    );
    return theTimestamp;
  }
  // Verifica que el pedido se encuentre en la ventana. La regla es, si el principio del pedido se encuentra sobre la ventana o el final del pedido programado se encuentra antes del inicio de la
  // ventana, entonces no se cumple la condicion.

  checkHoraEntregaPedidoSimple(plannedOrder) {
    // si DELCO es 004, da lo mismo la hora siempre la hora de entrega es valida.
    if (plannedOrder.DELCO === "004") {
      return true;
    }
    // codes
    // DELCO: 001  08:00-14:00 AM
    // DELCO: 002  14:00-20:00 PM
    // DELCO: 003  20:00-23:59 Noche
    // DELCO: 004  00:00-23:59 Cualquiera

    // DELCO: 005  08:00-18:00 Habil
    // DELCO: 006  00:00-08:00 Madrugada

    // calculamos timestamp de entrega de orden
    // los tiempo se entrega determinan el periodo de ventana donde quiero que el pedido se entregue
    var beg_date = plannedOrder.ENT_BEGDA;
    var beg_time = plannedOrder.ENT_BEGTI;

    var end_date = plannedOrder.ENT_ENDDA;
    var end_time = plannedOrder.ENT_ENDTI;

    var entBegTimestamp = new Date(
      this.yyyymmddToYear(beg_date),
      this.yyyymmddToMonth(beg_date) * 1 - 1,
      this.yyyymmddToDay(beg_date),
      this.hhmmmmToHour(beg_time),
      this.hhmmmmToMinute(beg_time)
    );
    var entEndTimestamp = new Date(
      this.yyyymmddToYear(end_date),
      this.yyyymmddToMonth(end_date) * 1 - 1,
      this.yyyymmddToDay(end_date),
      this.hhmmmmToHour(end_time),
      this.hhmmmmToMinute(end_time)
    );

    // calculamos timestamp de programacion
    var progBegDate = plannedOrder.PRO_BEGDA;
    var progBegTime = plannedOrder.PRO_BEGTI;

    // es lo que dura en ir de planta a cliente, + 30 minutos que considera la carga
    var tiempoLlegadaClienteEstimada = this.estimarTiempoLlegada(
      plannedOrder["DURACION"]
    );
    // este tiempo en minutos se lo debemos sumar a proBerTimeStamp y ver si esta en la ventana.

    var progBegTimestamp = new Date(
      this.yyyymmddToYear(progBegDate),
      this.yyyymmddToMonth(progBegDate) * 1 - 1,
      this.yyyymmddToDay(progBegDate),
      this.hhmmmmToHour(progBegTime),
      this.hhmmmmToMinute(progBegTime)
    );
    var llegadaEstimadaTimestamp = new Date(
      progBegTimestamp.getTime() + tiempoLlegadaClienteEstimada * 60000
    );
    // si la llegada estimada se encuentra en el periodo ventana.
    if (
      entBegTimestamp <= llegadaEstimadaTimestamp &&
      llegadaEstimadaTimestamp <= entEndTimestamp
    ) {
      return true;
    }
    return false;
  }

  // Verifica que el tipo de combustible del pedido coincida con el tipo de combustible del camion.
  checkTipoCombustible(truckFuelType, OrderFuelType) {
    if (truckFuelType == OrderFuelType) {
      return true;
    }
    return false;
  }

  // check de pedido sobre una marca de turno (raya gris... )
  checkPedidoOnShiftMark(parentCell, plannedOrder, minuteCells) {
    var timeStampIni = this.dbDateTimeToTimestamp(
      plannedOrder.PRO_BEGDA,
      plannedOrder.PRO_BEGTI
    );
    var timeStampEnd = this.dbDateTimeToTimestamp(
      plannedOrder.PRO_ENDDA,
      plannedOrder.PRO_ENDTI
    );

    var matchCells = minuteCells.filter(
      (e) =>
        e.truck == plannedOrder.VEHICLE &&
        this.cellTimeToTimestamp(e.date, e.hour, e.minute) >= timeStampIni &&
        this.cellTimeToTimestamp(e.date, e.hour, e.minute) <= timeStampEnd
    );
    var changeShiftCells = matchCells.filter((e) => e.isMod12 == true);

    if (changeShiftCells.length > 0) {
      return false;
    }
    return true;
  }

  // true if not blockead
  checkBlocked(order) {
    if (order.CMGST != "B") {
      return true;
    }
    return false;
  }

  /// OVER THIS VALIDATED
  // not being used
  checkConditionDateToday(orderStartDate, plantDate) {
    if (this.dateEquals(orderStartDate, plantDate)) {
      // // console.log('se cumple condicion de fecha')
      return true;
    }
    // // console.log('No se cumple condicion de fecha')
    return false;
  }

  private cellTimeToTimestamp(date, hour, minute) {
    var ndate = this.normalizeDateOneDigit(date);
    var year = parseInt(ndate.split("-")[2]);
    var day = parseInt(ndate.split("-")[0]);
    var month = parseInt(ndate.split("-")[1]) - 1;

    var dateTime = new Date(year, month, day, hour, minute);

    return dateTime.getTime();
  }

  detalleToInt(CapacidadDetalle) {
    if (CapacidadDetalle.includes(".")) {
      return CapacidadDetalle.split(",")[0].split(".")[0] * 1;
    } else if (CapacidadDetalle.split(",")[0].length === 3) {
      return CapacidadDetalle.split(",")[0].split(".")[0] * (1 / 1000);
    }

    return CapacidadDetalle.split(",")[0].split(".")[0] * 1;
  }

  // order 1 is dropped over order2
  checkCombinedCapacity(programador, pedidosAgrupados) {
    // de order 2 obtengo el camion y hago check que el total de las capacidades sea igual a la capac del camionn

    var sumCapacity = 0;
    pedidosAgrupados.forEach((element) => {
      sumCapacity += this.detalleToInt(element.DETALLE);
    });
    var vehicle = programador.getTruck(pedidosAgrupados[0].VEHICLE)[0];
    if (vehicle == undefined) {
      var vArr = programador.getTruck(pedidosAgrupados[0].VEHICLE);
      return false;
    }

    return sumCapacity == vehicle.FULL_CAPACITY;
  }

  checkMaxCombinedCapacity(programador, pedidosAgrupados) {
    let truck = programador.getTruck(pedidosAgrupados[0].VEHICLE)[0];
    var sumFuel = 0;
    pedidosAgrupados.forEach((pedido) => {
      sumFuel += this.detalleToInt(pedido.DETALLE);
    });
    if (truck.FULL_CAPACITY < sumFuel) {
      return false;
    }
    return true;
  }

  // order 1 is dropped over order2
  checkCombinedFuelType(programador, pedidosAgrupados) {
    // de order2, obtengo el camion y tipo de combustible y veo que sean compatibles.

    var vehicle = programador.getTruck(pedidosAgrupados[0].VEHICLE)[0];
    if (vehicle == undefined) {
      var vArr = programador.getTruck(pedidosAgrupados[0].VEHICLE);

      return false;
    }

    var tipoCombustibleCamion = vehicle.fuelTypeCode;
    var tipoCoincide = true;

    pedidosAgrupados.forEach((element) => {
      if (element.GROUPNAME != tipoCombustibleCamion) {
        tipoCoincide = false;
      }
    });
    return tipoCoincide;
  }

  validateOrderConditions(
    programador,
    newParentElement,
    orderToEvaluate,
    showAlert
  ) {
    var singleOrder = true;
    if (
      orderToEvaluate["pedidosCombinados"] != undefined &&
      orderToEvaluate["pedidosCombinados"].length > 1
    ) {
      singleOrder = false;
    }

    // limpiamos la orden
    if (singleOrder) {
      orderToEvaluate.plannedOrder["ERRORS"] = " ";
      orderToEvaluate.plannedOrder["WARNINGS"] = " ";
      orderToEvaluate.plannedOrder["WARNING_MESSAGE"] = " ";
      orderToEvaluate.plannedOrder["ERROR_MESSAGE"] = " ";
    } else {
      orderToEvaluate["pedidosCombinados"].forEach((e) => {
        e["ERRORS"] = " ";
        e["WARNINGS"] = " ";
        e["WARNING_MESSAGE"] = " ";
        e["ERROR_MESSAGE"] = " ";
      });
    }

    var WARNING_MESSAGE = " ";
    var ERROR_MESSAGE = " ";

    // si se trata de un pedido simple
    if (singleOrder) {
      if (!this.checkBlocked(orderToEvaluate.plannedOrder)) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          "El pedido " +
            orderToEvaluate.plannedOrder["VBELN"] +
            " se encuentra bloqueado."
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      if (
        !this.checkCuentaLitros(
          programador.trucks.filter(
            (e) => e.truckId == newParentElement.truck
          )[0],
          orderToEvaluate.plannedOrder
        )
      ) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.ORDER_REQUIRE_LTR_COUNT_TRUCK + " "
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      if (
        !this.checkConditionTruckCapacity(
          programador.trucks.filter(
            (e) => e.truckId == newParentElement.truck
          )[0],
          orderToEvaluate.plannedOrder
        )
      ) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.ORDER_CAPACITY_NOT_SAME_TRUCK + " "
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      if (
        !this.checkConditionMaxTruckCapacity(
          programador.trucks.filter(
            (e) => e.truckId == newParentElement.truck
          )[0],
          orderToEvaluate.plannedOrder
        )
      ) {
        if (!this.checkDistribution(orderToEvaluate.plannedOrder.VBELN)) {
          ERROR_MESSAGE = ERROR_MESSAGE.concat(
            MESSAGES.PROGRAMADOR.ORDER_CAPACITY_EXCEEDS_TRUCK + " "
          );
          orderToEvaluate.setErrorsToS(ERROR_MESSAGE);
          // programador.openAlert('danger',ERROR_MESSAGE)
          if (showAlert) {
            programador.openAlert("danger", ERROR_MESSAGE);
          }
        }
      }

      if (orderToEvaluate.plannedOrder["overlapped"] == true) {
        ERROR_MESSAGE = ERROR_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.ORDER_OVERLAPED + " "
        );
        orderToEvaluate.setErrorsToS(ERROR_MESSAGE);
        // programador.openAlert('danger',ERROR_MESSAGE)
        if (showAlert) {
          programador.openAlert("danger", ERROR_MESSAGE);
        }
      }

      // todo DEBIera haber un check que tire a rojo si agendas al dia de ayer

      // Check si parte del pedido se encuentra fuera del turno
      if (
        !this.checkOrderInsideShift(
          newParentElement,
          orderToEvaluate.plannedOrder
        )
      ) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.ORDER_OUT_OF_TURN + " "
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      // verificamos que el pedido coincida con la hora de entrega
      if (!this.checkHoraEntregaPedidoSimple(orderToEvaluate.plannedOrder)) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.DELIVERY_TIME_NOT_MATCH + " "
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      if (
        !this.checkTipoCombustible(
          programador.trucks.filter(
            (e) => e.truckId == newParentElement.truck
          )[0].fuelTypeCode,
          orderToEvaluate.plannedOrder["GROUPNAME"]
        )
      ) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.ORDER_FUEL_NOT_SAME_TRUCK + " "
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      if (
        !this.checkPedidoOnShiftMark(
          newParentElement,
          orderToEvaluate.plannedOrder,
          programador.minuteCells
        )
      ) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.ORDER_IN_TURN_CHANGE + " "
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      // Check para verificar si existe distribución de combustible en camiones de menor capacidad para pedidos Dinamo
      if (this.checkDistribution(orderToEvaluate.plannedOrder.VBELN)) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.ORDER_WITH_FUEL_DISTRIBUTION + " "
        );
        orderToEvaluate.setWarningToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }
    } else {
      // chequeamos condiciones para pedido combinado
      if (
        !this.checkMaxCombinedCapacity(
          programador,
          orderToEvaluate["pedidosCombinados"]
        )
      ) {
        // console.log('entro en if de max capacidad');
        ERROR_MESSAGE = ERROR_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.COMBINED_ORDERS_OVER_VEHICULE_CAPACITY
        );
        orderToEvaluate.setErrorsCombinadoToS(ERROR_MESSAGE);
        if (showAlert) {
          programador.openAlert("danger", ERROR_MESSAGE);
        }
      }

      if (
        !this.checkCombinedCapacity(
          programador,
          orderToEvaluate["pedidosCombinados"]
        )
      ) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.COMBINED_ORDERS_NOT_SAME_VEHICULE_CAPACITY
        );
        orderToEvaluate.setWarningCombinadoToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }

      if (
        !this.checkCombinedFuelType(
          programador,
          orderToEvaluate["pedidosCombinados"]
        )
      ) {
        WARNING_MESSAGE = WARNING_MESSAGE.concat(
          MESSAGES.PROGRAMADOR.COMBINED_ORDERS_FUEL_NOT_SAME_TYPE_VEHICULE
        );
        orderToEvaluate.setWarningCombinadoToS(WARNING_MESSAGE);
        if (showAlert) {
          programador.openAlert("warning", WARNING_MESSAGE);
        }
      }
    }
  }

  checkDistribution(vbeln: any) {
    const distrList: any = this.sessionService.getDistrOrders();
    if (distrList === null || distrList.length === 0) {
      return false;
    }
    const result = _.find(distrList, { VBELN: vbeln });
    if (result !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  calculatePositionsShadow(match, order) {
    // LOGICA DE RENDER DE SHADOW DEL CUADRADO UPPER LEFT PARA HIGHLIGHT
    var UL_dr_y = match[0].getBoundingClientRect().top;
    var coord_obj = {
      UL_dr_x: match[0].getBoundingClientRect().left,
      UL_dr_y: UL_dr_y,
    };
    //

    // LOGICA DE RENDER DE SHADOW DEL CUADRADO UPPER RIGHT  PARA HIGHLIGHT
    // var numberCellsDuracion = 16; //sacarla en base a la dur de lpedido
    coord_obj["UR_dl_y"] = coord_obj["UL_dr_y"];
    coord_obj["UR_dl_x"] = coord_obj["UL_dr_x"] + order.orderLengthPx + "px";

    // // console.log(coord_obj['UR_dl_x'])
    coord_obj["DL_ul_y"] =
      parseInt(coord_obj["UR_dl_y"]) +
      match[0].getBoundingClientRect().height +
      2 +
      "px";

    // fin calculo para highlight
    return coord_obj;
  }

  setOrderLenght(length: string, order: any) {
    order.orderLenghtMinutes = this.hhmmssToMinutes(length);
    this.sizeInPx(order);
  }

  // establece el largo de la orden agendada en px, de acuerdo a su largo en tiempo minutos
  // order has reference to programador
  public sizeInPx(order) {
    var horas = Math.floor(order.orderLenghtMinutes / 60);
    // tonto pero pa que se entienda en el codigo. Se agregaron unos bordes lo que se tradujo en un pixel mas por hora.
    var ajusteUnPixelHora = horas * 1;

    var pixelPorMinuto =
      order.programador.pixelUnitTime / order.programador.unitTime;

    order.orderLengthPx =
      order.orderLenghtMinutes * pixelPorMinuto + ajusteUnPixelHora;
  }

  validateComments(comment: string) {
    // checks at least 15 chars or five words
    var totalCharsCheck = false;
    var wordCountCheck = false;
    if (comment.length >= 15) {
      totalCharsCheck = true;
    }

    var splitted = comment.split(" ");
    if (splitted.filter((e) => e != "").length >= 5) {
      wordCountCheck = true;
    }

    // both criteria are valid.
    return totalCharsCheck || wordCountCheck;
  }

  // saca las comas
  removeDecimals(value) {
    var spl = value.split(",");
    return spl[0];
  }

  validateSameTypeOrder(orderSource: any, orderDestiny: any): boolean {
    let isSameType = true;
    const dinamoSource = _.get(orderSource, "DINAMO");
    const dinamoDestiny = _.get(orderDestiny, "DINAMO");
    if (dinamoSource !== dinamoDestiny) {
      isSameType = false;
    }
    return isSameType;
  }

  comparePedidoByPosAgrupador(a: any, b: any) {
    if (parseInt(a["POS_AGRUPADOR"], 10) > parseInt(b["POS_AGRUPADOR"], 10)) {
      return 1;
    }
    if (parseInt(b["POS_AGRUPADOR"], 10) > parseInt(a["POS_AGRUPADOR"], 10)) {
      return -1;
    }
    return 0;
  }

  calcularPedidoCombinado(arrPedidos: any, programador: any) {
    var agrupador = arrPedidos[0]["AGRUPADOR"];
    var duracionGrupo = arrPedidos[0]["DURACION_GRUPO"];
    var vehicle = arrPedidos[0]["VEHICLE"];
    var addingNew = false;
    this.calcularStartTimeCombinados(
      arrPedidos,
      agrupador,
      vehicle,
      duracionGrupo,
      programador,
      addingNew
    );
  }

  /*
  adding new permite saber si llamo este metodo al agregar un nuevo pedido combinado (true)
  o bien si lo estoy llamando al recalcular por mover (drag) un pedido combinado (false)
  además, ahora será utilizado para evidenciar si estoy creando un nuevo pedido combinado
  y en base a eso, limitar la cantidad de consultas al servicio que nis entrega la info de la programacion de los viajes
  Este se logra agregando la condicion addingNew dentro de cada iteracion del ciclo for...of, l detectar que efectivamente se está creando un nuevo pedido combinado,
  utilizara los datos suministrados por esa consulta para realizar los calculos
  Se cambio un ciclo forEach por un for...of ya que este ultimo si puede manejar peticiones asíncronas que se utilizan para obtener los datos.
  */
  async calcularStartTimeCombinados(
    pedidosAgrupados,
    agrupador,
    vehicle,
    duracionGrupo,
    programador,
    addingNew
  ) {
    var nextStartTimeMilli = 0;
    var lastKunag = 0;

    // var pedidosAgrupadosOrdenados = pedidosAgrupados
    if (addingNew == false) {
      // ORDENAMOS pedidosAgrupadosOrdenados
      pedidosAgrupados.sort(this.comparePedidoByPosAgrupador);
    }

    const fillDataPedidos = async () => {
      var lastKunagPedidosAgrupados = 0;

      for await (let [idx, pa] of pedidosAgrupados.entries()) {
        lastKunagPedidosAgrupados = parseInt(pa.KUNAG);
      }

      for await (let [idx, pa] of pedidosAgrupados.entries()) {
        pa.POS_AGRUPADOR = idx + 1;
        pa.DURACION_GRUPO = duracionGrupo;
        pa.AGRUPADOR = agrupador;
        pa.VEHICLE = vehicle;

        if (idx == 0) {
          var origen = parseInt(programador.plantData.code);
          var destino = parseInt(pa["KUNAG"]); // 'KUNAG' es el codigo de cliente

          var BaseStartTimeMilli = this.getTimestampFromDateTime(
            pa["PRO_BEGDA"],
            pa["PRO_BEGTI"]
          ).getTime();

          nextStartTimeMilli += BaseStartTimeMilli;
          var newTimes = this.millisecondsToDateAndTimeSap(nextStartTimeMilli);
          pa.CONFIR_START_DATE = newTimes.date;
          pa.CONFIR_START_TIME = newTimes.time;

          if (addingNew) {
            var tiempoCargaMilli =
              60 *
              1000 *
              parseFloat(
                programador.matrizDuraciones.filter(
                  (e) => parseInt(e.destino) == destino && e.origen == origen
                )[0].tiempo_carga_default
              );
            var tiempoDescargaMilli =
              60 *
              1000 *
              parseFloat(
                programador.matrizDuraciones.filter(
                  (e) => parseInt(e.destino) == destino && e.origen == origen
                )[0].tiempo_descarga_default
              );
            var tiempoViajeMilli =
              60 *
              1000 *
              parseFloat(
                programador.matrizDuraciones.filter(
                  (e) => parseInt(e.destino) == destino && e.origen == origen
                )[0].minutes
              );

            var duracionTramoActualMilli =
              tiempoCargaMilli + tiempoViajeMilli + tiempoDescargaMilli;

            nextStartTimeMilli += duracionTramoActualMilli;

            lastKunag = destino;
          } else {
            const tcm = await this.api.getTimesForSchedule(
              origen,
              destino,
              parseInt(programador.plantData.code)
            );

            var tiempoCargaMilli =
              60 * 1000 * parseFloat(tcm["body"].tiempo_carga_default);
            var tiempoDescargaMilli =
              60 * 1000 * parseFloat(tcm["body"].tiempo_descarga_default);
            var tiempoViajeMilli = 60 * 1000 * parseFloat(tcm["body"].minutes);
            var duracionTramoActualMilli =
              tiempoCargaMilli + tiempoViajeMilli + tiempoDescargaMilli;

            nextStartTimeMilli += duracionTramoActualMilli;

            lastKunag = destino;
          }
        } else {
          var newTimes = this.millisecondsToDateAndTimeSap(nextStartTimeMilli);
          pa.CONFIR_START_DATE = newTimes.date;
          pa.CONFIR_START_TIME = newTimes.time;

          pa.PRO_BEGDA = pedidosAgrupados[0]["PRO_BEGDA"];
          pa.PRO_BEGTI = pedidosAgrupados[0]["PRO_BEGTI"];

          // ahora calculamos nextStartTimeMilli para el proximo tramo
          var origen = lastKunag;
          var destino = parseInt(pa["KUNAG"]);

          var tiempoDescargaMilli = 0; // 60*1000*parseFloat(programador.matrizDuraciones[origen].filter(e=>parseInt(e['destination'])==destino)[0]['tiempo_descarga_default'])
          var tiempoViajeMilli = 0; // 60*1000*parseFloat(programador.matrizDuraciones[origen].filter(e=>parseInt(e['destination'])==destino)[0]['minutes'])

          if (origen != destino) {
            if (addingNew) {
              tiempoDescargaMilli =
                60 *
                1000 *
                parseFloat(
                  programador.matrizDuraciones.filter(
                    (e) => parseInt(e.destino) == destino && e.origen == origen
                  )[0].tiempo_descarga_default
                );
              tiempoViajeMilli =
                60 *
                1000 *
                parseFloat(
                  programador.matrizDuraciones.filter(
                    (e) => parseInt(e.destino) == destino && e.origen == origen
                  )[0].minutes
                );
            } else {
              const tcm = await this.api.getTimesForSchedule(
                origen,
                destino,
                parseInt(programador.plantData.code)
              );
              tiempoDescargaMilli =
                60 * 1000 * tcm["body"].tiempo_descarga_default;
              tiempoViajeMilli = 60 * 1000 * parseFloat(tcm["body"].minutes);
            }
          } else {
            // si destino y origen iguales, usamos el valor de tiempo de descarga asociado a la planta.

            if (addingNew) {
              tiempoDescargaMilli =
                60 *
                1000 *
                parseFloat(
                  programador.matrizDuraciones.filter(
                    (e) =>
                      parseInt(e.destino) ==
                        parseInt(programador.plantData.code) &&
                      e.origen == lastKunagPedidosAgrupados
                  )[0].tiempo_descarga_default
                );
            } else {
              const tcm = await this.api.getTimesForSchedule(
                parseInt(pa.KUNAG),
                parseInt(programador.plantData.code),
                parseInt(programador.plantData.code)
              );

              // tiempoDescargaMilli = 60 * 1000 * parseFloat(programador.matrizDuraciones[parseInt(pa.KUNAG)].filter(e => parseInt(e['destination']) == parseInt(programador.plantData.code))[0]['tiempo_descarga_default']);
              tiempoDescargaMilli =
                60 * 1000 * parseFloat(tcm["body"].tiempo_descarga_default);
            }
          }

          var duracionTramoActualMilli = tiempoViajeMilli + tiempoDescargaMilli;
          nextStartTimeMilli += duracionTramoActualMilli;

          // si es el ultimo pedido de la lista de combinados, se agrega duracion regreso a planta
          if (idx == pedidosAgrupados.length - 1) {
            if (addingNew) {
              var duracionesUltimoClientePlanta =
                programador.matrizDuraciones.filter(
                  (e) =>
                    parseInt(e.destino) ==
                      parseInt(programador.plantData.code) &&
                    e.origen == parseInt(pa.KUNAG)
                )[0];
              var tiempoVueltaPlanta =
                60 *
                1000 *
                parseFloat(duracionesUltimoClientePlanta["minutes"]);
              nextStartTimeMilli += tiempoVueltaPlanta;
            } else {
              const tcm = await this.api.getTimesForSchedule(
                parseInt(pa.KUNAG),
                parseInt(programador.plantData.code),
                parseInt(programador.plantData.code)
              );
              var duracionesUltimoClientePlanta = tcm["body"];
              var tiempoVueltaPlanta =
                60 *
                1000 *
                parseFloat(duracionesUltimoClientePlanta["minutes"]);
              nextStartTimeMilli += tiempoVueltaPlanta;
            }
          }

          lastKunag = destino;
        }
      }
    };
    await fillDataPedidos();
    programador.matrizDuraciones = [];
    // nextStartTimeMilli se usa para el ultimo tramo
  }

  isToday(dateTocheck, formatDate = true) {
    var date;
    if (formatDate) {
      date = this.yyyymmddToDate(dateTocheck); // dd-mm-yyyy
    } else {
      date = dateTocheck;
    }
    var year = parseInt(date.split("-")[2]);
    var day = parseInt(date.split("-")[0]);
    var month = parseInt(date.split("-")[1]) - 1;
    var dateTime = new Date(year, month, day);
    var today = new Date();

    return today.toDateString() == dateTime.toDateString();
  }

  pedidoTieneRuta(pedido: any) {
    if (pedido["TIENE_RUTA"] != undefined && pedido["TIENE_RUTA"] == true) {
      return true;
    }
    return false;
  }

  checkNavigator(): boolean {
    const usrAgent = navigator.userAgent;
    if (
      usrAgent.indexOf("Chrome") > -1 ||
      usrAgent.indexOf("AppleWebKit") > -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  delZeros(data: string): string {
    data = data.replace(/,[0-9]+/g, "");
    data = data.replace(/.000/gi, ".00");
    data = data.replace(/-/gi, "+");

    if (
      data.includes(".") == true &&
      data.includes("=") == false &&
      data.indexOf(".") == 1
    ) {
      data = data.substring(0, 4);
    } else if (data.includes(",") == false && data.includes(".") == false) {
      data = "0." + data;
      data = data.substring(0, 4);
    } else if (data.includes("=") == true && data.includes(".00") == false) {
      var dataList = data.split(" ");

      var valor = dataList[0].substring(0, dataList[0].length - 1) + " = ";

      var dataList2 = dataList[2].split("+");

      var i = 1;
      for (var dat of dataList2) {
        dat = dat.substring(0, dat.length - 1);
        if (dataList2.length > i) {
          dat = dat + "+";
        }
        valor += dat;
        i++;
      }

      data = valor;
    }
    return data;
  }

  async calcularTiemposPedidosGuardados(arrPedidos: any) {
    var agrupador = arrPedidos[0]["AGRUPADOR"];
    var duracionGrupo = arrPedidos[0]["DURACION_GRUPO"];
    var vehicle = arrPedidos[0]["VEHICLE"];

    var nextStartTimeMilli = 0;

    const tiempoPedidos = async () => {
      for await (let [idx, pa] of arrPedidos.entries()) {
        pa.POS_AGRUPADOR = idx + 1;
        pa.DURACION_GRUPO = duracionGrupo;
        pa.AGRUPADOR = agrupador;
        pa.VEHICLE = vehicle;

        if (idx == 0) {
          var BaseStartTimeMilli = this.getTimestampFromDateTime(
            pa["PRO_BEGDA"],
            pa["PRO_BEGTI"]
          ).getTime();

          nextStartTimeMilli += BaseStartTimeMilli;
          var newTimes = this.millisecondsToDateAndTimeSap(nextStartTimeMilli);
          pa.CONFIR_START_DATE = newTimes.date;
          pa.CONFIR_START_TIME = newTimes.time;
        } else {
          var newTimes = this.millisecondsToDateAndTimeSap(nextStartTimeMilli);
          pa.CONFIR_START_DATE = newTimes.date;
          pa.CONFIR_START_TIME = newTimes.time;
        }
      }
    };

    await tiempoPedidos();
  }

  /**
   * Da formato YYYYMMDD a la fecha de entrada
   * @param date fecha en formato MM-DD-YYYY
   * @returns string con la fecha de entrada en formato YYYYMMDD
   */
  getDateCodeFromDate(date: string) {
    var month: any = date.split("-")[1];
    var day: any = date.split("-")[0];
    var year = date.split("-")[2];

    if (month * 1 < 10) {
      month = "0" + month * 1;
    }
    if (day * 1 < 10) {
      day = "0" + day * 1;
    }
    return year + month + day;
  }

  getStringtoDate(fecha) {
    const [year, month, day] = fecha[0].split("-");
    const [hours, minutes, seconds] = fecha[1].split(":");
    return new Date(
      +year,
      +month - 1,
      +day,
      +hours,
      +minutes,
      +seconds.substr(0, 1)
    );
  }

  getSubStringDate(date, hours) {
    const anio = date.substring(0, 4);
    const mes = date.substring(4, 6);
    const dia = date.substring(6, 8);
    const hora = hours.substring(0, 2);
    const min = hours.substring(2, 4);
    const seg = hours.substring(4, 6);
    return new Date(+anio, +mes - 1, +dia, +hora, +min, +seg);
  }

  generateHourArray(from, to) {
    let arr = [];
    for (let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }

  dateFromMMDDYYYY(date) {
    let splitDate = date.split("-");
    return new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
  }

  tomorrow(date) {
    let today = this.dateFromMMDDYYYY(date);
    var tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return this.normalizeDateOneDigit(
      tomorrow.getDate() +
        "-" +
        (tomorrow.getMonth() + 1) +
        "-" +
        tomorrow.getFullYear()
    );
  }

  yesterday(date) {
    // date dd-mm-yyyy
    let today = this.dateFromMMDDYYYY(date);
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return this.normalizeDateOneDigit(
      yesterday.getDate() +
        "-" +
        (yesterday.getMonth() + 1) +
        "-" +
        yesterday.getFullYear()
    );
  }

  /**
   * Da formato YYYY-MM-DD a la fecha de entrada
   * @param date fecha en formato MM-DD-YYYY
   * @returns string con la fecha de entrada en formato YYYY-MM-DD
   */
  getDateWithHippens(date: string) {
    var month: any = date.split("-")[1];
    var day: any = date.split("-")[0];
    var year = date.split("-")[2];

    if (month * 1 < 10) {
      month = "0" + month * 1;
    }
    if (day * 1 < 10) {
      day = "0" + day * 1;
    }
    return year + "-" + month + "-" + day;
  }

  parsearFechaALocalCL(fecha) {
    return new Date(fecha).toLocaleDateString("es-CL");
  }
}
