export const PLANTAS = {
  1201: true, // Centro Iquique Régimen General
  1202: true, // Planta Arica
  1205: true, // Planta Caldera
  1207: true, // Planta Guayacán
  1208: true, // Planta Concón
  1210: true, // Planta San Fernando
  1211: true, // Planta Chillán
  1212: true, // Planta San Vicente
  1213: true, // Planta Chacabuco
  1214: true, // Planta Punta Arenas
  1215: true, // Planta Mejillones
  1216: true, // Planta Pureo
  1228: true, // Planta Maipú
  1245: true, // Oxiquim Quintero
  1254: true, // Oxiquim Coronel
  1261: true, // Planta Solventes AXXA
  1262: true, // Planta Bluemax Maipú
  1263: true, // Oxiquim Mejillones
  1265: true, // Terquim San Antonio
  1272: true, // Planta INOX Antofagasta
  1335: true, // Kerosene EESS 20213
  1336: true, // Kerosene EESS 20071
  1537: true, // Planta Bluemax Concepción
};
