import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Admin } from "../../pages/administrador/models/requests.model";
import { OPERACIONES } from "../../pages/administrador/constants/operations.const";
import { SharedDataService } from "../../global/services/shared-data.service";
import { SetDeleteService } from "../../pages/administrador/services/set-delete.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-modal-for-delete",
  templateUrl: "./modal-for-delete.component.html",
  styleUrls: ["./modal-for-delete.component.css"],
})
export class ModalForDeleteComponent implements OnInit {
  @Input() info: any;
  @Input() routeView: string;

  public borrarUsuario: string;

  constructor(
    private shared: SharedDataService,
    private setDelete: SetDeleteService,
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.borrarUsuario = this.info["Usuario SAP"];
  }

  delete() {
    /**
     * Con los valores de los inputs suministrados en la tabla, se llama al método setEndPointAndSend al cual nos suscribimos para saber la respuesta
     * de la solicitud, la cual será retornad por el modal.
     */
    this.spinner.show();
    this.setDelete
      .setEndpointandSend(this.info, this.routeView)
      .subscribe((resp) => {
        if (resp != null) {
          const { code, message } = resp;
          this.activeModal.close({ code, message });
        } else {
          this.activeModal.close({
            code: 0,
            message: "Ocurrió un error con su solicitud. Intente nuevamente",
          });
        }
      });
  }
}
