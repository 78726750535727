/**
 * Operaciones requeridas en el payload de las solcitudes a la API
 */

export const OPERACIONES = {
  getBatch: "read-batch",
  read: "read",
  create: "create",
  update: "update",
  delete: "delete",
};

//Operaciones solicitadas por el endpoint de descarga de planilla
export enum DOWNLOADTERMS {
  parameters = "parameters",
  constraints = "constraints",
  imputations = "imputations",
}

/**
 * Mensaje cuando ocurre un error no atribuible a la solicitud o al backend
 */

export const ERRORINESPERADO = {
  code: 0,
  message: "Ocurrió un error con su solicitud. Intente nuevamente",
};
