import { Component, OnInit } from "@angular/core";
import { ProgramadorComponent } from "src/app/pages/programador/programador.component";
import { UtilsService } from "../../global/utils/utils.service";
import { OrdenProgramadaComponent } from "../../pages/programador/components/orden-programada/orden-programada.component";
import { OrdenNoProgramadaComponent } from "../../pages/programador/components/orden-no-programada/orden-no-programada.component";

declare var $: any;

@Component({
  selector: "app-modal-montar-pedidos",
  templateUrl: "./modal-montar-pedidos.component.html",
  styleUrls: ["./modal-montar-pedidos.component.css"],
})
export class ModalMontarPedidosComponent implements OnInit {
  programador: ProgramadorComponent;
  programedOrder: OrdenProgramadaComponent;
  notProgramedOrder: OrdenNoProgramadaComponent;
  match: any;

  // constructor(public element: ElementRef) { }
  // constructor() { }
  constructor(private utils: UtilsService) {}

  ngOnInit() {}

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
  }
  setProgrammedOrder(programedOrder: OrdenProgramadaComponent, match: any) {
    this.programedOrder = programedOrder;
    this.match = match;
  }

  setNotProgrammedOrder(
    NotProgramedOrder: OrdenNoProgramadaComponent,
    match: any
  ) {
    this.notProgramedOrder = NotProgramedOrder;
    this.match = match;
  }

  showModalOrdenProgramada() {
    $("#montar-pedidos-ordenProgramada-modal").modal("show");
  }
  showModalOrdenNoProgramada() {
    $("#montar-pedidos-ordenNoProgramada-modal").modal("show");
  }

  okMontarPedidoOrdenProgramada() {
    // console.log('Montando pedido programado sobre uno confirmado');
    let newParentDom = this.match[0]; // the element where I want to add a new child, the planned order
    this.programador.activarClickOrden();
    let cellInfo = newParentDom.id.split(";");
    let truckId = cellInfo[1];
    let startDate = cellInfo[0];
    let startHour = parseInt(cellInfo[2], 10);
    let startMinute = parseInt(cellInfo[3], 10);
    this.programedOrder.clearWarningErrors();

    let newParentElement = this.programador.minuteCells.filter(
      (e) =>
        e.date == startDate &&
        e.truck == truckId &&
        e.hour == startHour &&
        e.minute == startMinute
    )[0];

    this.programedOrder.parentCell.removePlannedOrder();
    if (this.programedOrder.plannedOrder["AGRUPADOR"].trim() != "") {
      var combinados = this.programador.pedidos.filter(
        (e) => e["AGRUPADOR"] == this.programedOrder.plannedOrder["AGRUPADOR"]
      );
      combinados.forEach((pc) => {
        pc["VEHICLE"] = truckId;
        pc["PRO_BEGDA"] = this.utils.dateToyyyymmdd(startDate); // startDate; startDate viene DD-MM-YYYY. Pasar a YYYYMMDD
        pc["PRO_BEGTI"] = this.utils.hhmmTohhmmss(startHour, startMinute);

        let fechaHoraFinal = this.utils.sumDates(
          pc["PRO_BEGDA"],
          pc["PRO_BEGTI"],
          pc["DURACION_GRUPO"]
        );
        pc["PRO_ENDDA"] = fechaHoraFinal.split("-")[0];
        pc["PRO_ENDTI"] = fechaHoraFinal.split("-")[1];

        newParentElement.attachPlannedOrder(pc);
      });
      this.utils.calcularPedidoCombinado(combinados, this.programador);
    } else {
      this.programedOrder.plannedOrder["VEHICLE"] = truckId;
      // startDate; startDate viene DD-MM-YYYY. Pasar a YYYYMMDD
      this.programedOrder.plannedOrder["PRO_BEGDA"] =
        this.utils.dateToyyyymmdd(startDate);

      this.programedOrder.plannedOrder["PRO_BEGTI"] = this.utils.hhmmTohhmmss(
        startHour,
        startMinute
      );

      let fechaHoraFinal = this.utils.sumDates(
        this.programedOrder.plannedOrder["PRO_BEGDA"],
        this.programedOrder.plannedOrder["PRO_BEGTI"],
        this.programedOrder.plannedOrder["DURACION_CALCULA"]
      );

      this.programedOrder.plannedOrder["PRO_ENDDA"] =
        fechaHoraFinal.split("-")[0];
      this.programedOrder.plannedOrder["PRO_ENDTI"] =
        fechaHoraFinal.split("-")[1];

      var showAlert = true;
      showAlert = false;
      this.utils.validateOrderConditions(
        this.programador,
        newParentElement,
        this.programedOrder,
        showAlert
      );

      this.programedOrder.plannedOrder["CONFIR_START_DATE"] =
        this.programedOrder.plannedOrder["PRO_BEGDA"];
      this.programedOrder.plannedOrder["CONFIR_START_TIME"] =
        this.programedOrder.plannedOrder["PRO_BEGTI"];

      newParentElement.attachPlannedOrder(this.programedOrder.plannedOrder);
    }
  }
  cancelarMontarPedidoOrdenProgramada() {
    // console.log('Se cancela montar el pedido');
    this.programedOrder.cancelDragProgrammedOrder();
    return false;
  }

  okMontarPedidoOrdenNoProgramada() {
    // console.log('Montando pedido no programado sobre uno confirmado');
    let newParentDom = this.match[0];
    this.programador.activarClickOrden();

    let candidateInfo =
      this.notProgramedOrder.generateNewProgrammedOrderObject(newParentDom);

    this.notProgramedOrder.plannedOrder = candidateInfo.candidate;
    let newParentElement = candidateInfo.cellMinuteWhereInsert;

    this.notProgramedOrder.clearWarningError(
      this.notProgramedOrder.plannedOrder
    );

    var index = this.notProgramedOrder.getIndexProgramadroPlan(
      this.notProgramedOrder.vbeln
    );
    this.programador.pedidos[index] = this.notProgramedOrder.plannedOrder;

    this.notProgramedOrder.plannedOrder["CONFIR_START_DATE"] =
      this.notProgramedOrder.plannedOrder["PRO_BEGDA"];
    this.notProgramedOrder.plannedOrder["CONFIR_START_TIME"] =
      this.notProgramedOrder.plannedOrder["PRO_BEGTI"];

    newParentElement.attachPlannedOrder(this.notProgramedOrder.plannedOrder);
    var showAlert = false; // true
    this.utils.validateOrderConditions(
      this.programador,
      newParentElement,
      this.notProgramedOrder,
      showAlert
    );
  }

  cancelarMontarPedidoOrdenNoProgramada() {
    // console.log('Se cancela montar el pedido');
    this.notProgramedOrder.cancelDragNoProgrammedOrder();
    this.programador.updateCountPedidos();
    return false;
  }
}
