import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoginRequest } from "../models/login-request.model";
import { LoginResponse } from "../models/login-response.model";
import { ENDPOINTS } from "../constants/endpoints.const";

@Injectable({
  providedIn: "root",
})
export class ApiLoginService {
  apiURL: string = environment.apiURL;
  optimizeUrl: string = environment.optimizerUrl;

  constructor(private http: HttpClient) {}

  private handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  /**
   * Realiza el login del usuario hacia SAP
   * @param loginRequest UserName y Password del usuario
   * @returns Response del servicio con información del Bearer y plantas
   */
  public userLogin(loginRequest: LoginRequest): Observable<LoginResponse> {
    const loginData = {
      user: loginRequest.userName,
      pwd: loginRequest.userPass,
    };

    return this.http
      .post<LoginResponse>(
        this.apiURL + ENDPOINTS.LOGIN.SAP_INTEG_LOGIN,
        loginData
      )
      .pipe(catchError(this.handleError));
  }
}
