import { Component, OnInit, ElementRef } from "@angular/core";
import { OrdenProgramadaComponent } from "../../pages/programador/components/orden-programada/orden-programada.component";
import { DropListRef } from "@angular/cdk/drag-drop";
import { UtilsService } from "../../global/utils/utils.service";
import { MESSAGES } from "src/app/global/constants/messages.const";
import { ApiProgramadorService } from "src/app/pages/programador/services/api-programador.service";

// import { ProgramadorComponent } from '../programador/programador.component';

// This lets me use jquery
declare var $: any;

@Component({
  selector: "app-modal-nuevo-pedido-combinado",
  templateUrl: "./modal-nuevo-pedido-combinado.component.html",
  styleUrls: ["./modal-nuevo-pedido-combinado.component.css"],
})
export class ModalNuevoPedidoCombinadoComponent implements OnInit {
  // ordenProgramada: OrdenProgramadaComponent;
  plantTime: any[];
  plantName: any;
  candidateOrderToUpdate: any;
  orderToDeleteAfterCombine: any;

  timeFromTo: any;
  programador: any;
  order1: any;
  order2: any;
  PastAgrupador2: any;
  PastAgrupador1: any;
  order1Backup: any;
  order2Backup: any;
  warningMessage: string;
  combineNProgrammed: boolean;
  responseTimesSchedule: any[] = [];

  constructor(
    private utils: UtilsService,
    public element: ElementRef,
    private api: ApiProgramadorService
  ) {}

  ngOnInit() {}

  show() {
    // this.prepareForm();
    $("#combinar-pedido-modal").modal("show");
  }

  close() {
    $("#combinar-pedido-modal").modal("hide");
    this.warningMessage = "";
  }

  cancelarCombinado() {
    // this.ordenProgramada.cancelDragProgrammedOrder()
    var vbeln1 = this.order1["plannedOrder"]["VBELN"];
    var vbeln2 = this.order2["VBELN"];
    var vbelns = [vbeln1, vbeln2];

    this.rollbackOrdersCombined();
    if (this.combineNProgrammed) {
      this.orderToDeleteAfterCombine.cancelDragNoProgrammedOrder(vbelns);
    } else {
      this.orderToDeleteAfterCombine.cancelDragProgrammedOrder(vbelns);
    }

    this.programador.updateCountPedidos();
  }
  // se revierten los cambios hechos en las orders para efectos de combinar pedidos. (por ej, sacar agrupacion)
  rollbackOrdersCombined() {
    this.restoreDataOrder(this.order1Backup, this.order1.plannedOrder);
    this.restoreDataOrder(this.order2Backup, this.order2);
  }
  combinarPedido() {
    // console.log('combinar pedido');
    // remover planta combinada de pedidos combinados.
    this.orderToDeleteAfterCombine.remove(); // .setNoProgrammed()aa
    this.programador.refreshPlannedOrderOnGrid();
    this.close();
  }

  restoreDataOrder(backup, order) {
    var keys = Object.keys(backup);
    keys.forEach((k) => {
      order[k] = backup[k];
    });
  }

  backupDataOrders(order) {
    var backupFields = {};

    backupFields["PRO_BEGTI"] = order.PRO_BEGTI;
    backupFields["PRO_BEGDA"] = order.PRO_BEGDA;
    backupFields["DURACION_GRUPO"] = order.DURACION_GRUPO;
    // backupFields["ESTADO"] = order.ESTADO;
    backupFields["AGRUPADOR"] = order.AGRUPADOR;
    backupFields["VEHICLE"] = order.VEHICLE;

    return backupFields;
  }

  generatePlantClientTimesFromNoProgrammedOrder(
    order1,
    order2,
    timesClientPlant,
    plantName,
    programador
  ) {
    // this.combineNProgrammed = true
    // console.log('ORDER 1 NP');
    // console.log(order1);
    // var o1 = Object.assign({},order1)
    // o1["plannedOrder"] = order1["order"]
    order1["plannedOrder"] = order1["order"];
    var esCombinado = true;
    return this.generatePlantClientTimes(
      order1,
      order2,
      timesClientPlant,
      plantName,
      programador,
      esCombinado
    );
  }

  // order 1 is dropped over order2
  async generatePlantClientTimes(
    order1,
    order2,
    timesClientPlant,
    plantName,
    programador,
    esCombinado = false
  ) {
    this.combineNProgrammed = esCombinado;
    this.programador = programador;
    // generamos un backup, que podemos restaurar los valores de las orders en caso de cancelar.
    this.order1 = order1;
    this.order2 = order2;
    this.order1Backup = this.backupDataOrders(order1.plannedOrder);
    this.order2Backup = this.backupDataOrders(order2);
    this.plantTime = [];
    this.plantName = plantName;

    this.candidateOrderToUpdate = order2; // en caso de confirmar, la orden sobre la cual se hace
    // el drop, se modifica. La orden dropeada se elimina dado que se incluye en el combinado.
    this.orderToDeleteAfterCombine = order1;

    var agrupador = " ";
    var groupedBefore = false;
    var pedidosAgrupados = [];

    // si ninguna de las ordenes pertenece a una orden combinada
    if (
      order1.plannedOrder.AGRUPADOR.trim() == "" &&
      order2.AGRUPADOR.trim() == ""
    ) {
      agrupador = this.generateAgrupador(
        order1.plannedOrder.VBELN,
        order2.VBELN,
        order2.VEHICLE
      );
      pedidosAgrupados.push(order2);
      pedidosAgrupados.push(order1.plannedOrder);
    } else if (
      order1.plannedOrder.AGRUPADOR.trim() == "" ||
      order2.AGRUPADOR.trim() == ""
    ) {
      // nos quedamos con el agrupador de aquella que no sea nula
      agrupador =
        order1.plannedOrder.AGRUPADOR.trim() != ""
          ? order1.plannedOrder.AGRUPADOR.trim()
          : order2.AGRUPADOR.trim();

      // order 1 es la fija
      // si la o1 es la combinada, extraemos sus pedidos agrupados y los ponemos en pedidosAgrupados[] y
      // despue al ultomo agregamos la orden 2
      if (order1.plannedOrder.AGRUPADOR.trim() != "") {
        pedidosAgrupados.push(order2);
        var pAgr = this.programador.pedidos.filter(
          (p) => p["AGRUPADOR"] == order1.plannedOrder.AGRUPADOR
        );

        pAgr.forEach((pa) => {
          pedidosAgrupados.push(pa);
        });
      } else {
        var pAgr = this.programador.pedidos.filter(
          (p) => p["AGRUPADOR"] == order2.AGRUPADOR
        );

        pAgr.sort(this.utils.comparePedidoByPosAgrupador);

        pAgr.forEach((pa) => {
          pedidosAgrupados.push(pa);
        });
        // le damos el agrupador al nuevo pedido.
        order1.plannedOrder["AGRUPADOR"] = order2.AGRUPADOR;
        order1.plannedOrder["VEHICLE"] = order2.VEHICLE;
        pedidosAgrupados.push(order1.plannedOrder);
      }

      // order 2 es la que se mueve
      // si la o2 es la combinada, primero agregamos a pedidos agrupados la primera orden y luego las otras 2

      groupedBefore = true;
    } else {
      // console.log('no se puede combinar dos pedidos combinados');
      // console.log('RETURN FALSE 1!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1');
      return false;
      // esto es que hay dos pedidos combinados intentando combinarse (ningun AGRUPADOR es vacio)
      // cancelar y tirar mensaje de error que no podemos combinar dos pedidos previamente combinados
    }

    // lo utilizaremos para hacer el calculo de DURACION_PEDIDO
    var duracionesIndividualesPedidos = [];

    // var lastFrom="planta";
    var lastFrom = this.programador.plantData.code;

    var codePlaces = {};
    codePlaces[lastFrom] = "Planta (" + this.programador.plantData.name + ") ";
    // + this.programador.plantData.code  //ponemos codigo pa deb

    // check de capacidad total en combinado
    if (!this.utils.checkCombinedCapacity(this.programador, pedidosAgrupados)) {
      this.warningMessage = MESSAGES.PROGRAMADOR.VOLUME_NO_EQUAL_VEHICULE;
      this.programador.openAlert("warning", this.warningMessage);
    }

    // check de tipos de combustible en combinado
    if (!this.utils.checkCombinedFuelType(this.programador, pedidosAgrupados)) {
      this.warningMessage = MESSAGES.PROGRAMADOR.FUEL_TYPE_NOT_EQUAL;
    }

    var codigosClientesUsados = [];
    var accumMinutes = 0.0;
    // poblamos plant time para mostrar en dialog de creacion de pedido combinado
    // pedidosAgrupados.forEach( p => {
    //   codigosClientesUsados.push(p.KUNAG);
    //   // console.log( p.KUNAG)
    //   // console.log(lastFrom)
    //   // poblamos el array para ser usado posteriormente
    //   // duracionesIndividualesPedidos.push(p.DURACION)

    //   let item = {from: '', to: '', time: '', canModify: false};
    //   var canModify = false;

    //   // si el pedido combinado es entre mismos clientes, no entramos al if y dejamos el time como 0 (el tiempo entre la misma planta es 0)
    //   var time = '000000';
    //   var minutes = '0.0';
    //   if (lastFrom != p.KUNAG) {

    //     // const forMinutes = await this.getTimesForSchedule(lastFrom, p.KUNAG)
    //     minutes = this.programador.matrizDuraciones[lastFrom].filter(e => e.destination == parseInt(p.KUNAG, 10) )[0]['minutes'];  // hhmmss
    //     // minutes = forMinutes['body'].minutes;  // hhmmss

    //   }

    //   accumMinutes += parseFloat(minutes);

    //   duracionesIndividualesPedidos.push(minutes);
    //   item.from = codePlaces[lastFrom];
    //   item.to = p.NAME1_KUNAG;

    //   item.time = this.utils.millisecondsToHHMMSS(this.utils.minutesToMilliseconds(minutes));
    //   // this.utils.hhmmssSeparateWithColons(this.utils.floatMinutesTohhmmss(this.utils.estimarTiempoLlegada(time)));

    //   item.canModify = canModify;

    //   lastFrom = parseInt(p.KUNAG);
    //   codePlaces[lastFrom] = p.NAME1_KUNAG; // + " , " + p.KUNAG

    //   // // console.log("PRINING ITEM TO COMBINE")
    //   // // console.log(item)
    //   this.plantTime.push(item);
    // });
    const getAndInsert = async () => {
      for await (let p of pedidosAgrupados) {
        codigosClientesUsados.push(p.KUNAG);
        // console.log( p.KUNAG)
        // console.log(lastFrom)
        // poblamos el array para ser usado posteriormente
        // duracionesIndividualesPedidos.push(p.DURACION)

        let item = { from: "", to: "", time: "", canModify: false };
        var canModify = false;

        // si el pedido combinado es entre mismos clientes, no entramos al if y dejamos el time como 0 (el tiempo entre la misma planta es 0)
        var time = "000000";
        var minutes = "0.0";

        if (lastFrom != p.KUNAG) {
          /*Si el pedido es entre distintos clientes, consultamos la informacion para el tiempo y a la vez guardamos en responseTimesSchedule la info de estos pedidos
            y tabbien dentro programador.matrizDuraciones para que sea utilizado en ese componente y no realice mas consultas, descrito en utils.service, comentario 
            sobre calcularStartTimeCombinados 
            */

          const forMinutes = await this.api.getTimesForSchedule(
            lastFrom,
            p.KUNAG,
            parseInt(programador.plantData.code)
          );
          forMinutes["body"]["origen"] = parseInt(lastFrom);
          forMinutes["body"]["destino"] = parseInt(p.KUNAG);
          this.responseTimesSchedule.push(forMinutes["body"]);
          programador.matrizDuraciones.push(forMinutes["body"]);
          // minutes = this.programador.matrizDuraciones[lastFrom].filter(e => e.destination == parseInt(p.KUNAG, 10) )[0]['minutes'];  // hhmmss
          minutes = forMinutes["body"].minutes; // hhmmss
        }

        accumMinutes += parseFloat(minutes);

        duracionesIndividualesPedidos.push(minutes);
        item.from = codePlaces[lastFrom];
        item.to = p.NAME1_KUNAG;

        item.time = this.utils.millisecondsToHHMMSS(
          this.utils.minutesToMilliseconds(minutes)
        );
        // this.utils.hhmmssSeparateWithColons(this.utils.floatMinutesTohhmmss(this.utils.estimarTiempoLlegada(time)));

        item.canModify = canModify;

        lastFrom = parseInt(p.KUNAG);
        codePlaces[lastFrom] = p.NAME1_KUNAG; // + " , " + p.KUNAG

        // console.log("PRINING ITEM TO COMBINE")
        // console.log(item)
        this.plantTime.push(item);
      }
    };

    await getAndInsert();
    // insertamos el ultimo elemento a pedidos
    // var ultimoPedido = pedidosAgrupados[pedidosAgrupados.length-1];
    let item = { from: "", to: "", time: "", canModify: false };
    item.from = codePlaces[lastFrom];
    item.to = codePlaces[this.programador.plantData.code];

    //se realiza la misma operacion del If linea 269, pero este elemento es el regreso a planta que debe ser agregado al pedido
    const getMinutes = async () => {
      let resp = await this.api.getTimesForSchedule(
        lastFrom,
        programador.plantData.code,
        parseInt(programador.plantData.code)
      );
      return resp;
    };
    const dataFmin = await getMinutes();

    dataFmin["body"]["origen"] = parseInt(lastFrom);
    dataFmin["body"]["destino"] = parseInt(programador.plantData.code);
    this.responseTimesSchedule.push(dataFmin["body"]);
    programador.matrizDuraciones.push(dataFmin["body"]);

    var minutes = dataFmin["body"].minutes;
    // console.log(minutes)
    // var minutes = this.programador.matrizDuraciones[lastFrom].filter(e =>
    //               e.destination == parseInt(this.programador.plantData.code, 10) )[0]['minutes'];
    // var minutes = forVarMinutes['minutes'].minutes
    accumMinutes += parseFloat(minutes);

    // console.log(accumMinutes)
    duracionesIndividualesPedidos.push(minutes);
    item.time = this.utils.millisecondsToHHMMSS(
      this.utils.minutesToMilliseconds(minutes)
    );
    // this.utils.hhmmssSeparateWithColons(this.utils.floatMinutesTohhmmss(this.utils.estimarTiempoLlegada(time)));

    item.canModify = false;
    // console.log(item)
    this.plantTime.push(item);

    // ahora calculo los valores a escribir en las estrucutras y los asigno
    var vehicle = order2.VEHICLE;

    // accumMinutes tiene la suma de los tiempos (minutos) de trayectos de los pedidos combinados. Ahora hay que agregar
    // el tiempo de carga del primer pedido (averiguar si es la suma de todos los tiempos de carga de cada pedido o solo el primero)
    // y los tiempos de descarga de todos los pedisos.
    //// console.log(codigosClientesUsados)
    accumMinutes = await this.agregarTiempoCargaYDescarga(
      codigosClientesUsados,
      this.programador.plantData.code,
      accumMinutes
    );
    var duracionGrupo = this.utils.floatMinutesTohhmmss(accumMinutes);
    var addingNew = true;
    this.utils.calcularStartTimeCombinados(
      pedidosAgrupados,
      agrupador,
      vehicle,
      duracionGrupo,
      this.programador,
      addingNew
    );

    return true;
  }
  ////
  generateAgrupador(pedido1Code, pedido2Code, pedido2Vehicle) {
    var rnd = pedido1Code * pedido2Code * Math.random();
    var rndStr = rnd + "";

    var generatedAgrupador = pedido2Vehicle * 1 + "" + rndStr;
    if (generatedAgrupador.length > 10) {
      generatedAgrupador = generatedAgrupador.substring(0, 10);
    }

    return generatedAgrupador;
  }

  async agregarTiempoCargaYDescarga(
    codigosClientesUsados,
    codigoPlanta,
    minutesAcum
  ) {
    const forMinAcum = async () => {
      for (let [idx, element] of codigosClientesUsados.entries()) {
        // // console.log( codigoPlanta, parseInt(element), idx)
        const minutes = await this.api.getTimesForSchedule(
          codigoPlanta,
          element * 1,
          parseInt(this.programador.plantData.code)
        );
        if (idx == 0) {
          // minutesAcum += this.responseTimesSchedule.filter(e => e.destino == element * 1)[0].tiempo_carga_default
          // // console.log(a)
          // minutesAcum += parseFloat(this.programador.matrizDuraciones[codigoPlanta].filter(e =>
          //                 e.destination == element * 1)[0]['tiempo_carga_default']);
          minutesAcum += parseFloat(minutes["body"].tiempo_carga_default);
        }

        // minutesAcum += parseFloat(this.programador.matrizDuraciones[codigoPlanta].filter(e =>
        //                   e.destination == element * 1)[0]['tiempo_descarga_default']);
        // minutesAcum += this.responseTimesSchedule.filter(e => e.destino == element * 1)[0].tiempo_descarga_default
        minutesAcum += parseFloat(minutes["body"].tiempo_descarga_default);
      }
      return minutesAcum;
    };
    const resp = await forMinAcum();
    return resp;
  }

  sumaHHMMSS(hhmmss1, hhmmss2) {
    var minute1 = this.utils.hhmmssToMinutes(
      this.utils.hhmmssSeparateWithColons(hhmmss1)
    );
    var minute2 = this.utils.hhmmssToMinutes(
      this.utils.hhmmssSeparateWithColons(hhmmss2)
    );

    var sumaMins = minute1 + minute2;
    var suma = this.utils.minutesTohhmmss(sumaMins);
    return suma;
  }
}
