import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { throwError } from "rxjs";
import {
  RequestSummary,
  ResponseSummary,
} from "../models/optimizer-summary.model";
import { retry, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CommonApiRequestService {
  // Servicio para guardar solicitudes comunes tanto del programador como el administrador/gestión

  private apiURL = environment.apiURL;
  private summaryEndpoint = "/copec-api-planificador-results-summary";

  constructor(private http: HttpClient) {}

  private handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  public requestOptimizeResult(data: RequestSummary) {
    return this.http
      .post<ResponseSummary>(`${this.apiURL + this.summaryEndpoint}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }
}
