import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedDataService } from "src/app/global/services/shared-data.service";
import {
  DAYS,
  MONTHS,
} from "../../pages/administrador/constants/calendar.const";
import {
  ERRORINESPERADO,
  OPERACIONES,
} from "src/app/pages/administrador/constants/operations.const";
import { Constraint } from "src/app/pages/administrador/models/requests.model";
import { AlertService } from "src/app/pages/administrador/services/alert.service";
import { ApiAdministradorService } from "src/app/pages/administrador/services/api-administrador.service";
import { distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-modal-constraint-horario",
  templateUrl: "./modal-constraint-horario.component.html",
  styleUrls: ["./modal-constraint-horario.component.css"],
})
export class ModalConstraintHorarioComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() values;
  @Input() plantInfo;
  admin;
  vigente;
  restricciones;
  zonas;
  months = [...MONTHS];
  days = [...DAYS];
  client: Subscription;
  zone: Subscription;
  inicioHorario: Subscription;
  terminoHorario: Subscription;
  hourSpan: boolean = false;

  numericPattern = new RegExp(/^(0|[1-9][0-9]*)$/);

  constraintSchedule = this.fb.group({
    vigencia: ["", Validators.required],
    cliente: [
      "",
      [Validators.required, Validators.pattern(this.numericPattern)],
    ],
    zona: ["", Validators.required],
    restriccion: ["", Validators.required],
    horarioInicio: ["", Validators.required],
    horarioTermino: ["", Validators.required],
    mesInicial: [, Validators.required],
    mesFinal: [, Validators.required],
    diaInicial: [, Validators.required],
    diaFinal: [, Validators.required],
    comentario: [],
  });

  constructor(
    private shared: SharedDataService,
    private api: ApiAdministradorService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private alert: AlertService
  ) {}

  ngOnDestroy(): void {
    this.client.unsubscribe();
    this.zone.unsubscribe();
    this.inicioHorario.unsubscribe();
    this.terminoHorario.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.client = this.constraintSchedule
      .get("cliente")
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((val) => {
        if (val) {
          // // console.log( val )
          this.constraintSchedule.get("zona").disable();
          this.constraintSchedule.get("zona").clearValidators();
          this.constraintSchedule.get("zona").updateValueAndValidity();
        } else {
          this.constraintSchedule.get("zona").enable();
          this.constraintSchedule
            .get("zona")
            .setValidators(Validators.required);
          this.constraintSchedule.get("zona").updateValueAndValidity();
          this.constraintSchedule.get("zona").markAsUntouched();
        }
      });

    this.zone = this.constraintSchedule
      .get("zona")
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((val) => {
        if (val) {
          // // console.log( val )
          this.constraintSchedule.get("cliente").disable();
          this.constraintSchedule.get("cliente").clearValidators();
          this.constraintSchedule.get("cliente").updateValueAndValidity();
        } else {
          this.constraintSchedule.get("cliente").enable();
          this.constraintSchedule
            .get("cliente")
            .setValidators([
              Validators.required,
              Validators.pattern(this.numericPattern),
            ]);
          this.constraintSchedule.get("cliente").updateValueAndValidity();
          this.constraintSchedule.get("cliente").markAsUntouched();
        }
      });

    this.inicioHorario = this.constraintSchedule
      .get("horarioInicio")
      .valueChanges.subscribe((val) => {
        // console.log( val )
        if (val === this.constraintSchedule.get("horarioTermino").value) {
          this.hourSpan = true;
        } else {
          this.hourSpan = false;
        }
      });

    this.terminoHorario = this.constraintSchedule
      .get("horarioTermino")
      .valueChanges.subscribe((val) => {
        // console.log( val )
        if (val === this.constraintSchedule.get("horarioInicio").value) {
          this.hourSpan = true;
        } else {
          this.hourSpan = false;
        }
      });
  }

  ngOnInit() {
    this.admin = this.shared.getLoginInfo().user_name;

    const data = {
      user: this.admin,
      operation: OPERACIONES.getBatch,
      payload: {},
    };

    this.api.get_constraints_master(data).subscribe((resp) => {
      this.vigente = resp.data.filter((e) => e.field_name === "vigente")[0];
      this.restricciones = resp.data.filter(
        (e) => e.field_name === "tipo_restriccion_horaria"
      )[0].valid_options;
      this.zonas = resp.data.filter(
        (e) => e.field_name === "zona"
      )[0].valid_options;
      if (this.values == undefined) {
        this.spinner.hide();
      }
    });

    this.constraintSchedule.patchValue({
      vigencia: "SI",
      restriccion: "ida",
      horarioInicio: "00:00",
      horarioTermino: "00:00",
      mesInicial: "enero",
      mesFinal: "diciembre",
      diaInicial: "lunes",
      diaFinal: "domingo",
    });

    if (this.values != null && this.plantInfo == undefined) {
      this.plantInfo = {
        code: this.values["ID planta"],
        name: this.values["Planta"],
        restriction: this.values["Tipo de restricción"],
      };

      data.operation = OPERACIONES.read;
      data["payload"]["id_planta"] = this.values["ID planta"];
      data["payload"]["constraint_id"] = this.values["ID restricción"];

      this.api.constraints(data).subscribe((resp) => {
        if (resp.code === 200) {
          this.constraintSchedule.patchValue({
            vigencia: resp.data.vigente,
            cliente:
              resp.data.cliente_id_copec === " "
                ? ""
                : resp.data.cliente_id_copec,
            zona: resp.data.zona === " " ? "" : resp.data.zona,
            restriccion: resp.data.tipo_restriccion_horaria,
            horarioInicio: resp.data.horario_inicio,
            horarioTermino: resp.data.horario_termino,
            mesInicial: resp.data.mes_inicio,
            mesFinal: resp.data.mes_fin,
            diaInicial: resp.data.dia_inicio,
            diaFinal: resp.data.dia_fin,
            comentario: resp.data.comentario,
          });

          if (this.constraintSchedule.get("zona").value !== "") {
            this.constraintSchedule.get("zona").disable();
          }

          if (this.constraintSchedule.get("cliente").value !== "") {
            this.constraintSchedule.get("cliente").disable();
          }
        } else {
          let alert = {
            header: this.alert.setAlert(resp.code),
            code: resp.code,
            body: resp.message,
          };
          this.alert.show(alert);
        }
        this.spinner.hide();
      });
    }
  }

  send() {
    let inicio = this.constraintSchedule.get("horarioInicio");
    let termino = this.constraintSchedule.get("horarioTermino");

    if (inicio.value === termino.value) {
      this.hourSpan = true;
      return;
    }

    this.spinner.show();

    let data: Constraint = {
      user: this.admin,
      operation: "",
      payload: {
        id_planta: this.plantInfo.code,
        planta: this.plantInfo.name,
        tipo_restriccion: this.plantInfo.restriction,
        vigente: this.constraintSchedule.get("vigencia").value,
        cliente_id_copec: this.constraintSchedule.get("zona").value
          ? " "
          : this.constraintSchedule.get("cliente").value,
        zona:
          this.constraintSchedule.get("cliente").value != ""
            ? " "
            : this.constraintSchedule.get("zona").value,
        comentario:
          this.constraintSchedule.get("comentario").value === ("" || null)
            ? " "
            : this.constraintSchedule.get("comentario").value,
        tipo_restriccion_horaria:
          this.constraintSchedule.get("restriccion").value,
        horario_inicio: this.constraintSchedule.get("horarioInicio").value,
        horario_termino: this.constraintSchedule.get("horarioTermino").value,
        mes_inicio: this.constraintSchedule.get("mesInicial").value,
        mes_fin: this.constraintSchedule.get("mesFinal").value,
        dia_inicio: this.constraintSchedule.get("diaInicial").value,
        dia_fin: this.constraintSchedule.get("diaFinal").value,
      },
    };

    this.values !== null
      ? ((data.operation = OPERACIONES.update),
        (data.payload.constraint_id = this.values["ID restricción"]))
      : (data.operation = OPERACIONES.create);

    // // console.log( data )
    this.sendConstraint(data);
  }

  sendConstraint(payload) {
    this.api.constraints(payload).subscribe((resp) => {
      if (resp != null) {
        const { code, message } = resp;
        this.activeModal.close({ code, message });
      } else {
        this.activeModal.close(ERRORINESPERADO);
      }
    });
  }

  campoValido(campo: string) {
    return (
      this.constraintSchedule.controls[campo].errors &&
      this.constraintSchedule.controls[campo].touched
    );
  }
}
