import { Injectable, Inject, Renderer2, RendererFactory2 } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { throwError, Observable } from "rxjs";
import { ENDPOINT_ADMINISTRADOR } from "../constants/endpoints.const";
import { retry, catchError } from "rxjs/operators";
import { Spreadsheet } from "../models/requests.model";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class ApiAdministradorService {
  private renderer: Renderer2;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  apiUrl = environment.apiURL;
  // optimizeUrl = environment.optimizerUrl;

  private handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  //La API está configurada en forma de un solo endpoint para cada tabla, que mediante una petición POST y un payload que especifique la operación a realizar,
  //  retorna los valores correspondientes a la solicitud.

  // Operaciones Admins
  public admins(data: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/${ENDPOINT_ADMINISTRADOR.ADMINS}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }

  //Operaciones Parámetros
  public parameters(data: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/${ENDPOINT_ADMINISTRADOR.PARAMETERS}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }

  //Restricciones
  public constraints(data: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/${ENDPOINT_ADMINISTRADOR.CONSTRAINTS}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }

  public get_constraints_master(data): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/${ENDPOINT_ADMINISTRADOR.CONSTRAINTS_MASTER}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }

  // imputaciones - tiempos de viaje
  public imputations(data: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/${ENDPOINT_ADMINISTRADOR.IMPUTATIONS}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }

  // descarga tabla parámetros | restricciones | tiempos de viaje
  public getLink(data: Spreadsheet): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/${ENDPOINT_ADMINISTRADOR.SPREADSHEET}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }

  async downloadFile(url: string) {
    try {
      const a = this.renderer.createElement("a");
      this.renderer.appendChild(this.document.body, a);
      this.renderer.setAttribute(a, "href", url);
      a.click();
      this.renderer.removeChild(this.document.body, a);
      return "success";
    } catch (error) {
      // console.log( error)
      return "error";
    }
  }

  // SESSION_STATE
  public sessionState(data: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/${ENDPOINT_ADMINISTRADOR.SESSION_STATE}`, data)
      .pipe(retry(3), catchError(this.handleError));
  }
}
