import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ChangeDetectorRef,
} from "@angular/core";
import { ProgramadorComponent } from "../../programador.component";

@Component({
  selector: "truck",
  templateUrl: "./truck.component.html",
  styleUrls: ["./truck.component.css"],
})
export class TruckComponent implements OnInit {
  @Input() info: any;
  truckBackgroundColor: string;
  truckBoxShadow: string;

  constructor(
    private renderer: Renderer2,
    private programador: ProgramadorComponent,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked() {
    this.changeTruckHighlightColor();
    this.cdRef.detectChanges();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.changeTruckBackgroundColor();
  }

  changeTruckBackgroundColor() {
    // // console.log(this.info.fuelTypes)
    // // console.log(this.info.fuelTypeCode)
    // 00001100 Livianos normal
    // 00001200 Livianos aviacion
    // 00001300 Pesados
    this.truckBackgroundColor = "#FFFFFF";
    switch (this.info.fuelTypeCode) {
      case "00001100":
        this.truckBackgroundColor = "#FFFFFF";
        break;
      case "00001200":
        this.truckBackgroundColor = "#B3D8FF";
        break;
      case "00001300":
        this.truckBackgroundColor = "#739BC6";
        break;
      // This is a patch, is not definitive, but is the info that we have
      case " ":
        this.truckBackgroundColor = "#FFFFFF";

      default:
        return;
    }
  }

  changeTruckHighlightColor() {
    switch (this.info.HIGHLIGHTED) {
      case "S":
        this.truckBackgroundColor = "#40DD7F"; // '#DCDCDC'
        this.truckBoxShadow = "0 0 5px #999999";
        break;
      case "N":
        this.truckBoxShadow = " ";
        this.changeTruckBackgroundColor();
        break;
      default:
        return;
    }
  }

  showInfo() {
    this.programador.openTruckDetailsModal(this);
  }

  // del tipo  ["4/5", "8", "9", "10"]
  getMaxCapacity() {
    var arrCapacity = this.info.capacity;
    var sum = 0;
    arrCapacity.forEach((element) => {
      var elSplit = element.split("/");
      var maxCapacity = elSplit[0] * 1;
      // asumimos que los compartimientos cuando vienen con flecha "/", vienen 2 capacidades
      if (elSplit.length > 1) {
        maxCapacity = Math.max(elSplit[0] * 1, elSplit[1] * 1);
      }
      sum += maxCapacity * 1;
    });
    return sum;
  }

  getMinCapacity() {
    var arrCapacity = this.info.capacity;
    var sum = 0;
    arrCapacity.forEach((element) => {
      var elSplit = element.split("/");
      var minCapacity = elSplit[0] * 1;
      // asumimos que los compartimientos cuando vienen con flecha "/", vienen 2 capacidades
      if (elSplit.length > 1) {
        minCapacity = Math.min(elSplit[0] * 1, elSplit[1] * 1);
      }
      sum += minCapacity * 1;
    });
    return sum;
  }

  displayTruckDetailCapacity() {
    return (
      this.getMaxCapacity() + " = " + this.displayTruckWithCapacityDetail("+")
    );
  }

  displayTruckWithCapacity() {
    // // console.log("this.info.capacity")
    // // console.log(this.info.capacity)
    return (
      this.info.truckId * 1 +
      this.hasLiterCount() +
      " (" +
      this.displayTruckDetailCapacity() +
      " )"
    );
    // return this.info.truckId*1 + " (" + this.info.capacity.reduce((a,b) => a + b, 0) + " = " +
    // this.displayTruckWithCapacityDetail("+") + " )"
  }

  displayTruckWithCapacityDetail(tokenSeparator) {
    var sumDetail = "";
    this.info.capacity.forEach((element, index, arr) => {
      sumDetail +=
        index < arr.length - 1 ? element + " " + tokenSeparator + " " : element;
    });
    return sumDetail;
  }
  // dibuja la disponibilidad de turnos en las celdas del programador.
  renderShifts() {}

  hasLiterCount() {
    if (this.info.countLiter == true) {
      return "c";
    }
    return "s";
  }
}
