import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class SubscribersProgramadorServices {
  private _modTrukSubject = new BehaviorSubject<boolean>(true);

  constructor() {}

  public modalToProgramador() {
    return this._modTrukSubject.next(true);
  }

  public modalToProgramadorObservable(): Observable<boolean> {
    return this._modTrukSubject.asObservable();
  }
}
