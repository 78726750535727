import { Injectable } from "@angular/core";
import { ApiAdministradorService } from "./api-administrador.service";
import { SharedDataService } from "../../../global/services/shared-data.service";
import { OPERACIONES } from "../constants/operations.const";
import { Observable } from "rxjs";
import {
  Administradores,
  Imputaciones,
  Restricciones,
  Sesiones,
} from "../constants/theaders.const";

@Injectable({
  providedIn: "root",
})
export class SetDeleteService {
  /**
   * Servicio utilizado por el modal eliminar de la tabla para  eliminar el recurso seleccionado
   */

  constructor(
    private api: ApiAdministradorService,
    private shared: SharedDataService
  ) {}

  public setEndpointandSend(data: any, endpoint: string): Observable<any> {
    /**
     * @param data Es la información correspondiente al objeto a eliminar; se utilia en el método makeData.
     * @param endpoint Endpoint de la tabla a eliminar. Al igual que data, es un argumento de makeData.
     */
    let send;
    let payload;
    /**
     * send y payload son variables locales: payload será el objeto requerido en la solicitud, y send guardará el resultado de dicha operación
     * retornando el observable en el modal y poder ser suscrito.
     */
    switch (endpoint) {
      case "administradores":
        payload = this.makeData(data, endpoint);
        send = this.api.admins(payload);
        break;
      case "tiempos-de-viaje":
        payload = this.makeData(data, endpoint);
        send = this.api.imputations(payload);
        break;
      case "restricciones":
        payload = this.makeData(data, endpoint);
        send = this.api.constraints(payload);
        break;
      case "sesiones":
        payload = this.makeData(data, endpoint);
        send = this.api.sessionState(payload);
        break;
      default:
        payload = "";
        break;
    }

    return send;
  }

  private makeData(data: any, endpoint: string) {
    /**
     * @param data Objeto que se desea eliminar de la tabla; se utiliza para rescatar los datos necesarios para armar el objeto de la solicitud DELETE
     * @param endpoint Endpoint correspondiente a la tabla donde se ejecutará la petición. Cada uno de estos requiere info adicional de la fila para
     * eliminar el recurso.
     */

    let requested = {
      user: this.shared.getLoginInfo().user_name,
      operation: OPERACIONES.delete,
      payload: {},
    };
    //requested es el esquema básico del payload a enviar. Dependiendo del endpoint, se le agregaran propiedades para hacer efectiva la solicitud.
    //Refiérase al switch case de abajo.

    switch (endpoint) {
      case "administradores":
        requested.payload["sap_user"] = data[Administradores.sap_user];
        break;
      case "tiempos-de-viaje":
        requested.payload["origin"] = data[Imputaciones.origin];
        requested.payload["destination"] = data[Imputaciones.destination];
        break;
      case "restricciones":
        requested.payload["id_planta"] = data[Restricciones.id_planta];
        requested.payload["constraint_id"] = data[Restricciones.constraint_id];
        break;
      case "sesiones":
        requested.payload["session_date"] = data[Sesiones.session_date];
        requested.payload["plant"] = data[Sesiones.plant];
        break;
    }

    return requested;
  }
}
