export const GLOBAL = {
  SIGNED_URL: "https://copec-s3-",
  CALC_WHITE_SPACE_VAL: 174,
  MOTIVOS_BAJA: [
    "Equipo no disponible",
    "Equipo con bloqueo de seguridad GTI",
    "Conductor no disponible",
    "Emergencia (pedido del día para el día)",
    "Acuerdo con el cliente (lo pide el cliente)",
    "Acuerdo con el cliente (lo pide COPEC)",
    "Mejora de programación",
  ],
  MOTIVOS_DESCONFIRMACION: [
    "Equipo no disponible",
    "Equipo con bloqueo de seguridad GTI",
    "Conductor no disponible",
    "Emergencia (pedido del día para el día)",
    "Acuerdo con el cliente (lo pide el cliente)",
    "Acuerdo con el cliente (lo pide COPEC)",
    "Pedido mal programado",
    "Camión llega atrasado",
    "Camión en Panne",
    "Camión/Conductor no se presenta",
  ],
};
