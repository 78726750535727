import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiAdministradorService } from "../../pages/administrador/services/api-administrador.service";
import { SharedDataService } from "../../global/services/shared-data.service";
import {
  OPERACIONES,
  ERRORINESPERADO,
} from "../../pages/administrador/constants/operations.const";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-modal-edit-parametros",
  templateUrl: "./modal-edit-parametros.component.html",
  styleUrls: ["./modal-edit-parametros.component.css"],
})
export class ModalEditParametrosComponent implements OnInit {
  @Input() values;
  data;
  admin;
  newValue: number;

  constructor(
    private api: ApiAdministradorService,
    private shared: SharedDataService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.admin = this.shared.getLoginInfo().user_name;
    this.newValue = this.values["Valor"];
    this.spinner.hide();
  }

  validatorValue(input) {
    let validation = Number.isNaN(+input);
    return validation;
  }

  validatorPristine(value: boolean) {
    if (value) {
      return true;
    }
    return false;
  }

  submit() {
    this.spinner.show();
    const data = {
      user: this.admin,
      operation: OPERACIONES.update,
      payload: {
        name: this.values["Nombre"],
        value: +this.newValue,
      },
    };
    this.send(data);
  }

  send(payload) {
    this.api.parameters(payload).subscribe((resp) => {
      if (resp != null) {
        const { code, message } = resp;
        this.activeModal.close({ code, message });
      } else {
        this.activeModal.close(ERRORINESPERADO);
      }
    });
  }
}
