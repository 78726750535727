import { Injectable } from "@angular/core";
import { ResponseAdmin } from "../models/response.models";
import {
  Administradores,
  Imputaciones,
  Parametros,
  Propuestas,
  Restricciones,
  Sesiones,
} from "../constants/theaders.const";
import { ModalConstraintCapacidadComponent } from "src/app/modals/modal-constraint-capacidad/modal-constraint-capacidad.component";
import { ModalConstraintFlotaComponent } from "../../../modals/modal-constraint-flota/modal-constraint-flota.component";
import { ModalConstraintHorarioComponent } from "../../../modals/modal-constraint-horario/modal-constraint-horario.component";
import { ModalConstraintMotoCuentaComponent } from "../../../modals/modal-constraint-moto-cuenta/modal-constraint-moto-cuenta.component";

@Injectable({
  providedIn: "root",
})
export class TransformService {
  /**
   * Este servicio engloba métodos para, como su nombre indica, transformar la data recibida de las consultas a las tablas administrativas ( administradores,
   * restricciones, imputaciones y parámetros ).
   */

  constructor() {}

  transformDate(input: number) {
    // recibe un valor numérico de tipo timestamp y lo convierte a fecha en formato DD/MM/AAAA hh/mm/ss
    let date = new Date(input * 1000);
    let day = `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
    let month = `${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }`;
    let hours = `${
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
    }`;
    let minutes = `${
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
    }`;
    let seconds = `${
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
    }`;

    return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
  }

  transformObject(type: string, data: any) {
    /**
     * @description Método para convertir el objeto de la solicitud http, para poblar la tabla. Recibe el tipo  y la data.
     * La transformación consiste en rearmar el objeto, modificando las claves por su versión en español y transformando los valores de tipo timestamp a fecha.
     *
     * @param type Corresponde a la tabla que será consultada y en consecuencia, el objeto que se armará.
     * @param data Es la data que retorna la solicitud http, que será transformada para poblar la tabla.
     *
     *
     */
    let temp = [...data];
    let assign = this.setType(type);
    let info = temp.map((e, idx) => {
      let newObj = {};
      for (let item in assign) {
        if (item.toLowerCase().includes("timestamp")) {
          newObj[assign[item]] = this.transformDate(e[item]);
        } else if (item.includes("proposal_date")) {
          newObj[assign[item]] = this.reverseDate(e[item]);
        } else {
          newObj[assign[item]] = e[item];
        }
      }
      return newObj;
    });
    return info;
  }

  setType(type: string) {
    /**
     * @description Método utilizado para asignar el objeto correspondiente para la conversión/traducción de las claves.
     */
    let val;
    switch (type) {
      case "administrador":
        val = { ...Administradores };
        break;
      case "parametros":
        val = { ...Parametros };
        break;
      case "propuestas":
        val = { ...Propuestas };
        break;
      case "restricciones":
        val = { ...Restricciones };
        break;
      case "tiempos-de-viaje":
        val = { ...Imputaciones };
        break;
      case "sesion":
        val = { ...Sesiones };
        break;
      default:
        "administrador";
        break;
    }

    return val;
  }

  manageModalNewConstraint(type: string) {
    /**
     * @description método utilizado en la pantalla ddministrativa de restricciones, para asignar el modal que se desplegará de acuerdo a lo solicitado.
     */
    let value;
    switch (type) {
      case "Capacidad":
        value = ModalConstraintCapacidadComponent;
        break;
      case "Flota dedicada":
        value = ModalConstraintFlotaComponent;
        break;
      case "Flota no permitida":
        value = ModalConstraintFlotaComponent;
        break;
      case "Horario":
        value = ModalConstraintHorarioComponent;
        break;
      case "Motobomba - Cuentalitros":
        value = ModalConstraintMotoCuentaComponent;
        break;
    }

    return value;
  }

  deconvertTime(datestring: string) {
    /**
     * @description Método utilizado para convertir nuevamente los valores de fecha a timestamp y poder realizar el ordenamiento asc, desc.
     */

    if (Array.isArray(datestring)) {
      return;
    }

    let date = datestring.split(" ");
    let day = date[0].split("/");
    let dayU = [day[1], day[0], day[2]];
    if (dayU.includes(undefined)) {
      return;
    }
    dayU.push(" ");
    let hours = date[1];
    let timestamp = dayU.concat(hours).join(" ");
    return Math.round(new Date(timestamp).getTime() / 1000);
  }

  reverseDate(datestring: string) {
    return datestring.split("-").reverse().join("-");
  }
}
