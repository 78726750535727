import { Component, OnInit, ElementRef, Input } from "@angular/core";

@Component({
  selector: "shadow-highlight",
  templateUrl: "./shadow-highlight.component.html",
  styleUrls: ["./shadow-highlight.component.css"],
})
export class ShadowHighlightComponent implements OnInit {
  @Input() position: string;
  width: string;
  height: string;
  display: string;
  URLeft: any;
  URTop: any;
  URHeight: any;
  URWidth: any;
  ULTop: any;
  ULLeft: any;
  DLTop: any;
  DLWidth: any;
  DLHeight: any;
  DRTop: any;
  DRWidth: any;
  DRHeight: any;
  DRLeft: any;
  dataMatched: any;

  constructor(public element: ElementRef) {}

  ngOnInit() {
    this.display = "none";
  }

  updateUL(obj) {
    // // console.log("UR obj")
    // // console.log(obj)
    this.width = obj.width; // this.programador.pivotReference[this.position].width
    this.height = obj.height; // this.programador.pivotReference[this.position].height
    this.ULTop = obj.top;
    this.ULLeft = obj.left;
    // this.anchorRight = obj.right
    // this.anchorBottom = obj.bottom
  }
  updateUR(obj) {
    // // console.log("DR obj")
    // // console.log(obj)
    this.URTop = obj.top;
    this.URLeft = obj.left;
    this.URHeight = obj.height;
    this.URWidth = obj.width;
  }

  updateDL(obj) {
    // // console.log("DL obj")
    // // console.log(obj)
    this.DLTop = obj.top;
    this.DLWidth = obj.width;
    this.DLHeight = obj.height;
  }

  updateDR(obj, cellMatched: string) {
    // // console.log("DR obj")
    // // console.log(obj)
    this.DRTop = obj.top;
    this.DRLeft = obj.left;
    this.DRHeight = obj.height;
    this.dataMatched = cellMatched.split(";");
    if (this.dataMatched[2].length < 2) {
      this.dataMatched[2] = "0" + this.dataMatched[2];
    }
    if (this.dataMatched[3].length < 2) {
      this.dataMatched[3] = "0" + this.dataMatched[3];
    }
  }

  show() {
    this.display = "block";
  }
  hide() {
    this.display = "none";
  }

  isUL() {
    if (this.position == "UL") {
      return true;
    }
    return false;
  }
  setUL() {
    this.position = "UL";
  }

  //
  isUR() {
    if (this.position == "UR") {
      return true;
    }
    return false;
  }
  setUR() {
    this.position = "UR";
  }

  //
  isDL() {
    if (this.position == "DL") {
      return true;
    }
    return false;
  }
  setDL() {
    this.position = "DL";
  }

  //
  isDR() {
    if (this.position == "DR") {
      return true;
    }
    return false;
  }
  setDR() {
    this.position = "DR";
  }
}
