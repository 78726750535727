import { Component, OnDestroy, OnInit } from "@angular/core";
import { UtilsService } from "../../global/utils/utils.service";

declare var $: any;

@Component({
  selector: "modal-details-truck",
  templateUrl: "./modal-details-truck.component.html",
  styleUrls: ["./modal-details-truck.component.css"],
})
export class ModalDetailsTruckComponent implements OnInit, OnDestroy {
  programador: any;
  InfoColOne: any;
  InfoColTwo: any[];
  tankInfo: any[];
  private eventListener: any;

  constructor(private utils: UtilsService) {}

  ngOnInit() {
    this.eventListener = window.addEventListener(
      "keyup",
      (e: KeyboardEvent) => {
        if (e.code == "Escape") {
          $("#truck-details-modal").modal("hide");
        }
      }
    );
  }

  setProgramador(programador) {
    this.programador = programador;
  }

  show() {
    $("#truck-details-modal").modal("show");
  }

  setTruck(truck) {
    // TODO
    this.InfoColOne = [];
    this.InfoColOne.push({
      name: "Número de camión",
      value: truck.info.truckId,
    });
    this.InfoColOne.push({
      name: "Tipo de producto",
      value: truck.info.fuelTypes,
    });
    this.InfoColOne.push({
      name: "Detalle",
      value: truck.displayTruckDetailCapacity(),
    });
    // this.InfoColOne.push({name:"Detalle", value: truck.info.capacity.reduce((a,b)=> a+b,0 )
    // + " = " + truck.displayTruckWithCapacityDetail("-")  });

    this.InfoColTwo = []; // Verificar si camiones traen capacidad minima y max.
    this.InfoColTwo.push({
      name: "Capacidad Mínima",
      value: truck.getMaxCapacity(),
    });
    this.InfoColTwo.push({
      name: "Capacidad Máxima",
      value: truck.getMinCapacity(),
    });

    // this.InfoColTwo.push({name:"Capacidad Mínima", value:this.getTotalCapacity(truck.info.capacity)});
    // this.InfoColTwo.push({name:"Capacidad Máxima", value:this.getTotalCapacity(truck.info.capacity)});

    this.tankInfo = [];
    this.tankInfo.push({
      name: "ID",
      value: this.generateTankId(truck.info.capacity),
    });
    this.tankInfo.push({
      name: "Volumen mínimo",
      value: this.utils.generateVolumenDetailMin(truck.info.capacity),
    });
    this.tankInfo.push({
      name: "Volumen máximo",
      value: this.utils.generateVolumenDetailMax(truck.info.capacity),
    });
  }

  // input an array of numbers with truck capacity
  // each number represents the liters of capacity, in 1000L
  getTotalCapacity(capacity) {
    return 1000 * capacity.reduce((a, b) => a + b, 0);
  }

  // input an array of numbers with truck capacity
  generateTankId(capacity) {
    var arrIds = [];
    capacity.forEach((element, index) => {
      arrIds.push(index + 1);
    });
    return arrIds;
  }
  // input an array of numbers with truck capacity
  // each number represents the liters of capacity, in 1000L
  /*
  generateVolumenDetail(capacity){
    var arrCapacityDetail = []
    capacity.forEach(element => {
      arrCapacityDetail.push(element*1000)
    });
    return arrCapacityDetail;
  }
  */

  generateVolumenDetailMin(capacity) {
    var arrCapacityDetail = [];
    capacity.forEach((element) => {
      var spl = element.split("/");
      var cap = spl[0];
      if (spl.length > 1) {
        cap = Math.min(spl[0], spl[1]);
      }
      arrCapacityDetail.push(cap * 1000);
    });
    return arrCapacityDetail;
  }

  generateVolumenDetailMax(capacity) {
    var arrCapacityDetail = [];
    capacity.forEach((element) => {
      var spl = element.split("/");
      var cap = spl[0];
      if (spl.length > 1) {
        cap = Math.max(spl[0], spl[1]);
      }
      arrCapacityDetail.push(cap * 1000);
    });
    return arrCapacityDetail;
  }

  ngOnDestroy(): void {
    window.removeEventListener("keyup", this.eventListener);
  }
}
