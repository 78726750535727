import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedDataService } from "src/app/global/services/shared-data.service";
import { ApiAdministradorService } from "src/app/pages/administrador/services/api-administrador.service";
import {
  OPERACIONES,
  ERRORINESPERADO,
} from "../../pages/administrador/constants/operations.const";
import {
  MONTHS,
  DAYS,
} from "../../pages/administrador/constants/calendar.const";
import { AlertService } from "../../pages/administrador/services/alert.service";
import { Constraint } from "src/app/pages/administrador/models/requests.model";
import { Subscription } from "rxjs";

@Component({
  selector: "app-modal-constraint-capacidad",
  templateUrl: "./modal-constraint-capacidad.component.html",
  styleUrls: ["./modal-constraint-capacidad.component.css"],
})
export class ModalConstraintCapacidadComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() values;
  @Input() plantInfo;
  admin;
  useRestriction: Subscription;
  min: Subscription;
  valMinCMax;
  valMinCMin = 1;
  vigente;
  especificacion;
  months = [...MONTHS];
  days = [...DAYS];

  numericPattern = new RegExp(/^(0|[1-9][0-9]*)$/);

  constraintCapacity = this.fb.group({
    vigencia: ["", Validators.required],
    cliente: [
      "",
      [Validators.required, Validators.pattern(this.numericPattern)],
    ],
    especificacion: ["", Validators.required],
    capacidadMin: ["", [Validators.required, Validators.min(1)]],
    capacidadMax: ["", [Validators.required]],
    mesInicial: [, Validators.required],
    mesFinal: [, Validators.required],
    diaInicial: [, Validators.required],
    diaFinal: [, Validators.required],
    comentario: [],
  });

  constructor(
    private shared: SharedDataService,
    private api: ApiAdministradorService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private alert: AlertService
  ) {}

  ngOnDestroy(): void {
    this.useRestriction.unsubscribe();
    this.min.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.useRestriction = this.constraintCapacity
      .get("especificacion")
      .valueChanges.subscribe((value) => {
        if (value !== "entre") {
          this.constraintCapacity.get("capacidadMax").reset();
          this.constraintCapacity
            .get("capacidadMax")
            .setValue(this.constraintCapacity.get("capacidadMin").value);
          this.constraintCapacity.get("capacidadMax").updateValueAndValidity();
          this.constraintCapacity.get("capacidadMax").disable();
        } else {
          this.constraintCapacity.get("capacidadMax").enable();
          const validator =
            parseInt(this.constraintCapacity.get("capacidadMin").value) + 1;
          this.constraintCapacity
            .get("capacidadMax")
            .setValidators(Validators.min(validator));
          this.constraintCapacity.get("capacidadMax").updateValueAndValidity();
        }
      });

    this.min = this.constraintCapacity
      .get("capacidadMin")
      .valueChanges.subscribe((value) => {
        if (this.constraintCapacity.get("especificacion").value !== "entre") {
          this.constraintCapacity
            .get("capacidadMax")
            .setValue(this.constraintCapacity.get("capacidadMin").value);
          this.constraintCapacity.get("capacidadMax").updateValueAndValidity();
        } else {
          this.valMinCMax = value;
          const validator = value + 1;
          this.constraintCapacity
            .get("capacidadMax")
            .setValidators(Validators.min(validator));
          this.constraintCapacity.get("capacidadMax").updateValueAndValidity();
        }
      });
  }

  ngOnInit() {
    this.admin = this.shared.getLoginInfo().user_name;

    const data = {
      user: this.admin,
      operation: OPERACIONES.getBatch,
      payload: {},
    };

    this.api.get_constraints_master(data).subscribe((resp) => {
      this.vigente = resp.data.filter((e) => e.field_name === "vigente")[0];
      this.especificacion = resp.data.filter(
        (e) => e.field_name === "tipo_especificacion"
      )[0];
      if (this.values == undefined) {
        this.spinner.hide();
      }
    });

    this.constraintCapacity.patchValue({
      vigencia: "SI",
      especificacion: "entre",
      mesInicial: "enero",
      mesFinal: "diciembre",
      diaInicial: "lunes",
      diaFinal: "domingo",
      comentario: " ",
    });

    if (this.values != null && this.plantInfo == undefined) {
      this.plantInfo = {
        code: this.values["ID planta"],
        name: this.values["Planta"],
        restriction: this.values["Tipo de restricción"],
      };

      data.operation = OPERACIONES.read;
      data["payload"]["id_planta"] = this.values["ID planta"];
      data["payload"]["constraint_id"] = this.values["ID restricción"];

      this.api.constraints(data).subscribe((resp) => {
        if (resp.code === 200) {
          this.constraintCapacity.patchValue({
            vigencia: resp.data.vigente,
            cliente: resp.data.cliente_id_copec,
            especificacion: resp.data.tipo_especificacion,
            capacidadMin: resp.data.capacidad_min,
            capacidadMax: resp.data.capacidad_max,
            mesInicial: resp.data.mes_inicio,
            mesFinal: resp.data.mes_fin,
            diaInicial: resp.data.dia_inicio,
            diaFinal: resp.data.dia_fin,
            comentario: resp.data.comentario,
          });
          this.constraintCapacity.get("cliente").disable();
          this.constraintCapacity
            .get("capacidadMax")
            .setValidators(Validators.min(+resp.data.capacidad_min + 1));
          this.constraintCapacity.get("capacidadMax").updateValueAndValidity();

          this.spinner.hide();
        } else {
          let alert = {
            header: this.alert.setAlert(resp.code),
            code: resp.code,
            body: resp.message,
          };
          this.alert.show(alert);
          this.spinner.hide();
        }
      });
    }
  }

  public send() {
    this.spinner.show();

    let data: Constraint = {
      user: this.admin,
      operation: "",
      payload: {
        id_planta: this.plantInfo.code,
        planta: this.plantInfo.name,
        tipo_restriccion: this.plantInfo.restriction,
        vigente: this.constraintCapacity.get("vigencia").value,
        cliente_id_copec: this.constraintCapacity.get("cliente").value,
        comentario:
          this.constraintCapacity.get("comentario").value === ""
            ? " "
            : this.constraintCapacity.get("comentario").value,
        tipo_especificacion:
          this.constraintCapacity.get("especificacion").value,
        capacidad_min: this.constraintCapacity.get("capacidadMin").value,
        capacidad_max: this.constraintCapacity.get("capacidadMax").value,
        mes_inicio: this.constraintCapacity.get("mesInicial").value,
        mes_fin: this.constraintCapacity.get("mesFinal").value,
        dia_inicio: this.constraintCapacity.get("diaInicial").value,
        dia_fin: this.constraintCapacity.get("diaFinal").value,
      },
    };

    this.values !== null
      ? ((data.operation = OPERACIONES.update),
        (data.payload.constraint_id = this.values["ID restricción"]))
      : (data.operation = OPERACIONES.create);

    this.sendConstraint(data);
  }

  sendConstraint(payload) {
    this.api.constraints(payload).subscribe((resp) => {
      if (resp != null) {
        const { code, message } = resp;
        this.activeModal.close({ code, message });
      } else {
        this.activeModal.close(ERRORINESPERADO);
      }
    });
  }

  campoValido(campo: string) {
    return (
      this.constraintCapacity.controls[campo].errors &&
      this.constraintCapacity.controls[campo].touched
    );
  }
}
