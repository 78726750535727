import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { ProgramadorComponent } from "../../programador.component";
import { normalize } from "path";
import { NgModel } from "@angular/forms";
import { ProtractorExpectedConditions } from "protractor";
import { stringify } from "querystring";
import { UtilsService } from "src/app/global/utils/utils.service";
import { ThrowStmt } from "@angular/compiler";
import { ENUMS } from "src/app/global/enums/enums";
import _ from "lodash";
import { MESSAGES } from "../../../../global/constants/messages.const";
import { SessionService } from "src/app/global/services/session.service";
import { ProgramadorFunctions } from "../../functions/programador.functions";

// This lets me use jquery
declare var $: any;

@Component({
  selector: "orden-programada",
  templateUrl: "./orden-programada.component.html",
  styleUrls: ["./orden-programada.component.css"],
})

// represents the draggable box that is a programmed order.
export class OrdenProgramadaComponent implements OnInit {
  highLightColor: string;
  fontSize: string;
  overlapped: boolean;

  @HostListener("click", ["$event"])
  selectPedido(event: MouseEvent) {
    if (event.shiftKey) {
      if (this.plannedOrder["WEB_SELECTED"] != undefined) {
        this.plannedOrder["WEB_SELECTED"].classList.remove("selected");
        delete this.plannedOrder["WEB_SELECTED"];

        // si es que no hay mas pedidos seleccionados, quitamos el estado de seleccion.
        this.programador.checkSeleccionadosCero();
      } else {
        // // console.log(this.element.nativeElement.children[0])
        this.plannedOrder["WEB_SELECTED"] =
          this.element.nativeElement.children[0].children[1];
        this.plannedOrder["WEB_SELECTED"].classList.add("selected");
        this.programador.seleccionPedidos = true;
      }
    }
  }

  parentCell: any;
  orderLenghtMinutes: number;
  orderLenght: string;
  orderLengthPx: number;
  labelWidth: number;
  labelWidthWarning: number;

  // contains the details of planned order.
  plannedOrder: any;
  // tiene el nombre del cliente que se muestra en la orden de la grilla. En caso de ser un pedido combinado, se muestran los nombres de clientes separados por un slash /
  clientNameComposed: string;
  pedidosCombinados: any;
  // FromYesterday: boolean;

  constructor(
    private utils: UtilsService,
    private renderer: Renderer2,
    private programador: ProgramadorComponent,
    public element: ElementRef,
    private cdRef: ChangeDetectorRef,
    private sessionService: SessionService,
    private programadorFunctions: ProgramadorFunctions
  ) {}

  ngOnInit() {
    this.labelWidth = this.orderLengthPx;
    this.labelWidthWarning = this.orderLengthPx - 41;
    this.hasWarnings();
    this.hasErrors();
  }

  isFromBeforeToday() {
    // Yesterday(){

    var planCurrentDay = new Date(
      parseInt(this.programador.plantData.date.split("-")[2]),
      parseInt(this.programador.plantData.date.split("-")[1]) - 1,
      parseInt(this.programador.plantData.date.split("-")[0])
    );
    var orderBegData = new Date(
      parseInt(
        this.utils.yyyymmddToDate(this.plannedOrder.PRO_BEGDA).split("-")[2]
      ),
      parseInt(
        this.utils.yyyymmddToDate(this.plannedOrder.PRO_BEGDA).split("-")[1]
      ) - 1,
      parseInt(
        this.utils.yyyymmddToDate(this.plannedOrder.PRO_BEGDA).split("-")[0]
      )
    );

    if (orderBegData < planCurrentDay) {
      return true;
    }

    return false;
  }
  public ngAfterViewInit() {
    if (this.plannedOrder["WEB_SELECTED"] != undefined) {
      this.plannedOrder["WEB_SELECTED"] =
        this.element.nativeElement.children[0].children[0];
      this.plannedOrder["WEB_SELECTED"].classList.add("selected");
    }
  }

  // determina el color de la barrita de pedido de acuerdo al estado.
  getStatusClass() {
    var claseBase = "status-bar";
    if (this.hasWarnings()) {
      claseBase = "status-bar status-orange";
    }
    // priorizamos errors para mostrar en caso de haber warnings
    if (this.hasErrors()) {
      claseBase = "status-bar status-red";
    }
    return claseBase;
  }

  getClass() {
    // // console.log("obteniendo clase")
    // "programmed-order getConfirmed()"
    var claseBase = "programmed-order";
    // if(this.plannedOrder.CONFIRMADO=="N"){
    if (!this.programadorFunctions.esPedidoConfirmado(this.plannedOrder)) {
      claseBase += " not-confirmed";
    }
    /*if(this.overlapped){
      claseBase += " pedido-overlapped"
    }*/
    if (this.plannedOrder["overlapped"] == true) {
      claseBase += " pedido-overlapped";
    }
    if (this.plannedOrder.BSARK == "ZPPC") {
      if (this.plannedOrder.DINAMO === ENUMS.IS_DINAMO) {
        claseBase += " pedido-dinamo-preprogramado";
      } else {
        claseBase += " pedido-preprogramado";
      }
    } else {
      if (this.plannedOrder.DINAMO === ENUMS.IS_DINAMO) {
        claseBase += " pedido-dinamo";
      } else {
        claseBase += " pedido-standard";
      }
    }
    return claseBase;
  }

  esCombinado() {
    // // console.log("verificando que es combinado")
    // check if planned order has agrupador and are more than one
    var agrupador = this.plannedOrder.AGRUPADOR;
    if (agrupador.trim() != "") {
      if (
        this.programador.pedidos.filter((e) => e.AGRUPADOR == agrupador)
          .length > 0
      )
        return true;
    }
    return false;
  }

  checkOverlapped() {
    this.overlapped = false;

    if (this.plannedOrder["VBELN"] in this.programador.overlapped_dict) {
      // this.overlapped = true
      this.plannedOrder["overlapped"] = true;
    }
    // // console.log("planned order")
    // // console.log(this.plannedOrder)
    /*if(this.plannedOrder["overlapped"]==true){
      // console.log("Entro en condicion de overlapped")
      this.overlapped = true
    }*/
    /*if(this.pedidosCombinados.length < 2)
      return this.overlapped
    this.pedidosCombinados.forEach(element => {
      if(element["AGRUPADOR"].trim()=="")
        this.overlapped = true
    });*/

    return this.overlapped;
  }

  // definimos el largo de la orden con la duracion de grupo que debiera venir de sap. Duracion Grupo
  // entendemo que se considera de que el camion sale a cdo llega de vuelta a la planta.
  setOrderObject(plannedOrder) {
    // // console.log("setOrderObject()")
    // fijar condicion que sean mismo grupo
    // // console.log("SET ORDER OBJECT!")
    // // console.log(plannedOrder)
    if (this.pedidosCombinados == undefined) {
      this.pedidosCombinados = [];
    }
    // che
    this.pedidosCombinados.push(plannedOrder);
    // // console.log(this.pedidosCombinados)

    this.plannedOrder = plannedOrder;

    if (this.checkOverlapped()) {
      // console.log('FOUND OVERLAPPED');
    }

    // calculamos el largo del pedido a mostrar, de acuerdo a la duracion si es pedido combinado o no
    // var duracion=plannedOrder["DURACION"];
    //si es un peiddo combinado, se utiliza DURACION_GRUPO, en caso contrario, DURACION_CALCULA
    var duracion = plannedOrder["DURACION_CALCULA"];
    if (plannedOrder.AGRUPADOR.trim() != "") {
      duracion = plannedOrder["DURACION_GRUPO"];
    }

    let totalPlannedOrderLength = this.utils.hhmmssSeparateWithColons(duracion);
    if (this.isFromBeforeToday()) {
      var proInicio = this.utils.dbDateTimeToTimestamp(
        this.plannedOrder.PRO_BEGDA,
        this.plannedOrder.PRO_BEGTI
      );

      var yesterdayYear = parseInt(
        this.utils.yesterday(this.programador.plantData.date).split("-")[2]
      );
      var yesterdayMonth =
        parseInt(
          this.utils.yesterday(this.programador.plantData.date).split("-")[1]
        ) - 1;
      var yesterdayDay = parseInt(
        this.utils.yesterday(this.programador.plantData.date).split("-")[0]
      );
      var yesterdayCutInitHour = this.programador.gridData[0].hourFrom;

      var cutHourYesterday = new Date(
        yesterdayYear,
        yesterdayMonth,
        yesterdayDay,
        yesterdayCutInitHour
      );

      if (proInicio < cutHourYesterday.getTime()) {
        var endTimeDate = this.utils.generateDate(
          this.plannedOrder.PRO_BEGDA,
          this.plannedOrder.PRO_BEGTI
        );

        var duracionGrupoSemicolon = this.utils.hhmmssSeparateWithColons(
          this.plannedOrder.DURACION_CALCULA
        );

        if (this.esCombinado()) {
          duracionGrupoSemicolon = this.utils.hhmmssSeparateWithColons(
            this.plannedOrder.DURACION_GRUPO
          );
          // // console.log(duracionGrupoSemicolon)
        }
        var minutes = this.utils.hhmmssToMinutes(duracionGrupoSemicolon);

        var duracionGrupoMillisec = this.utils.minutesToMilliseconds(minutes);

        var t = endTimeDate.getTime() + duracionGrupoMillisec;
        endTimeDate.setTime(t);

        var proEndTime = endTimeDate.getTime();

        var timeDelta = 0;
        if (cutHourYesterday.getTime() < proEndTime) {
          timeDelta = Math.abs(cutHourYesterday.getTime() - proEndTime);
        }

        this.utils.setOrderLenght(
          this.utils.millisecondsToHHMMSS(timeDelta),
          this
        );
      } else {
        // // console.log("B")
        this.utils.setOrderLenght(totalPlannedOrderLength, this);
      }
    } else {
      // // console.log("C")
      this.utils.setOrderLenght(totalPlannedOrderLength, this);
      // this.utils.setOrderLenght("01:00:00",this)
    }
    // this.clientNameComposed = this.generateNameComposed(plannedOrder.order)
  }

  /*
  //genera el nombre con el que el pedido se despliega
  generateNameComposed(orderArray){
    var clientComposedName="";
    orderArray.forEach((order,index,arr) => {
      clientComposedName += index == arr.length-1? order.clientName : order.clientName + "/";
    });
    return clientComposedName;
  }
  */

  // obtiene la suma del largo en hh:mm:ss de todos los pedidos en el array dado
  getSumLengts(clientTimesArray) {
    // orderArray){
    var minutesAcum = 0;
    clientTimesArray.forEach((ct) => {
      minutesAcum += this.utils.hhmmssToMinutes(ct.time);
    });

    let hhmmss = this.minutesToHMS(minutesAcum);
    return hhmmss;
  }
  // give minutes, return hh:mm:ss
  minutesToHMS(minutes) {
    let hh = Math.floor(minutes / 60);
    let mm = Math.floor((minutes / 60 - hh) * 60);
    let ss = Math.floor(((minutes / 60 - hh) * 60 - mm) * 60);
    let ret = hh + ":" + mm + ":" + ss;

    return ret;
  }

  @HostListener("dblclick", ["$event"])
  showDetails(event: MouseEvent) {
    if (event.shiftKey) {
      // dont show details if shift is pressed
      return;
    }
    if (this.pedidosCombinados.length == 1) {
      this.programador.openOrderDetailsModal(this.pedidosCombinados[0]);
    } else {
      // ABRIR DETALLES PEDIDO COMBINADO
      this.programador.openOrderCombinedDetailsModal(this.pedidosCombinados);
    }
    // this.programador.openOrderDetailsModal(this.plannedOrder);
  }

  setParentCell(cell) {
    this.parentCell = cell;
    if (!this.isFromBeforeToday()) {
      this.utils.validateOrderConditions(
        this.programador,
        this.parentCell,
        this,
        false
      );
    }
  }

  /*
  setOrderLenght(length:string){
    this.orderLenghtMinutes=this.utils.hhmmssToMinutes(length);
    this.sizeInPx();
  }
  */
  drag(e) {
    // // console.log("DRAGGING")
    // // console.log(this.programador.pedidos)

    this.programador.clearHighLight();

    let left = e.source.element.nativeElement.getBoundingClientRect().left;
    let top = e.source.element.nativeElement.getBoundingClientRect().top;
    // let match = document.elementsFromPoint(left, top).filter(element => element.className == "minute-cell");
    let match = document
      .elementsFromPoint(left, top)
      .filter((element) => this.hasClass(element, "minute-cell"));

    if (match.length > 0) {
      this.programador.cellMatched = _.get(match[0], "id");
      var coord_obj = this.utils.calculatePositionsShadow(match, this);
      // day;truck;h;m
      // let cellCoords = match[0].id.split(";");
      // this.programador.highLightCells(cellCoords[0], cellCoords[1], parseInt(cellCoords[2]), parseInt(cellCoords[3]) , coord_obj)
      this.programador.highLightCells(coord_obj);
    }
  }

  // give x1,x2 and y and check if there is an intersection in X
  checkIntersectionX(x1, x2, y, className) {
    for (var x = x1; x <= x2; x = x + 1) {
      let matchX = document
        .elementsFromPoint(x, y)
        .filter((element) => this.hasClass(element, className));
      if (matchX.length > 1) {
        return { check: true, match: matchX };
      }
    }
    return { check: false, match: [] };
  }

  drop(e) {
    // console.log('DROP!!');
    // console.log(this.programador.pedidos);
    // console.log('==');
    // // console.log("drop prog.")
    // new
    // this.programador.shadowUL.instance.hide()
    // this.plannedOrder["overlapped"] = true
    // this.getClass()
    if (this.plannedOrder["overlapped"] == true) {
      this.plannedOrder["overlapped"] = false;
      this.plannedOrder["ERRORS"] = " ";
      this.plannedOrder["ERROR_MESSAGE"] = " ";
      this.programador.overlapped_dict[this.plannedOrder["VBELN"]].forEach(
        (e) => {
          // console.log(e);
          e["overlapped"] = false;
          e["ERRORS"] = " ";
          e["ERROR_MESSAGE"] = " ";
          delete this.programador.overlapped_dict[e["VBELN"]];
        }
      );
      delete this.programador.overlapped_dict[this.plannedOrder["VBELN"]];
      // console.log('Overlapped Dict');
      // console.log(this.programador.overlapped_dict);
    }

    this.programador.clearHighLight();

    let left = e.source.element.nativeElement.getBoundingClientRect().left;
    let top = e.source.element.nativeElement.getBoundingClientRect().top;

    let right = e.source.element.nativeElement.getBoundingClientRect().right;
    let bottom = e.source.element.nativeElement.getBoundingClientRect().bottom;

    var match = document
      .elementsFromPoint(left, top)
      .filter(
        (element) =>
          this.hasClass(element, "minute-cell") &&
          this.hasClass(element, "shift-available")
      );
    // console.log('match 1!');
    // console.log(match);
    // si no encuentra match para el dia de hoy vemos si es para un shift de otro dia
    if (match.length == 0) {
      // console.log('match para otro dia... pero match');
      match = document
        .elementsFromPoint(left, top)
        .filter(
          (element) =>
            this.hasClass(element, "minute-cell") &&
            this.hasClass(element, "shift-available-not-today")
        );
    }
    // console.log('match: ');
    // console.log(match);

    // si match esta definido, significa que el elemento dropeado coincide con una celda "minute cell" valida y con turno disponible.
    if (match[0] != undefined) {
      // if there are 2 elements of type programmed-order (the one is dragging and the other that we drop on) means that there are an overlap in 2 programmed orders
      let checkBorderTop = this.checkIntersectionX(
        left,
        right,
        top,
        "programmed-order-borderdetector"
      );
      let checkBorderBottom = this.checkIntersectionX(
        left,
        right,
        bottom,
        "programmed-order-borderdetector"
      );

      // si se detecta que el elemento dropeado colisiona con otra orden programada, entonces es un pedido combinado.
      // PEDIDO COMBINADO
      if (checkBorderTop.check || checkBorderBottom.check) {
        // // console.log("COMBINADO!!!!!!!!!!!!")
        // Este es el div rectangular que representa el pedido sobre el cual se hizo el drop.
        let boxMatched = checkBorderTop.check
          ? checkBorderTop.match[1]
          : checkBorderBottom.match[1];
        let boxMatchedLeft = boxMatched.getBoundingClientRect().left;
        let boxMatchedTop = boxMatched.getBoundingClientRect().top;
        let cellOrderMatched = document
          .elementsFromPoint(boxMatchedLeft, boxMatchedTop)
          .filter((element) => this.hasClass(element, "minute-cell"))[0].id;

        let matchDate = cellOrderMatched.split(";")[0];
        let matchTRuck = cellOrderMatched.split(";")[1];
        let matchHour = cellOrderMatched.split(";")[2];
        let matchMinute = cellOrderMatched.split(";")[3];

        // let matchOrder = this.programador.programmedOrders.filter(e=> e.truckId==matchTRuck &&
        //  e.startHour==matchHour && e.startMinute==matchMinute && e.startDate==matchDate)[0]
        /*
        let matchOrder = this.programador.pedidos.filter(e=> e.VEHICLE==matchTRuck
          && this.utils.hhmmmmToHour(e.PRO_BEGTI)+""==matchHour
          && this.utils.adjustMinuteToCellResolution(this.utils.hhmmmmToMinute(e.PRO_BEGTI))+""==matchMinute
          && this.utils.yyyymmddToDate(e.PRO_BEGDA)==matchDate)[0]
          */
        let matchOrder = this.programador.pedidos.filter(
          (e) =>
            e.VEHICLE == matchTRuck &&
            this.utils.hhmmmmToHour(e.PRO_BEGTI) + "" == matchHour &&
            this.utils.adjustMinuteToCellResolution(
              this.utils.hhmmmmToMinute(e.PRO_BEGTI)
            ) +
              "" ==
              matchMinute &&
            this.utils.yyyymmddToDate(e.PRO_BEGDA) == matchDate
        )[0];

        // // console.log("matchOrder")
        // // console.log(matchOrder)
        // // console.log(matchOrder.CONFIRMADO)
        if (this.otherOrderConfirmed(matchOrder)) {
          // console.log('Aqui poder hacer drop sobre confirmado');

          // // console.log(this.programador.plantData.date)
          let today_temp = new Date();
          // // console.log(today_temp)
          let today_month = String(today_temp.getMonth() + 1).padStart(2, "0");
          let today_day = String(today_temp.getDate()).padStart(2, "0");
          let today_year = today_temp.getFullYear();
          let today_str = today_day + "-" + today_month + "-" + today_year;
          // // console.log(today_str)

          // // console.log(matchOrder['PRO_BEGTI'])
          let temp_hour = match[0].id.split(";")[2];
          let temp_min = match[0].id.split(";")[3];
          if (temp_hour.length == 1) {
            temp_hour = "0" + temp_hour;
          }
          if (temp_min.length == 1) {
            temp_min = temp_min + "0";
          }
          let pro_begti_temp = temp_hour + temp_min + "00";
          // // console.log(pro_begti_temp)

          let notSameBeginTime = false;
          if (pro_begti_temp != matchOrder["PRO_BEGTI"]) {
            notSameBeginTime = true;
            // console.log('VERIFICADO: Al montar pedidos, no quedan con mismo tiempo de inicio');
          }
          let isToday = false;
          if (this.programador.plantData.date == today_str) {
            isToday = true;
            // console.log('VERIFICADO: Se está programando para el mismo día');
          }

          // let isToday = true
          if (isToday && notSameBeginTime) {
            if (
              !this.utils.validateSameTypeOrder(this.plannedOrder, matchOrder)
            ) {
              this.programador.openAlert(
                "danger",
                MESSAGES.PROGRAMADOR.NO_COMBINE_DINAMO_WITH_NO_DINAMO
              );
              this.cancelDragProgrammedOrder();
              return false;
            }
            this.programador.openMontarPedidoModal(this, match, 0);
          } else {
            this.programador.openAlert(
              "warning",
              MESSAGES.PROGRAMADOR.NO_COMBINE_OVER_CONFIRMED
            );
            // this.programador.montarPedidoFlag = false
            this.cancelDragProgrammedOrder();
            return false;
          }
        } else {
          // Al combinar un pedido programado se valida si el pedido origen y el destino son del mismo tipo
          // sino lo son se mostrará una alerta.
          if (
            !this.utils.validateSameTypeOrder(this.plannedOrder, matchOrder)
          ) {
            this.programador.openAlert(
              "danger",
              MESSAGES.PROGRAMADOR.NO_COMBINE_DINAMO_WITH_NO_DINAMO
            );
            this.cancelDragProgrammedOrder();
            return false;
          } else {
            this.programador.openCombinarPedidoModal(this, matchOrder);
          }
        }
      } else {
        // si no hay colision de bordes con otros pedidos, entonces no estamos creando un pedido combinado
        // console.log('NO COMBINADO!');
        let newParentDom = match[0]; // the element where I want to add a new child, the planned order
        this.programador.activarClickOrden();
        let cellInfo = newParentDom.id.split(";");
        let truckId = cellInfo[1];
        let startDate = cellInfo[0];
        let startHour = parseInt(cellInfo[2]);
        let startMinute = parseInt(cellInfo[3]);
        // RACG: Validamos si el camión al que se movio el pedido es distinto, si es así limpiamos la distribución del pedido
        const currentVehicle = _.get(this.plannedOrder, "VEHICLE");
        if (truckId !== currentVehicle) {
          if (_.get(this.plannedOrder, "DINAMO") === "X") {
            let distrPedidos: any = this.sessionService.getDistrOrders();
            if (distrPedidos !== null) {
              _.remove(distrPedidos, (remover) => {
                return remover.VBELN === _.get(this.plannedOrder, "VBELN");
              });
              if (distrPedidos.length > 0) {
                this.sessionService.saveDistrOrders(distrPedidos);
              } else {
                this.sessionService.delDistrOrdersKey();
              }
            }
          }
        }
        this.clearWarningErrors();

        let newParentElement = this.programador.minuteCells.filter(
          (e) =>
            e.date == startDate &&
            e.truck == truckId &&
            e.hour == startHour &&
            e.minute == startMinute
        )[0];

        this.parentCell.removePlannedOrder();
        if (this.plannedOrder["AGRUPADOR"].trim() != "") {
          var combinados = this.programador.pedidos.filter(
            (e) => e["AGRUPADOR"] == this.plannedOrder["AGRUPADOR"]
          );
          combinados.forEach((pc) => {
            pc["VEHICLE"] = truckId;
            pc["PRO_BEGDA"] = this.utils.dateToyyyymmdd(startDate); // startDate; startDate viene DD-MM-YYYY. Pasar a YYYYMMDD
            pc["PRO_BEGTI"] = this.utils.hhmmTohhmmss(startHour, startMinute);

            let fechaHoraFinal = this.utils.sumDates(
              pc["PRO_BEGDA"],
              pc["PRO_BEGTI"],
              pc["DURACION_GRUPO"]
            );
            pc["PRO_ENDDA"] = fechaHoraFinal.split("-")[0];
            pc["PRO_ENDTI"] = fechaHoraFinal.split("-")[1];

            // this.plannedOrder["ESTADO"] estado entre ordenes progamadas se conserva si es que eso represneta. Confirmar
            newParentElement.attachPlannedOrder(pc);
          });

          // recalculo
          this.utils.calcularPedidoCombinado(combinados, this.programador);
        } else {
          // modify order details
          // estos campos vienen de la posicion en donde el usuario deja la celda. Se obtiene el camion, fecha y hora de inicio
          this.plannedOrder["VEHICLE"] = truckId;
          this.plannedOrder["PRO_BEGDA"] = this.utils.dateToyyyymmdd(startDate); // startDate; startDate viene DD-MM-YYYY. Pasar a YYYYMMDD
          // pro begtime considera los segundos como 0 siemrpe
          this.plannedOrder["PRO_BEGTI"] = this.utils.hhmmTohhmmss(
            startHour,
            startMinute
          );

          // Estos campos se deben recalcular en base a DURACION_GRUPO (que se entiende es la duracion de la barrita, total)
          // fecha y hora inicial + DURACION_GRUPO = fecha y hora final
          // let fechaHoraFinal= this.utils.sumDates(this.plannedOrder["PRO_BEGDA"],this.plannedOrder["PRO_BEGTI"],this.plannedOrder["DURACION_GRUPO"])
          let fechaHoraFinal = this.utils.sumDates(
            this.plannedOrder["PRO_BEGDA"],
            this.plannedOrder["PRO_BEGTI"],
            this.plannedOrder["DURACION_CALCULA"]
          );
          // // console.log("fechaHoraFinal")
          // // console.log(fechaHoraFinal)
          this.plannedOrder["PRO_ENDDA"] = fechaHoraFinal.split("-")[0];
          this.plannedOrder["PRO_ENDTI"] = fechaHoraFinal.split("-")[1];

          // this.plannedOrder["ESTADO"] estado entre ordenes progamadas se conserva si es que eso represneta. Confirmar
          var showAlert = true;
          showAlert = false;
          this.utils.validateOrderConditions(
            this.programador,
            newParentElement,
            this,
            showAlert
          );
          // // console.log("B attach")

          this.plannedOrder["CONFIR_START_DATE"] =
            this.plannedOrder["PRO_BEGDA"];
          this.plannedOrder["CONFIR_START_TIME"] =
            this.plannedOrder["PRO_BEGTI"];

          newParentElement.attachPlannedOrder(this.plannedOrder);
        }
      }
    } else {
      // no hay match
      // console.log('Otro Caso..');
      // if no element where to drop (a cell minute) we must put the order item back
      this.cancelDragProgrammedOrder();
    }
    this.programador.updateCountPedidos();
  }

  cancelDragProgrammedOrder(listaVbeln = []) {
    // // console.log("Cancel drag programmed ")
    this.parentCell.removePlannedOrder();
    this.programador.activarClickOrden();
    // // console.log("C attach")
    if (this.plannedOrder["AGRUPADOR"].trim() != "") {
      if (parseInt(this.plannedOrder["POS_AGRUPADOR"]) < 1) {
        this.plannedOrder["AGRUPADOR"] = "";
        this.plannedOrder["POS_AGRUPADOR"] = "0";
        this.parentCell.attachPlannedOrder(this.plannedOrder);
      } else {
        var agrupados = this.programador.pedidos.filter(
          (ped) => ped["AGRUPADOR"] == this.plannedOrder["AGRUPADOR"]
        );
        this.utils.calcularPedidoCombinado(agrupados, this.programador);
        // // console.log(agrupados)

        /*
          var pedidoCombinadoPrincipal = agrupados.filter(ped=>parseInt(ped["POS_AGRUPADOR"])==1 )[0]
          this.parentCell.attachPlannedOrder(pedidoCombinadoPrincipal);
          */
        // vamos a tener que iterar con los pos agrupador.
        // // console.log("C A L L I N G   A T T A C H    F R O M     C A N C E L")
        // // console.log(agrupados)

        agrupados.forEach((pag) => {
          this.parentCell.attachPlannedOrder(pag);
        });
      }
    } else {
      this.parentCell.attachPlannedOrder(this.plannedOrder);
    }

    // patch nos aseguramos que si la lista de vbelns es not empty, es pq se trata de pedidos combinados asi que
    if (listaVbeln.length != 0) {
      listaVbeln.forEach((vbeln) => {
        // console.log(vbeln);
        var pedidos = this.programador.pedidos.filter(
          (p) => p["VBELN"] == vbeln
        );
        // // console.log(pedidos[0]["VBELN"])
        // // console.log(pedidos[0]["AGRUPADOR"].trim())

        if (pedidos[0]["AGRUPADOR"].trim() == "") {
          // // console.log("set pos agrupador a 0 de vbeln " + pedidos[0]["VBELN"])
          pedidos[0]["POS_AGRUPADOR"] = "0";
        }
      });
    }
  }

  // establece el largo de la orden agendada en px, de acuerdo a su largo en tiempo minutos
  /*
  private sizeInPx(){
    this.orderLengthPx = this.orderLenghtMinutes*this.programador.pixelUnitTime/this.programador.unitTime;
  }
  */

  hasClass(element, className) {
    return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
  }

  confirm() {
    this.renderer.removeClass(
      this.element.nativeElement.children[0].children[0],
      "not-confirmed"
    );
  }

  canModify() {
    if (this.isFromBeforeToday()) {
      return false;
    }
    if (this.isConfirmed()) {
      return false;
    }
    /*if(this.isOverlapped())
      return false*/
    return true;
  }

  isOverlapped() {
    return this.overlapped;
  }

  isConfirmed() {
    // Si o No
    // if(this.plannedOrder.CONFIRMADO=="S")
    if (this.programadorFunctions.esPedidoConfirmado(this.plannedOrder)) {
      return true;
    }
    return false;
  }

  otherOrderConfirmed(otherOrder) {
    // Si o No
    // if(otherOrder.CONFIRMADO=="S")
    if (this.programadorFunctions.esPedidoConfirmado(otherOrder)) {
      return true;
    }
    return false;
  }

  desConfirm() {
    this.renderer.addClass(
      this.element.nativeElement.children[0].children[0],
      "not-confirmed"
    );
  }

  remove() {
    if (this.element.nativeElement.parentNode != null) {
      this.element.nativeElement.outerHTML = "";
    }
  }

  format(pedidosCombinados: any) {
    // // console.log("FORMAT! PC")
    // // console.log("dando formato a pedidos combinados")
    // // console.log(pedidosCombinados)
    var label = "";
    // ordenarlos por agrupador
    pedidosCombinados.forEach((e, index) => {
      // // console.log("Ped " + e["VBELN"] + ", " + e["KUNAG"])
      // Cambio para permitir desplegar si el pedido es Dinamo

      const esDinamo = e.DINAMO === ENUMS.IS_DINAMO ? " D" : "";

      if (index == pedidosCombinados.length - 1) {
        //Validamos pedido redistribuido
        if (e.ESTADO == "3" && esDinamo && e.DETALLE_PROGRAMADO < e.DETALLE)
          label += e.KUNAG * 1 + " R";
        else label += e.KUNAG * 1 + esDinamo;
      } else {
        label += e.KUNAG * 1 + esDinamo + "/";
      }
    });

    return label;
  }

  calculateAvailableSpace(pedidosCombinados: any) {
    var total = 0;
    var vehicle;
    pedidosCombinados.forEach((e) => {
      total = total + parseInt(e.DETALLE_PEDIDO[0].BMENG);
      vehicle = e.VEHICLE;
    });

    var totalString = total.toString().slice(0, -1);

    if (totalString.length == 3)
      totalString = totalString.slice(0, 1) + "." + totalString.slice(1);
    else totalString = totalString.slice(0, 2) + "." + totalString.slice(2);

    if (vehicle != " ") {
      var capacity = this.programador.trucks.filter(
        (e) => e.truckId == vehicle
      )[0].FULL_CAPACITY;

      var disponible = parseFloat(capacity) - parseFloat(totalString);

      var level =
        totalString +
        "m³ utilizados de " +
        capacity +
        ".00m³, \n" +
        disponible.toFixed(2) +
        "m³ disponibles";

      return level;
    } else {
      return "";
    }
  }

  hasComments() {
    if (
      this.plannedOrder.COMENTARIOS != undefined &&
      this.plannedOrder.COMENTARIOS.trim() != ""
    ) {
      return true;
    }
    return false;
  }

  hasWarnings() {
    if (
      this.plannedOrder.WARNINGS != undefined &&
      this.plannedOrder.WARNINGS.trim() == "S"
    ) {
      return true;
    }
    return false;
  }

  setWarningToS(message) {
    this.programador.pedidos.forEach((e) => {
      if (this.plannedOrder.VBELN == e.VBELN) {
        e["WARNINGS"] = "S";
        e["WARNING_MESSAGE"] = message;
      }
    });
  }

  hasErrors() {
    if (
      this.plannedOrder.ERRORS != undefined &&
      this.plannedOrder.ERRORS.trim() == "S"
    ) {
      return true;
    }
    return false;
  }

  setErrorsToS(message) {
    this.programador.pedidos.forEach((e) => {
      if (this.plannedOrder.VBELN == e.VBELN) {
        e["ERRORS"] = "S";
        e["ERROR_MESSAGE"] = message;
      }
    });
  }

  clearWarningErrors() {
    this.programador.pedidos.forEach((e) => {
      if (this.plannedOrder.VBELN == e.VBELN) {
        e["ERRORS"] = " ";
        e["WARNINGS"] = " ";
        e["WARNING_MESSAGE"] = " ";
        e["ERROR_MESSAGE"] = " ";
      }
    });
  }

  setWarningCombinadoToS(message) {
    // // console.log("setWarningCombinadoToS")
    this.programador.pedidos.forEach((e) => {
      if (this.pedidosCombinados != undefined) {
        this.pedidosCombinados.forEach((pc) => {
          if (pc.VBELN == e.VBELN) {
            e["WARNINGS"] = "S";
            e["WARNING_MESSAGE"] = message;
          }
        });
      }
    });
  }
  setErrorsCombinadoToS(message) {
    this.programador.pedidos.forEach((e) => {
      if (this.pedidosCombinados != undefined) {
        this.pedidosCombinados.forEach((pc) => {
          if (pc.VBELN == e.VBELN) {
            e["ERRORS"] = "S";
            e["ERROR_MESSAGE"] = message;
          }
        });
      }
    });
  }

  ngAfterViewChecked() {
    this.changePedidoHighlightColor();
    this.cdRef.detectChanges();
  }

  changePedidoHighlightColor() {
    switch (this.plannedOrder.HIGHLIGHTED) {
      case "S":
        this.highLightColor = "#FF0000"; // '#DCDCDC'
        this.fontSize = "12px";
        break;
      case "N":
        this.highLightColor = "#000000";
        this.fontSize = "13px";

        // this.truckBoxShadow = ' '
        // this.changeTruckBackgroundColor()
        break;
      default:
        return;
    }
  }
}
