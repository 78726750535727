export class IdleTimer {
  timeout: number;
  adviceTime: any;
  onTimeout: any;
  user: string;
  key: string;
  eventHandler: any;
  interval: NodeJS.Timer;
  timeoutTracker: any;
  expiredTime: any;
  hiddenPage: any;

  constructor({
    timeout: timeout,
    adviceTime: adviceTime,
    onTimeout: onTimeout,
    user: user,
    hiddenPage: hiddenPage,
  }) {
    this.timeout = timeout * 60;
    this.adviceTime = adviceTime;
    this.onTimeout = onTimeout;
    this.user = user;
    this.key = `_expiredTime_${this.user}`;
    this.hiddenPage = hiddenPage;

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      // const expiredTime = parseInt( localStorage.getItem(this.key), 10);
      if (this.expiredTime - Math.floor(Date.now() / 1000) === 60) {
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
        this.handleModalTimer()
          .then((resp) => {
            this.updateTimerOnClick(resp);
          })
          .catch((resp) => {
            this.cleanOnDismiss();
          });
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      this.expiredTime = Number(Math.floor(Date.now() / 1000) + this.timeout);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    if (this.key.includes(this.user)) {
      this.expiredTime = null;
    }

    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }

  updateTimerOnClick(param = true) {
    if (!param) {
      return;
    }
    this.updateExpiredTime();
    this.tracker();
  }

  cleanOnDismiss() {
    this.onTimeout();
    this.cleanUp();
  }

  handleModalTimer(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(() => {});
    }).then(() => {
      return this.adviceTime();
    });
  }
}
