/**
 * Objetos para transformar las claves de la respuesta de la consulta de llenado de tablas.
 * Cada objeto está nombrado de acuerdo a la pantalla administrativa que representa.
 * Nótese que simplemente son los encabezados al español
 */

export const Administradores = {
  sap_user: "Usuario SAP",
  user_roles: "Permisos",
  last_activity_timestamp: "Fecha última actividad",
  created_by: "Asignado por",
  creation_timestamp: "Fecha asignación",
  modified_by: "Modificado por",
  modification_timestamp: "Fecha modificación",
};

export const Parametros = {
  name: "Nombre",
  descriptive_name: "Nombre descriptivo",
  description: "Descripción",
  value: "Valor",
  units: "Unidades",
  range: "Rango",
  modified_by: "Modificado por",
  modification_timestamp: "Fecha modificación",
};

export const Restricciones = {
  tipo_restriccion: "Tipo de restricción",
  vigente: "Vigente",
  cliente_id_copec: "Código cliente",
  vehiculo: "Vehículo",
  horario_inicio: "Horario inicio",
  horario_termino: "Horario termino",
  tipo_restriccion_horaria: "Tipo restricción horaria",
  tipo_especificacion: "Tipo especificación",
  capacidad_min: "Capacidad mínima (m³)",
  capacidad_max: "Capacidad máxima (m³)",
  zona: "Zona",
  motobomba: "Motobomba",
  cuentalitros: "Cuentalitros",
  comentario: "Comentario",
  mes_inicio: "Mes inicio",
  mes_fin: "Mes fin",
  dia_inicio: "Día inicio",
  dia_fin: "Día fin",
  created_by: "Creado por",
  creation_timestamp: "Fecha de creación",
  modified_by: "Modificado por",
  modification_timestamp: "Fecha modificación",
  id_planta: "ID planta",
  planta: "Planta",
  constraint_id: "ID restricción",
};

export const Imputaciones = {
  origin: "Origen",
  destination: "Destino",
  outward_travel_time: "Tiempo viaje ida (minutos)",
  created_by: "Creado por",
  creation_timestamp: "Fecha creación",
  modified_by: "Modificado por",
  modification_timestamp: "Fecha modificación ",
};

export const CONSTRAINTS = {
  capacidad: [
    "Tipo de restricción",
    "Horario inicio",
    "Horario termino",
    "Tipo restricción horaria",
    "Vehículo",
    "Zona",
    "Motobomba",
    "Cuentalitros",
  ],
  horario: [
    "Tipo de restricción",
    "Tipo especificación",
    "Capacidad mínima (m³)",
    "Capacidad máxima (m³)",
    "Vehículo",
    "Motobomba",
    "Cuentalitros",
  ],
  flotas: [
    "Tipo de restricción",
    "Horario inicio",
    "Horario termino",
    "Tipo restricción horaria",
    "Mes inicio",
    "Mes fin",
    "Día inicio",
    "Día fin",
    "Tipo especificación",
    "Capacidad mínima (m³)",
    "Capacidad máxima (m³)",
    "Zona",
    "Motobomba",
    "Cuentalitros",
  ],
  "moto-cuenta": [
    "Tipo de restricción",
    "Horario inicio",
    "Horario termino",
    "Tipo restricción horaria",
    "Capacidad mínima (m³)",
    "Capacidad máxima (m³)",
    "Vehículo",
    "Tipo especificación",
    "Vehículo",
    "Zona",
    "Mes inicio",
    "Mes fin",
    "Día inicio",
    "Día fin",
  ],
};

export const Propuestas = {
  plant: "Planta",
  plant_name: "Nombre Planta",
  proposal_date: "Fecha de la Propuesta",
  execution_date: "Fecha de Ejecución",
  solution_type: "Tipo de Solución",
  programmed_orders: "Pedidos Programados",
  combined_orders: "Pedidos Combinados",
  running_mode: "Modo de Ejecución",
};

export const Sesiones = {
  plant: "Planta",
  session_date: "Fecha",
  sap_user: "Usuario",
  creation_timestamp: "Fecha Inicio",
  EXPIRATION_TIMESTAMP: "Fecha Expiracion",
};
