import { Component, OnInit } from "@angular/core";
import { ProgramadorComponent } from "../../pages/programador/programador.component";

declare var $: any;

@Component({
  selector: "app-modal-clean-button",
  templateUrl: "./modal-clean-button.component.html",
  styleUrls: ["./modal-clean-button.component.css"],
})
export class ModalCleanButtonComponent implements OnInit {
  programador: ProgramadorComponent;

  constructor() {}

  ngOnInit() {}

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
  }

  show() {
    $("#clean-button-modal").modal("show");
  }

  cleanOrders() {
    //this.programador.cleanOrders();
    // console.log("Ok para clean orders")
    this.programador.clean();
  }
}
