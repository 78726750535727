import { Pipe, PipeTransform } from "@angular/core";
import { ProgramadorComponent } from "../../pages/programador/programador.component";
import _ from "lodash";

@Pipe({
  name: "filter",
  pure: false,
})
export class FilterPipe implements PipeTransform {
  constructor(private programador: ProgramadorComponent) {}

  transform(values: any[], filter: string): any[] {
    if (!values || !values.length) {
      return [];
    }
    if (!filter) {
      return values;
    }

    // Filter items array, items which match will return true
    switch (this.programador.colname) {
      case "VBELN":
        return values.filter(
          (v) =>
            v.VBELN.toString().toLowerCase().indexOf(filter.toLowerCase()) !==
            -1
        );
        break;
      case "NAME1_KUNAG_CONCAT":
        return values.filter(
          (v) =>
            v.NAME1_KUNAG_CONCAT.toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "DETALLE":
        return values.filter(
          (v) =>
            v.DETALLE.split("=")[0]
              .toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "DURACION_TEXT":
        return values.filter(
          (v) =>
            v.DURACION_TEXT.toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "BEZEI_DELCO":
        return values.filter(
          (v) =>
            v.BEZEI_DELCO.toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "AUART":
        return values.filter(
          (v) =>
            v.AUART.toString().toLowerCase().indexOf(filter.toLowerCase()) !==
            -1
        );
        break;
      case "BSARK":
        return values.filter(
          (v) =>
            v.BSARK.toString().toLowerCase().indexOf(filter.toLowerCase()) !==
            -1
        );
        break;
      case "GROUPTEXT":
        return values.filter(
          (v) =>
            v.GROUPTEXT.toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "VTEXT":
        return values.filter(
          (v) =>
            v.VTEXT.toString().toLowerCase().indexOf(filter.toLowerCase()) !==
            -1
        );
        break;
      case "CUENTA_LITROS":
        return values.filter(
          (v) =>
            v.CUENTA_LITROS.toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "CMGST_TEXT":
        return values.filter(
          (v) =>
            v.CMGST_TEXT.toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "COMENTARIOS":
        return values.filter(
          (v) =>
            v.COMENTARIOS.toString()
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1
        );
        break;
      case "DINAMO":
        if (_.startsWith(filter.toLocaleLowerCase(), "s")) {
          return values.filter((v) => v.DINAMO === "X");
        } else if (_.startsWith(filter.toLocaleLowerCase(), "n")) {
          return values.filter((v) => v.DINAMO === " ");
        }
        break;
      default:
        return;
    }
  }
}
