import { Component, OnInit, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SharedDataService } from "../../global/services/shared-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { LoginResponse } from "./models/login-response.model";
import { ApiLoginService } from "./services/api-login.service";
import { LoginRequest } from "./models/login-request.model";
import { MESSAGES } from "../../global/constants/messages.const";
import { UtilsService } from "src/app/global/utils/utils.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
@Injectable({
  providedIn: "root",
})
export class LoginComponent implements OnInit {
  userName = "";
  token = "";
  errorLogin: boolean;
  userPass: string;
  errorMsg: string;
  whitelist: any;
  isChrome: boolean;
  version = `${environment.env}-${environment.gitHash}`;

  constructor(
    private sharedService: SharedDataService,
    private loginService: ApiLoginService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.isChrome = this.utilsService.checkNavigator();
    this.errorLogin = false;
    this.errorMsg = "";
    this.whitelist = [];
  }

  containObject(obj, objArr) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < objArr.length; i++) {
      if (objArr[i].code === obj.code) {
        return true;
      }
    }
    return false;
  }

  login() {
    if (this.userName.trim() === "" || this.userPass.trim() === "") {
      this.errorLogin = true;
      this.errorMsg = MESSAGES.LOGIN.EMPTY_FIELDS;
      return;
    }

    const loginData: LoginRequest = {
      userName: this.userName,
      userPass: this.userPass,
    };

    this.SpinnerService.show();
    const cbSuccess = (data: LoginResponse) => {
      if (data.retCode === 401) {
        this.errorMsg = data.message;
        this.errorLogin = true;
        this.SpinnerService.hide();
      } else if (data.retCode === 200) {
        this.errorLogin = false;
        this.errorMsg = "";

        let plantasADesplegar = data.data;
        if (data.enablePlantRules) {
          this.whitelist = data.whitelist;
          plantasADesplegar = data.data.filter((e) =>
            this.containObject({ name: e.Name1, code: e.Werks }, this.whitelist)
          );
        }

        // shared then
        const plantaCode = [];
        // data.data.forEach(e => {
        plantasADesplegar.forEach((e) => {
          const res = e.Autoriza.filter(
            (a) => a.CbCode === "ZPR_PRG" && a.CbValue === "1"
          );

          if (res.length) {
            plantaCode.push({ NAME1: e.Name1, WERKS: e.Werks });
          }
        });
        // // console.log(data)
        this.sharedService.setPlantList(plantaCode);
        this.sharedService.setValidatedLogin(
          this.userName.toUpperCase(),
          this.userPass,
          plantaCode,
          data.bearer,
          data.admin_roles,
          data.is_admin
        );
        this.SpinnerService.hide();
        this.router.navigate(["/selector"]);
      } else {
        this.errorMsg =
          "Ha ocurrido un error inesperado (code: " +
          data.retCode +
          ", message: " +
          data.message +
          "). Por favor intente ingresar nuevamente. " +
          "Si el problema persiste, comuníquese con su administrador.";
        this.errorLogin = true;
        this.SpinnerService.hide();
      }
      this.SpinnerService.hide();
    };

    const cbError = (err) => {
      this.SpinnerService.hide();
      alert(err);
    };

    this.loginService.userLogin(loginData).subscribe(cbSuccess, cbError);
  }
}
