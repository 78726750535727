import { Component, OnInit } from "@angular/core";
import { ApiProgramadorService } from "../../pages/programador/services/api-programador.service";
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;

@Component({
  selector: "app-modal-large-order-info",
  templateUrl: "./modal-large-order-info.component.html",
  styleUrls: ["./modal-large-order-info.component.css"],
})
export class ModalLargeOrderInfoComponent implements OnInit {
  plantName: number;
  responseData: any;
  message: string;

  constructor(
    public api: ApiProgramadorService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    window.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.code == "Escape") {
        $("#modal-large-order-info").modal("hide");
      }
    });
  }

  show(data) {
    this.message = " ";
    this.responseData = null;
    this.longTrips(data);
    this.plantName = data["plantData"]["name"];
  }

  longTrips(data) {
    this.spinner.show();
    var date = data["plantData"]["date"].split("-");

    this.api
      .longTrips({
        user: data["sessionAdmin"],
        password: data["sharedService"]["login"]["user_pass"],
        plant: data["plantData"]["code"],
        date: date[2] + date[1] + date[0],
      })
      .subscribe((resp) => {
        if (resp["code"] === 200) {
          if (resp["data"].length === 0) {
            this.message = "No hay pedidos largos por programar.";
          } else {
            this.message = " ";
            this.responseData = resp["data"];
          }
        } else if (resp["message"] != undefined) {
          this.message = resp["message"];
        } else {
          this.message = resp["errorMessage"];
        }
        this.spinner.hide();
        $("#modal-large-order-info").modal("show");
      });
  }
}
