import { Component, OnInit, ElementRef, Input } from "@angular/core";
// import { ProgramadorComponent } from '../programador/programador.component';

@Component({
  selector: "pivot-element",
  templateUrl: "./pivot-element.component.html",
  styleUrls: ["./pivot-element.component.css"],
})
export class PivotElementComponent implements OnInit {
  // elemento que sirve para poner como referencia y sacar coordenadas por ejemplo. Usado en funcion de highlight
  constructor(public element: ElementRef) {} // , private programador: ProgramadorComponent) { }

  @Input() coord: string;

  ngOnInit() {}

  update(programador, coord) {
    var left = this.element.nativeElement.getBoundingClientRect().left;
    var right = this.element.nativeElement.getBoundingClientRect().right;
    var top = this.element.nativeElement.getBoundingClientRect().top;
    var bottom = this.element.nativeElement.getBoundingClientRect().bottom;

    programador.setPivotReference(coord, left, right, top, bottom);
  }
}
