import { Component, OnInit, Injectable, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { SharedDataService } from "../../global/services/shared-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SessionService } from "../../global/services/session.service";
import { MESSAGES } from "../../global/constants/messages.const";
import { ApiSelectorPlantaService } from "./services/api-selector-planta.service";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-selector-planta",
  templateUrl: "./selector-planta.component.html",
  styleUrls: ["./selector-planta.component.css"],
})
export class SelectorPlantaComponent implements OnInit, AfterViewInit {
  user: string;
  plants: any;
  plantDate: any;
  plantCode = "-1";
  errorNoPlant: boolean;
  textNoPlant = MESSAGES.PLANT_SELECTOR.PLANT_TEXT_ERROR;
  errorNoDate: boolean;
  textNoDate = MESSAGES.PLANT_SELECTOR.DATE_PLAN_ERROR;
  plantName: string;
  idPlantas: any;
  errorMsg: string;
  isAdmin: boolean;
  readOnly: boolean;

  constructor(
    private sharedService: SharedDataService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private sessionService: SessionService,
    private apiService: ApiSelectorPlantaService
  ) {}

  ngOnInit() {
    this.resetErrorMsg();
    this.checkLogin();
    this.loadPlants();
    this.errorMsg = "";
    this.isAdmin = this.sharedService.getLoginInfo().isAdmin;
    this.readOnly = this.sharedService.getLoginInfo().readerOnly;
  }

  ngAfterViewInit() {
    this.errorMsg = this.sharedService.getSelectorMessage();
    // limpiamos
    this.sharedService.setSelectorMessage("");
  }

  checkLogin() {
    if (this.sharedService.getLoginInfo() === undefined) {
      this.router.navigate(["/"]);
    } else {
      // For testing purpose
      this.user = this.sharedService.getLoginInfo().user_name;
    }
  }

  errorAccess() {
    if (this.errorMsg === undefined) {
      return false;
    }
    if (this.errorMsg.trim() !== "") {
      return true;
    }
    return false;
  }

  /*
  //This method is use to connect with DynamoDB in order to retrieve data from "AccesoUsuarioPlantas" table
  loadPlantsByUser() {
    let user_name = this.sharedService.getLoginInfo().user_name;
    let responseAccesoUsuarioPlanta = this.api.fetchAccesoUsuarioPlantas(user_name,this.sharedService.getLoginInfo().bearer);
    responseAccesoUsuarioPlanta.subscribe( (data:{idPlantasUser:any}) => {
      this.idPlantas = data.idPlantasUser;
      let response = this.api.fetchPlants(this.idPlantas, this.sharedService.getLoginInfo().bearer);
      response.subscribe( (data:{plantas:any}) => {
        this.plants = data.plantas;
        this.sharedService.setPlantList(this.plants);
        this.SpinnerService.hide();
      })

    })
  }
  */
  // This method is use to connect with DynamoDB in order to retrieve data from "plantas" table
  loadPlants() {
    // // console.log(sessionStorage.getItem("plantList"))
    if (this.sharedService.getLoginInfo() !== undefined) {
      // this.loadPlantsByUser()
      // // console.log(this.sharedService.getLoginInfo())
      var loginInfo = this.sharedService.getLoginInfo();
      if (loginInfo["plantCode"] != undefined) {
        this.plants = loginInfo["plantCode"];
      } else {
        this.plants = JSON.parse(sessionStorage.getItem("plantList"));
      }

      this.sharedService.setPlantList(this.plants);
      this.SpinnerService.hide();
      /*
      this.plants = data.plantas;
      this.sharedService.setPlantList(this.plants);
      this.SpinnerService.hide();
      */
    }
  }

  async ingresarPlanta() {
    this.SpinnerService.show();
    this.errorNoPlant = this.plantCode == "-1" ? true : false;
    this.errorNoDate = this.plantDate == undefined ? true : false;

    if (this.errorNoDate || this.errorNoPlant) {
      this.SpinnerService.hide();
      return false;
    }

    this.plantName = this.plants.filter((e) => e.WERKS == this.plantCode)[0][
      "NAME1"
    ];
    this.sharedService.setPlantInfo(
      this.plantName,
      this.plantCode,
      this.plantDate
    );

    this.sessionService.saveLoginData(
      this.sharedService.getPlantInfo(),
      this.sharedService.getLoginInfo(),
      this.sharedService.getPlantList()
    );

    const dtm_response: any = await this.apiService
      .getDistrTruckMotives()
      .toPromise();

    // console.log(dtm_response);
    if (dtm_response.hasOwnProperty("motivos")) {
      this.sessionService.saveDistrMotives(dtm_response);
    }

    const om_response: any = await this.apiService
      .getOrdersMotives()
      .toPromise();

    // console.log(om_response);
    if (om_response.hasOwnProperty("ET_MOTIVOS")) {
      this.sessionService.saveOrdersMotives(om_response);
      this.router.navigate(["/programador"]);
    }
  }

  resetErrorMsg() {
    this.errorNoPlant = false;
    this.errorNoDate = false;
  }

  logout() {
    this.sharedService.setValidatedLogin(null, null, null, null, null, null);
    sessionStorage.clear();
    this.router.navigate(["/login"]);
  }

  ingresarAdmin() {
    this.router.navigate(["/administrador"]);

    this.sessionService.saveAdminLogin(this.sharedService.getLoginInfo());
  }
}
