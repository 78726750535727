import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { OrdenProgramadaComponent } from "../orden-programada/orden-programada.component";
import { ProgramadorComponent } from "../../programador.component";
import { MaxLengthValidator } from "@angular/forms";
import { UtilsService } from "src/app/global/utils/utils.service";
import { ThrowStmt } from "@angular/compiler";
import { CellComponent } from "../cell/cell.component";
/*
@Injectable({
  providedIn: 'root',
})
*/
// @Injectable()

@Component({
  selector: "minute-cell",
  templateUrl: "./minute-cell.component.html",
  styleUrls: ["./minute-cell.component.css"],
})
export class MinuteCellComponent implements OnInit {
  @ViewChild("planificacion", { static: false, read: ViewContainerRef })
  container;
  @ViewChild("div", { static: false }) childDiv; // ojo

  @Input() truck: string;
  @Input() hour: string;
  @Input() minute: string;
  @Input() date: string;

  componentRef: any;
  available: boolean;
  cellBgColor: string;
  startDate: any;
  endDate: any;
  endHour: any;
  endMinute: any;
  startHour: any;
  startMinute: any;
  isMod12: boolean; // indicates if the cell match with a 12 hours period within a shift.
  parentCell: CellComponent;

  constructor(
    private utils: UtilsService,
    private renderer: Renderer2,
    public element: ElementRef,
    public programador: ProgramadorComponent,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.available = false;
  }

  ngOnInit() {
    this.programador.pushMinuteCell(this);
    this.isMod12 = false;
  }

  destroy() {
    // this.container.instance
    this.componentRef.instance.remove();
  }

  ngAfterViewInit() {
    // once all is rendered, each cell checks if should be available for its truck shift.
    this.checkCellTruckAvailability();
  }

  // define las cells que estan disponibles para los turnos de camiones.
  checkCellTruckAvailability() {
    // Parche para los test unitarios
    if (this.programador.trucks == undefined) {
      this.programador.trucks = [];
    }
    let cellTruck = this.programador.trucks.filter(
      (t) => t.truckId == this.truck
    );
    // this.cleanCells(cellTruck);

    var cellTime = this.utils.dateFromMMDDYYYY(this.date);
    cellTime.setHours(cellTime.getHours() + parseInt(this.hour, 10));
    cellTime.setMinutes(cellTime.getMinutes() + parseInt(this.minute, 10));

    // Parche para los test unitarios
    if (cellTruck[0] == undefined) {
      cellTruck[0] = [0];
    }
    // Parche para los test unitarios
    if (cellTruck[0].shifts == undefined) {
      cellTruck[0].shifts = [];
    }
    cellTruck[0].shifts.forEach((shift, index) => {
      // // console.log("*******")
      // // console.log(this.truck)
      // // console.log(shift)
      // // console.log(index)
      // // console.log("=======")

      var shiftInitTime = this.utils.dateFromMMDDYYYY(shift.dayInit);
      shiftInitTime.setHours(shiftInitTime.getHours() + shift.hourInit);
      shiftInitTime.setMinutes(shiftInitTime.getMinutes() + shift.minutesInit);

      var shiftEndTime = this.utils.dateFromMMDDYYYY(shift.dayEnd);
      shiftEndTime.setHours(shiftEndTime.getHours() + shift.hourEnd);
      shiftEndTime.setMinutes(shiftEndTime.getMinutes() + shift.minutesEnd);

      if (
        cellTime.getTime() >= shiftInitTime.getTime() &&
        cellTime.getTime() < shiftEndTime.getTime()
      ) {
        if (shift.active) {
          // a la cell le decimos donde parte el turno y donde termina
          this.startDate = shift.dayInit;
          this.startHour = shift.hourInit;
          this.startMinute = shift.minutesInit;

          this.endDate = shift.dayEnd;
          this.endHour = shift.hourEnd;
          this.endMinute = shift.minutesEnd;
          if (cellTime.getTime() == shiftInitTime.getTime()) {
            this.setAvailable({ first: true });
          } else {
            this.setAvailable({});
          }
        }
      }
    });
  }

  /*
  cleanCells(cells:any){
    //cells.foreach(c=>c.setNoAvailable());
  }
  */
  // for mouseover on minute cell, lets call the highlight for all the cells truck, all minutes and hour
  mouseover(evt) {
    let left = evt.target.getBoundingClientRect().x;
    let top = evt.target.getBoundingClientRect().y;
    // // console.log("left: " + left)
    // // console.log("top: " + top)
  }

  attachPlannedOrder(orderPlannedObject) {
    // // console.log("attachPlannedOrder!")

    orderPlannedObject.DETALLE_PEDIDO.forEach(function (value, i) {
      if (value.BMENG == 0) {
        orderPlannedObject.DETALLE_PEDIDO[i].BMENG = "0";
      }
    });
    if (this.programador.gridReadyToClick) {
      if (this.componentRef == undefined) {
        const componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            OrdenProgramadaComponent
          );
        this.componentRef = this.container.createComponent(componentFactory); // la programacion asociada a la celda
      }
      this.componentRef.instance.setOrderObject(orderPlannedObject);
      this.componentRef.instance.setParentCell(this);
      // si agrupador es vacio, es un pedido unico y cerramos la celda
      if (orderPlannedObject["AGRUPADOR"].trim() == "") {
        this.programador.gridReadyToClick = false;
      }

      // poner el component en zindex 1 para que quede sobre la cell minute y pueda arrastrrarse

      // agregamos al plannedObject a una estructura en programador para que podamos acceder a estte mas rapidamente
      this.programador.setPlannedObject(this.componentRef.instance);
    }
  }

  getProgrammedOrder() {
    if (this.componentRef != null) {
      return this.componentRef.instance;
    }
    return false;
  }

  cellInfo(evt) {
    /*
    // console.log("truck :" + this.truck);
    // console.log("hour :" + this.hour);
    // console.log("minute :" + this.minute);
    // console.log("date :" + this.date);
    let left = evt.target.getBoundingClientRect().x;
    let top = evt.target.getBoundingClientRect().y;
    // console.log("left: " + left)
    // console.log("top: " + top)
    */
    if (this.componentRef != undefined) {
      // console.log(this.componentRef.instance);
    }
  }

  setAvailable(opt) {
    // debemos distinguir entre las cells disponibles del dia de hoy y las del dia de otro dia.
    var availableClass = "shift-available";
    // celltime
    var cellYear = parseInt(this.date.split("-")[2]);
    var cellMonth = parseInt(this.date.split("-")[1]);
    var cellDay = parseInt(this.date.split("-")[0]);
    var cellHour = parseInt(this.hour);
    var cellMinute = parseInt(this.minute);
    var cellDate = new Date(
      cellYear,
      cellMonth * 1 - 1,
      cellDay,
      cellHour,
      cellMinute
    ).getTime();
    // start shift time
    var startShiftYear = this.startDate.split("-")[2];
    var startShiftMonth = this.startDate.split("-")[1];
    var startShiftDay = this.startDate.split("-")[0];
    var startDate = new Date(
      startShiftYear,
      startShiftMonth * 1 - 1,
      startShiftDay
    ); // cuando parte el turno solo fecha
    var startTimesamp = new Date(
      startShiftYear,
      startShiftMonth * 1 - 1,
      startShiftDay,
      this.startHour,
      this.startMinute
    ).getTime(); // cuando parte el turno
    // plant time
    let plantYear = parseInt(this.programador.plantData.date.split("-")[2]);
    let plantMonth = parseInt(this.programador.plantData.date.split("-")[1]);
    let plantDay = parseInt(this.programador.plantData.date.split("-")[0]);
    var plantDate = new Date(plantYear, plantMonth * 1 - 1, plantDay);

    // vemos si el turno camion se encuentra en la lista de camiones vehicle_down. Esto indica que el turno est'a deshabilitado.
    var isShiftDown = false;
    this.programador.vehicleDown.forEach((vd) => {
      var c1 = vd["TurnoFechaDesde"] == this.utils.swapDate(this.startDate);
      var c2 = vd["TurnoFechaHasta"] == this.utils.swapDate(this.endDate);
      var c3 =
        vd["TurnoHoraDesde"] ==
        this.utils.getHHMMSS(this.startHour, this.startMinute, 0);
      var c4 =
        vd["TurnoHoraHasta"] ==
        this.utils.getHHMMSS(this.endHour, this.endMinute, 59);
      var c5 = vd["Vehicle"] == this.truck;
      if (c1 && c2 && c3 && c4 && c5) {
        isShiftDown = true;
      }
    });

    // if(this.programador.vehicleDown.includes(this.truck)){
    // si se cumplen todas es porque el turno coincide con un turno que debiera estar abajo, por lo que en este punto retorno
    if (isShiftDown) {
      return;
    }

    if (plantDate > startDate) {
      availableClass = "shift-available-not-today";
    }

    var diffHours = Math.abs(cellDate - startTimesamp) / 36e5;
    var mod12 = diffHours % 12;
    // solo queremos mostrar los cambios de turnos (cada 12 horas). No contamos el tiempo inicial, por eso diffHours!=0
    if (mod12 == 0 && diffHours != 0) {
      this.renderer.addClass(
        this.element.nativeElement.children[0],
        "shift-separator"
      );
      this.isMod12 = true;
    }

    this.available = true;
    this.renderer.addClass(
      this.element.nativeElement.children[0],
      availableClass
    );
    if (opt != undefined) {
      if (opt.first) {
        this.renderer.addClass(
          this.element.nativeElement.children[0],
          "shift-first"
        );
      }
    }

    // TODO REVISAR POR QUE LAS DISPONIBILIDADES END E INICIO SE MUESTRAN MAL
    // Hay que hacer que se muestre disponibilidad de los camiones.
  }
  setNoAvailable() {
    this.available = false;
    this.renderer.removeClass(
      this.element.nativeElement.children[0],
      "shift-available"
    );
    this.renderer.removeClass(
      this.element.nativeElement.children[0],
      "shift-first"
    );

    // remove separator
    this.renderer.removeClass(
      this.element.nativeElement.children[0],
      "shift-separator"
    );
    this.isMod12 = false;
  }
  getAvaialble() {
    return this.available;
  }

  // hace rror..
  removeProgrammedOrder() {
    // this.container.de
    // // console.log("******************** REMOVE PLANNED ORDER!! *******************")
    // this.componentRef.instance.element.nativeElement.remove();
    this.removePlannedOrder();
  }

  removePlannedOrder() {
    // this.componentRef=undefined
    // this.componentRef.instance.remove()
    // this.componentRef.instance = undefined
    this.componentRef = undefined;
    this.container.clear();
    // // console.log("CONTAINER")
    // // console.log(this.container)
  }
}
