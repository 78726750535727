import { Injectable } from "@angular/core";
import { LoginData, PlantData, PlantList } from "../models/login-data.model";
import { ListadoMotivos } from "../../pages/programador/interfaces/motivos-distribucion";
import { ListadoMotivosDesc } from "../../pages/programador/interfaces/motivos-desconfirmacion";

@Injectable({
  providedIn: "root",
})

// Clase/servicio para compartir data entre distintas componentes del programador.
export class SharedDataService {
  login: LoginData;

  plant: PlantData;

  distrMotives: ListadoMotivos;

  ordersMotives: ListadoMotivosDesc;

  plantsList: any[];
  selectorMessage: any;

  adminLogin;

  constructor() {}

  setValidatedLogin(
    userName: string,
    userPass: string,
    plantCode: any,
    bearer: string,
    roles: string[],
    isAdmin: boolean
  ) {
    // Pasamos el pwd del user para que pueda hacer los llamados a sap.
    // La pass sera enviada como parte del request para que pueda utilizarse con sap.
    // Si bien es cierto esto va por una VPN, se aconseja utilizar un certificado SSL para la encriptacion de la info.
    this.login = {
      user_name: userName,
      user_pass: userPass,
      plantCode,
      bearer,
      roles: isAdmin ? roles : ["lectura"],
      isAdmin,
      readerOnly: isAdmin ? false : true,
    };
  }

  getLoginInfo() {
    return this.login;
  }

  // Info asociada a planta
  setPlantInfo(
    name: string,
    code: string,
    date: { year: number; month: number; day: number }
  ) {
    const day = date.day * 1 < 10 ? "0" + date.day * 1 : date.day;
    const month = date.month * 1 < 10 ? "0" + date.month * 1 : date.month;
    const dateDDMMYYY = day + "-" + month + "-" + date.year;

    this.plant = { name, code, date: dateDDMMYYY };
  }

  getPlantInfo() {
    return this.plant;
  }

  setPlantList(plantList: PlantList[]) {
    this.plantsList = plantList;
  }

  getPlantList() {
    return this.plantsList;
  }

  setDistrMotives(distrMotives: ListadoMotivos) {
    this.distrMotives = distrMotives;
  }

  getDistrMotives() {
    return this.distrMotives;
  }

  setOrdersMotives(ordersMotives: ListadoMotivosDesc) {
    this.ordersMotives = ordersMotives;
  }

  getOrdersMotives() {
    return this.ordersMotives;
  }

  setSelectorMessage(msg) {
    this.selectorMessage = msg;
  }

  getSelectorMessage() {
    return this.selectorMessage;
  }
}
