import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { ListadoDistribucion } from "src/app/pages/programador/interfaces/listado-distribucion";
import { ListadoMotivos } from "../../pages/programador/interfaces/motivos-distribucion";
import { ListadoMotivosDesc } from "../../pages/programador/interfaces/motivos-desconfirmacion";
import { KEYS } from "../keys/keys";
import { LoginData, PlantData, PlantList } from "../models/login-data.model";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor() {}

  /**
   * Registra la información relacionada al inicio de sesión en el
   * Session Storage del navegador de forma encriptada para impedir su manipulación
   * directa.
   * @param plantInfo Información de la planta seleccionada
   * @param loginData Información del referente al inicio de sesión
   * @param plantList Información con el listado de plantas
   */
  saveLoginData(plantInfo: any, loginData: any, plantList): void {
    sessionStorage.clear();
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.PLANT_INFO,
      this.encrypt(plantInfo)
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.LOGIN_DATA,
      this.encrypt(loginData)
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.PLANT_LIST,
      this.encrypt(plantList)
    );
  }

  /**
   * Registra únicamente la información de login. Su uso esta contemplado para el componente Administrador.
   * @param login
   */
  saveAdminLogin(login: any) {
    sessionStorage.clear();
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.LOGIN_DATA,
      this.encrypt(login)
    );
  }

  /**
   * Encripta y guarda en la sessionStorage la distribución de los materiales para los pedidos Dínamo
   * cuyo camión asignado es menor a la totalidad del pedido.
   * @param distrOrders Listado con la distribución de los pedidos
   */
  saveDistrOrders(distrOrders: ListadoDistribucion): void {
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.DISTR_ORDERS,
      this.encrypt(distrOrders)
    );
  }

  /**
   * Retorna el listado de distribución de pedidos Dinamo desencriptada
   * @returns Listado de distribución desencriptada
   */
  getDistrOrders(): ListadoDistribucion {
    if (
      sessionStorage.getItem(KEYS.SESSION_STORAGE_KEYS.DISTR_ORDERS) !== null
    ) {
      return this.decrypt(KEYS.SESSION_STORAGE_KEYS.DISTR_ORDERS);
    } else {
      return null;
    }
  }

  /**
   * Elimina la key relacionada a la lista de distribución de la session storage.
   */
  delDistrOrdersKey(): void {
    sessionStorage.removeItem(KEYS.SESSION_STORAGE_KEYS.DISTR_ORDERS);
  }

  /**
   * Encripta y guarda en sessionStorage el listado de motivos para la redistribución de pedidos
   * Dínamo en que el pedido sea mayor a la capacidad del camión
   * @param motives Listado con los motivos de distribución
   */
  saveDistrMotives(motives: ListadoMotivos): void {
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.MOTIVES,
      this.encrypt(motives)
    );
  }

  /**
   * Retorna el listado de motivos descriptado desde el sessionStorage
   * @returns Listado de motivos
   */
  getDistrMotives(): ListadoMotivos {
    return this.decrypt(KEYS.SESSION_STORAGE_KEYS.MOTIVES);
  }

  /**
   * Encripta y guarda en sessionStorage el listado de motivos para desconfirmar pedidos
   * o dar de baja a turnos de camiones.
   * @param motivesDesc Listado con los motivos para desconfirmar pedidos
   * o dar de baja a turnos de camiones.
   */
  saveOrdersMotives(motivesDesc: ListadoMotivosDesc): void {
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.ORDERS_MOTIVES,
      this.encrypt(motivesDesc)
    );
  }

  /**
   * Retorna el listado de motivos descriptado desde el sessionStorage
   * @returns Listado de motivos
   */
  getOrdersMotives(): ListadoMotivosDesc {
    return this.decrypt(KEYS.SESSION_STORAGE_KEYS.ORDERS_MOTIVES);
  }

  /**
   * Retorna la información del login
   * @returns Objeto con la información del login
   */
  getLoginData(): LoginData {
    return this.decrypt(KEYS.SESSION_STORAGE_KEYS.LOGIN_DATA);
  }

  /**
   * Retorna la planta seleccionada por el usuario
   * @returns Objeto de información de planta
   */
  getPlantInfo(): PlantData {
    return this.decrypt(KEYS.SESSION_STORAGE_KEYS.PLANT_INFO);
  }

  /**
   * Retorna el listado de plantas
   * @returns Lista de plantas
   */
  getPlantList(): PlantList[] {
    return this.decrypt(KEYS.SESSION_STORAGE_KEYS.PLANT_LIST);
  }

  /**
   * Encripta un objeto pasado por parámetro
   * @param data Información a encriptar
   * @returns Información encriptada
   */
  private encrypt(data: any): string {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), KEYS.CRYPTO);
    return encrypted.toString();
  }

  /**
   * Desencripta la data relacionada mediante la key del Storage de sesión
   * @param key Clave relacionada al dato a desencriptar
   * @returns Data desencriptada
   */
  private decrypt(key: string): any {
    const dataToDecrypt = sessionStorage.getItem(key);
    if (dataToDecrypt !== null) {
      const decrypted = CryptoJS.AES.decrypt(
        dataToDecrypt,
        KEYS.CRYPTO
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted);
    } else {
      return null;
    }
  }
}
