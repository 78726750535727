export const MESSAGES = {
  LOGIN: {
    EMPTY_FIELDS: "Debe ingresar los datos solicitados para poder continuar.",
  },
  PLANT_SELECTOR: {
    PLANT_TEXT_ERROR: "*Debes seleccionar una planta",
    DATE_PLAN_ERROR: "*Debes seleccionar una fecha",
  },
  PROGRAMADOR: {
    NO_COMBINE_DINAMO_WITH_NO_DINAMO:
      "No se puede combinar un pedido Dínamo con un pedido no Dínamo.",
    VOLUME_NO_EQUAL_VEHICULE:
      "El total del volúmen de los pedidos no coincide con el total del volúmen del vehículo.",
    FUEL_TYPE_NOT_EQUAL:
      "El tipo de combustible de los pedidos combinados no corresponde al tipo de combustible del vehículo.",
    NO_COMBINE_OVER_CONFIRMED:
      "No se puede combinar un pedido sobre un pedido confirmado.",
    ERROR_SAVING_PLAN:
      "Ha ocurrido un error al guardar la planificación. Intente nuevamente",
    ERROR_UNCONFIRMED:
      "Ha ocurrido un error al desconfirmar el pedido solicitado. Intente nuevamente",
    ERROR_CONFIRM:
      "Ha ocurrido un error al confirmar los pedidos solicitados. Intente nuevamente",
    ERROR_PERFORM_CONFIRMATION:
      "Existen pedidos que no permiten realizar la operación de confirmar (Ver pedidos en rojo).",
    NO_ORDER_TO_CONFIRM: "No hay pedidos que se puedan confirmar.",
    ORDER_REQUIRE_LTR_COUNT_TRUCK:
      "El pedido requiere de un camión con cuenta litros.",
    ORDER_CAPACITY_NOT_SAME_TRUCK:
      "Capacidad del pedido es diferente de la capacidad del camión.",
    ORDER_CAPACITY_EXCEEDS_TRUCK:
      "Capacidad del pedido es Mayor que la capacidad del camión.",
    ORDER_OVERLAPED: "Pedido superpuesto, Bajar/Desconfirmar",
    ORDER_OUT_OF_TURN: "Parte del pedido se encuentra fuera del turno.",
    DELIVERY_TIME_NOT_MATCH:
      "La hora de entrega deseada no coincide con la programación del pedido.",
    ORDER_FUEL_NOT_SAME_TRUCK:
      "El tipo de combustible del pedido no coincide con el tipo de combustible del camión",
    ORDER_IN_TURN_CHANGE: "El pedido coincide con un cambio de turno.",
    COMBINED_ORDERS_OVER_VEHICULE_CAPACITY:
      "El total del volumen de los pedidos supera el máximo del volumen del vehículo.",
    COMBINED_ORDERS_NOT_SAME_VEHICULE_CAPACITY:
      "El total del volumen de los pedidos no coincide " +
      "con el total del volumen del vehículo.",
    COMBINED_ORDERS_FUEL_NOT_SAME_TYPE_VEHICULE:
      "El tipo de combustible de los pedidos combinados " +
      "no corresponde al tipo de combustible del vehículo.",
    ORDER_WITH_FUEL_DISTRIBUTION:
      "Se ha realizado una redistribución del pedido de acuerdo a la capacidad del camión.",
    ERROR_EMPTY:
      "Hay compartimentos sin asignar un producto. Revise la distribución.",
    ERROR_DISTR_EXCEEDS_ORDER:
      "El volumen distribuido del material {mat} supera el volumen original del pedido.",
    ERROR_NO_ORDER_EMPTY:
      "El material {mat} no puede estar sin un compartimento asignado. Verifique la distribución.",
    DISTR_REGISTER_SUCCESS:
      "La distribución se ha registrado en la sesión satisfactoriamente.",
    WARNING_DISTR_MESSAGE:
      " Se ha realizado una redistribución del pedido de acuerdo a la capacidad del camión.",
    ERROR_SAVE_PLAN:
      "Ha ocurrido un error al guardar la planificación. Intente nuevamente",
    DISTR_MESSAGE:
      "Se ha realizado una redistribución de materiales para este pedido.",
  },
};
