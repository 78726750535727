import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProgramadorComponent } from "../../pages/programador/programador.component";

declare var $: any;

@Component({
  selector: "app-modal-no-programed-orders-comments",
  templateUrl: "./modal-no-programed-orders-comments.component.html",
  styleUrls: ["./modal-no-programed-orders-comments.component.css"],
})
export class ModalNoProgramedOrdersCommentsComponent
  implements OnInit, OnDestroy
{
  programador: ProgramadorComponent;
  comments: string;
  orderNumber: string;
  clientName: string;

  private eventListener: any;

  constructor() {}

  ngOnInit() {
    this.eventListener = window.addEventListener(
      "keyup",
      (e: KeyboardEvent) => {
        if (e.code == "Escape") {
          $("#no-programed-orders-comments-modal").modal("hide");
        }
      }
    );
  }

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
  }

  setComments(comments: string) {
    this.comments = comments;
  }

  setClient(clientName: string) {
    this.clientName = clientName;
  }

  setOrderNumber(orderNumber: string) {
    this.orderNumber = orderNumber;
  }

  show() {
    $("#no-programed-orders-comments-modal").modal("show");
  }

  ngOnDestroy(): void {
    window.removeEventListener("keyup", this.eventListener);
  }
}
