import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoginComponent } from "./login/login.component";
import { SelectorPlantaComponent } from "./selector-planta/selector-planta.component";
import { ProgramadorComponent } from "./programador/programador.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TruckComponent } from "./programador/components/truck/truck.component";
import { CellComponent } from "./programador/components/cell/cell.component";
import { OrdenNoProgramadaComponent } from "./programador/components/orden-no-programada/orden-no-programada.component";
import { OrdenProgramadaComponent } from "./programador/components/orden-programada/orden-programada.component";
import { MinuteCellComponent } from "./programador/components/minute-cell/minute-cell.component";
import { ShiftComponent } from "./programador/components/shift/shift.component";
import { ModalsModule } from "../modals/modals.module";
import { AngularSplitModule } from "angular-split";
import { FilterPipe } from "../global/pipes/filter.pipe";
import { FilterPipeTruck } from "../global/pipes/filterTruck.pipe";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { BrowserModule } from "@angular/platform-browser";
import { ShadowHighlightComponent } from "./programador/components/shadow-highlight/shadow-highlight.component";
import { PivotElementComponent } from "./programador/components/pivot-element/pivot-element.component";
// import { AdministradorModule } from './administrador/administrador.module';

@NgModule({
  declarations: [
    LoginComponent,
    SelectorPlantaComponent,
    ProgramadorComponent,
    CellComponent,
    OrdenNoProgramadaComponent,
    OrdenProgramadaComponent,
    MinuteCellComponent,
    TruckComponent,
    ShiftComponent,
    FilterPipe,
    FilterPipeTruck,
    ShadowHighlightComponent,
    PivotElementComponent,
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    ModalsModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    NgbModule,
    BrowserAnimationsModule,
    AngularSplitModule.forRoot(),
    // AdministradorModule
  ],
  entryComponents: [
    OrdenProgramadaComponent,
    ShadowHighlightComponent,
    PivotElementComponent,
  ],
})
export class PagesModule {}
