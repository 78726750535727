import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonApiRequestService } from "../../global/services/common-api-request.service";
import {
  RequestSummary,
  ResponseSummary,
} from "../../global/models/optimizer-summary.model";
import { OPERACIONES } from "../../pages/administrador/constants/operations.const";
import { NgxSpinnerService } from "ngx-spinner";
import { TransformService } from "../../pages/administrador/services/transform.service";
import { delay } from "rxjs/operators";

@Component({
  selector: "app-modal-status-propuesta",
  templateUrl: "./modal-status-propuesta.component.html",
  styleUrls: ["./modal-status-propuesta.component.css"],
})
export class ModalStatusPropuestaComponent implements OnInit {
  modalInfo = {
    name: undefined,
    date: undefined,
    code: undefined,
  };

  summaryInfo = undefined;
  transformedDate;

  constructor(
    public activeModal: NgbActiveModal,
    // private spinner: NgxSpinnerService,
    private apiSummary: CommonApiRequestService,
    private transform: TransformService
  ) {}

  ngOnInit() {
    const formatedDate = this.modalInfo.date.split("-").reverse().join("-");
    // // console.log( this.modalInfo, formatedDate )s

    const request: RequestSummary = {
      operation: OPERACIONES.read,
      payload: {
        proposal_date: formatedDate,
        plant: this.modalInfo.code,
      },
    };

    this.apiSummary
      .requestOptimizeResult(request)
      .pipe(delay(1500))
      .subscribe((resp) => {
        // console.log( resp)
        this.summaryInfo = resp.data;
        // console.log( this.summaryInfo)
        this.summaryInfo.running_date === 0
          ? (this.transformedDate = 0)
          : (this.transformedDate = this.transform
              .transformDate(this.summaryInfo.running_date)
              .replace(/\//g, "-"));
      });

    // Prueba read-Batch propuesta
    // let data = {
    //   operation: OPERACIONES.getBatch,
    //   payload: {
    //     proposal_date: this.modalInfo.date,
    //   }
    // }

    // this.apiSummary.requestOptimizeResult( data).subscribe( resp => {
    //   // console.log( resp )
    // })
  }
}
