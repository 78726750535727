import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstWordTitleCase",
})
export class FirstWordTitleCasePipe implements PipeTransform {
  /**
   * Pipe que retorna la primera letra de la palabra inicial en mayúsculas.
   */

  transform(value: any, ...args: any[]): any {
    let words = value.split(" ");
    let word = words.shift();
    let uppercaseWord =
      word.substring(0, 1).toUpperCase() + word.substring(1, word.length);
    words.unshift(uppercaseWord);
    return words.join(" ");
  }
}
