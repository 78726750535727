import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { ProgramadorComponent } from "../../programador.component";
import { UtilsService } from "src/app/global/utils/utils.service";
// import { ConsoleReporter } from 'jasmine';

@Component({
  selector: "shift",
  templateUrl: "./shift.component.html",
  styleUrls: ["./shift.component.css"],
})
export class ShiftComponent implements OnInit {
  @Input() shift: any;
  @Input() truckId: string;
  checked: boolean;
  isDisabled: boolean;
  @Input() info: any;
  truckBackgroundColor: string;
  truckBoxShadow: string;

  constructor(
    private programador: ProgramadorComponent,
    private cdRef: ChangeDetectorRef,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.isDisabled = false;
    this.checked = true;
    if (this.shift == -1) {
      this.checked = false;
      this.isDisabled = true;
    }

    // si hay un shift, veo que coincida con un vehicleDown (que tiene fecha, vehiculo y hora inicio, fin de turno)
    if (this.shift != -1) {
      var vehicleDownMatch = this.programador.vehicleDown.filter((e) =>
        this.compareVehicleDownWithShift(e, this.shift)
      );
      // // console.log("VDMatch")
      // // console.log(vehicleDownMatch)
      // if(this.programador.vehicleDown.includes(this.truckId)){
      if (vehicleDownMatch.length > 0) {
        this.checked = false;
        // console.log(this.shift);// .active=false;
        // this.isDisabled=false
      }
    }
  }
  // vd vehicleDown object
  compareVehicleDownWithShift(vd, shift) {
    var c1 = vd["TurnoFechaDesde"] == this.utils.swapDate(shift["dayInit"]);
    var c2 = vd["TurnoFechaHasta"] == this.utils.swapDate(shift["dayEnd"]);
    var c3 =
      vd["TurnoHoraDesde"] ==
      this.utils.getHHMMSS(
        shift["hourInit"],
        shift["minutesInit"],
        shift["secondsInit"]
      );
    var c4 =
      vd["TurnoHoraHasta"] ==
      this.utils.getHHMMSS(
        shift["hourEnd"],
        shift["minutesEnd"],
        shift["secondsEnd"]
      );
    var c5 = vd["Vehicle"] == this.truckId;

    if (c1 && c2 && c3 && c4 && c5) {
      return true;
    }

    return false;
  }

  ngAfterViewChecked() {
    this.changeTruckHighlightColor();
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {}

  click(event) {
    event.preventDefault();

    this.programador.openShiftTruckModal(this.truckId, this.shift, this);
  }

  change(event) {}

  changeTruckHighlightColor() {
    switch (this.info.HIGHLIGHTED) {
      case "S":
        this.truckBackgroundColor = "#40DD7F"; // '#DCDCDC'
        this.truckBoxShadow = "0 0 5px #999999";
        break;
      case "N":
        this.truckBoxShadow = " ";
        this.truckBackgroundColor = "#FFFFFF";
        break;
      default:
        return;
    }
  }
}
