import { environment } from "src/environments/environment";

export const HeadersNotProgrammedOrders = [
  {
    displayname: "N° Pedido",
    colname: "VBELN",
    width: "8%",
    visible: true,
    display: "block",
  },
  {
    displayname: "Dinamo",
    colname: "DINAMO",
    width: "5%",
    visible: environment.showDinamoFunctionalities,
    display: environment.showDinamoFunctionalities ? "block" : "none",
  },
  {
    displayname: "Cliente",
    colname: "NAME1_KUNAG_CONCAT",
    width: "15%",
    visible: true,
    display: "block",
  },
  {
    displayname: "",
    colname: "KUNAG",
    width: "1%",
    visible: false,
    display: "none",
  },
  {
    displayname: "Estanque",
    colname: "DETALLE",
    width: "8%",
    visible: true,
    display: "block",
  },
  {
    displayname: "Duración",
    colname: "DURACION_TEXT",
    width: "5%",
    visible: true,
    display: "block",
  },
  {
    displayname: "Ventana",
    colname: "BEZEI_DELCO",
    width: "10%",
    visible: true,
    display: "block",
  },
  {
    displayname: "Clase Documento",
    colname: "AUART",
    width: "7%",
    visible: true,
    display: "block",
  }, // Check campo si esta ok
  {
    displayname: "Clase Pedido",
    colname: "BSARK",
    width: "7%",
    visible: true,
    display: "block",
  }, // Check campo si esta ok
  {
    displayname: "Tipo",
    colname: "GROUPTEXT",
    width: "7%",
    visible: true,
    display: "block",
  },
  {
    displayname: "",
    colname: "DETALLE_PEDIDO",
    width: "1%",
    visible: false,
    display: "none",
  },
  {
    displayname: "Localidad",
    colname: "VTEXT",
    width: "7%",
    visible: true,
    display: "block",
  },
  {
    displayname: "Cta. Litros",
    colname: "CUENTA_LITROS",
    width: "5%",
    visible: true,
    display: "block",
  },
  {
    displayname: "Bloqueo",
    colname: "CMGST_TEXT",
    width: "5%",
    visible: true,
    display: "block",
  },
  {
    displayname: "Comentarios",
    colname: "COMENTARIOS",
    width: "8%",
    visible: true,
    display: "block",
  }, // no se bien que es especial aun
];
