export const ENDPOINTS_PROGRAMADOR = {
  POST_CLEAR: "/sap-integ-limpiar",
  POST_RECEIVER_SQS: "/copec-programador-receiver-sqs",
  POST_CONSUMER_SQS: "/copec-programador-consumer-sqs",
  POST_GET_PLAN: "/sap-integ-get-plan",
  POST_SAVE_PLAN: "/sap-integ-save",
  POST_CONFIRM: "/sap-integ-confirmar",
  POST_CONFIRM_TURN_CHANGE: "/copec-api-confirmar-turno-sap",
  POST_UNCONFIRM: "/sap-integ-desconfirmar",
  POST_REG_SHIFT_TRUCK_CHANGE: "/integ-registrar-cambios-turno-camion",
  POST_TRUCK_ORDER_LIST: "/copec-api-lista-pedidos-sap",
  GET_TRAVEL_TIMES: "/copec-api-planificador-travel-times",
  POST_SESSION_STATE: "/copec-api-planificador-session-state",
  POST_LONG_TRIPS: "/copec-api-planificador-viajes-largos",
  POST_modifications_SQS: "/copec-api-obtiene-modificaciones",
  POST_CONFIRMA_LECTURA: "/copec-api-confirma-lectura",
};
