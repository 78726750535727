import { Component } from "@angular/core";
import { ProgramadorComponent } from "../../pages/programador/programador.component";

declare var $: any;

@Component({
  selector: "app-modal-cambio-plantafecha",
  templateUrl: "./modal-cambio-plantafecha.component.html",
  styleUrls: ["./modal-cambio-plantafecha.component.css"],
})
export class ModalCambioPlantafechaComponent {
  programador: ProgramadorComponent;
  operationType: string;
  plant: string;
  date: string;

  constructor() {}

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
  }
  setOperationType(data: any) {
    this.plant = data.plant;
    let day = "";
    let month = "";
    if (data.date.day <= 9) {
      day = "0" + data.date.day;
    } else {
      day = data.date.day + "";
    }
    if (data.date.month <= 9) {
      month = "0" + data.date.month;
    } else {
      month = data.date.month + "";
    }
    this.date = day + "/" + month + "/" + data.date.year;
  }

  show() {
    $("#cambio-planta-modal").modal("show");
  }

  continue() {
    this.programador.okChangePlantAndDate();
  }

  cancelChangeDate() {
    this.programador.rollBackChangeDate();
  }
}
