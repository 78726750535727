import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ENDPOINTS_SELECTOR_PLANTA } from "../constants/endpoints.const";
import { catchError, retry } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiSelectorPlantaService {
  apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {}

  private handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  public getDistrTruckMotives() {
    return this.http
      .get(this.apiUrl + ENDPOINTS_SELECTOR_PLANTA.GET_DISTRIBUTION_SAP_MOTIVES)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getOrdersMotives() {
    return this.http
      .get(this.apiUrl + ENDPOINTS_SELECTOR_PLANTA.GET_ORDERS_SAP_MOTIVES)
      .pipe(retry(1), catchError(this.handleError));
  }
}
