import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterTruck",
})
export class FilterPipeTruck implements PipeTransform {
  constructor() {}

  transform(values: any[], filter: string): any[] {
    if (!values || !values.length) return [];
    if (!filter) return values;
    // Filter items array, items which match will return true
    return values.filter(
      (v) =>
        v.truckId.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
}
