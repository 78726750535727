import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Optional,
  HostListener,
} from "@angular/core";
import { Order } from "../../classes/order";
import { ProgramadorComponent } from "../../programador.component";
import { UtilsService } from "src/app/global/utils/utils.service";
import { DICTIONARY } from "../../enums/dictionary.enum";
import { ENUMS } from "src/app/global/enums/enums";
import { ProgramadorFunctions } from "../../functions/programador.functions";
import _ from "lodash";
import { MESSAGES } from "src/app/global/constants/messages.const";

@Component({
  selector: "orden-no-programada",
  templateUrl: "./orden-no-programada.component.html",
  styleUrls: ["./orden-no-programada.component.css"],
})
export class OrdenNoProgramadaComponent implements OnInit {
  @Input() order: Order;
  @Input() index: any; // con la nueva logica, el index debiera ser el indice que identifica al npo dentro del array de pedidos
  @Input() vbeln: any;

  // order in format array in order to display in programador.
  orderArray;
  mouseX: any;
  mouseY: any;
  // Dentro de la orden no programada, plannedOrder contiene la representacion en json de la orden que va a pasar a ser planeada, al momento de hacer un drag a la grilla.
  plannedOrder: {};

  npoBackgroundColor: string;
  npoBoxShadow: string;
  currentDropElement: any;

  constructor(
    private utils: UtilsService,
    private renderer: Renderer2,
    private programador: ProgramadorComponent,
    public element: ElementRef,
    @Optional() private ref: ChangeDetectorRef,
    private programadorFunctions: ProgramadorFunctions
  ) {}

  ngOnInit() {
    this.orderArray = this.generateOrderRowFormat();
    // // console.log(this.programador)
    // // console.log(this.order)
    this.programador.noProgrammedOrderElements.push({
      np_vbeln: this.order["VBELN"],
      order_no_programada_object: this,
    });
  }

  /*
  setComentario(comentario){
    this.order["COMENTARIOS"] = comentario
    var elem = this.orderArray.filter(e=>e["name"]=="COMENTARIOS")
    elem[0]["value"]=comentario
    //this.ref.detectChanges();
  }
  */

  ngAfterViewInit() {
    this.changeNpoBackgroundColor();
  }

  changeNpoBackgroundColor() {
    switch (this.order["BSARK"]) {
      case "ZEMG":
        this.npoBackgroundColor = "#FFEBEB";
        this.npoBoxShadow = "0 0 5px #FFEBEB";
        break;
      case "ZCHG":
        this.npoBackgroundColor = "#FFFFEB";
        this.npoBoxShadow = "0 0 5px #FFFFEB";
      default:
        return;
    }
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  dragStart(e) {
    e.source.element.nativeElement.innerHTML = this.order["KUNAG"] * 1;
    this.renderer.addClass(
      e.source.element.nativeElement,
      "draging-no-programmed-order"
    );
    // this.renderer.setStyle(e.source.element.nativeElement, "width",this.sizeInPx(this.utils.hhmmssToHHMMSS(this.order["DURACION"]))+"px");//no se por que en los no programados a veces duracion_calculada es 0. Asumo que es DURACION
    this.renderer.setStyle(
      e.source.element.nativeElement,
      "width",
      this.sizeInPx(
        this.utils.hhmmssSeparateWithColons(this.order["DURACION_CALCULA"])
      ) + "px"
    ); // no se por que en los no programados a veces duracion_calculada es 0. Asumo que es DURACION

    // positionate the moving div to the mouse coordinates
    this.renderer.setStyle(
      e.source.element.nativeElement,
      "position",
      "absolute"
    );
    this.renderer.setStyle(e.source.element.nativeElement, "z-index", "1");
    this.renderer.setStyle(
      e.source.element.nativeElement,
      "left",
      this.mouseX + "px"
    );
    this.renderer.setStyle(
      e.source.element.nativeElement,
      "top",
      this.mouseY + "px"
    );
  }

  // wanna keep the mouse coord to use in drag
  mouseDown(e) {
    this.mouseX = e.x;
    this.mouseY = e.y;
  }

  drag(e) {
    this.programador.clearHighLight();

    let left = e.source.element.nativeElement.getBoundingClientRect().left;
    let top = e.source.element.nativeElement.getBoundingClientRect().top;
    // let match = document.elementsFromPoint(left, top).filter(element => element.className == "minute-cell");

    let match = document
      .elementsFromPoint(left, top)
      .filter((element) => this.hasClass(element, "minute-cell"));

    if (match.length > 0) {
      this.programador.cellMatched = _.get(match[0], "id");
      let cellCoords = match[0].id.split(";");

      var duracion = this.order["DURACION_CALCULA"];
      let totalPlannedOrderLength =
        this.utils.hhmmssSeparateWithColons(duracion);
      this.utils.setOrderLenght(totalPlannedOrderLength, this);

      // let totalPlannedOrderLength = this.utils.hhmmssSeparateWithColons(duracion)
      var coord_obj = this.utils.calculatePositionsShadow(match, this);
      this.programador.highLightCells(coord_obj);
      // this.programador.highLightCells(cellCoords[0], cellCoords[1], parseInt(cellCoords[2]), parseInt(cellCoords[3]) )
    }
  }

  // give x1,x2 and y and check if there is an intersection in X
  checkIntersectionX(x1, x2, y, className) {
    for (var x = x1; x <= x2; x = x + 1) {
      let matchX = document
        .elementsFromPoint(x, y)
        .filter((element) => this.hasClass(element, className));
      if (matchX.length > 0) {
        // return true
        return { check: true, match: matchX };
      }
    }
    // return false;
    return { check: false, match: [] };
  }

  getIndexProgramadroPlan(vbeln: any) {
    var ret = -1;
    this.programador.pedidos.forEach((ped, index) => {
      // supuesto fuerte: VBELN es unico
      if (ped.VBELN.trim() == vbeln.trim()) {
        ret = index;
      }
    });
    return ret;
  }
  drop(e) {
    this.currentDropElement = e;
    // console.log('DROP NO PROGRAMADO');
    this.programador.clearHighLight();

    let left = e.source.element.nativeElement.getBoundingClientRect().left;
    let top = e.source.element.nativeElement.getBoundingClientRect().top;
    let right = e.source.element.nativeElement.getBoundingClientRect().right;
    let bottom = e.source.element.nativeElement.getBoundingClientRect().bottom;

    // let match = document.elementsFromPoint(left, top).filter(element => element.className == "minute-cell");
    // let match = document.elementsFromPoint(left, top).filter(element => this.hasClass(element, "minute-cell"));
    var match = document
      .elementsFromPoint(left, top)
      .filter(
        (element) =>
          this.hasClass(element, "minute-cell") &&
          this.hasClass(element, "shift-available")
      );
    // console.log('match 2!');
    // console.log(match);

    if (match.length == 0) {
      // console.log('match para otro dia... pero match');
      match = document
        .elementsFromPoint(left, top)
        .filter(
          (element) =>
            this.hasClass(element, "minute-cell") &&
            this.hasClass(element, "shift-available-not-today")
        );
    }
    // console.log('MATCH! ');
    // console.log(match);

    // console.log('PLANNED ORDER');
    // console.log(this.order);
    // console.log(this.order['TIENE_RUTA']);
    if (
      this.order["TIENE_RUTA"] != undefined &&
      this.order["TIENE_RUTA"] == false
    ) {
      var warningMessage =
        "El pedido " + this.order["VBELN"] + " no tiene ruta.";
      this.programador.openAlert("warning", warningMessage);
      this.cancelDragNoProgrammedOrder();
      return;
    }

    if (match[0] != undefined) {
      let newParentDom = match[0];
      // if there are 1 element of type programmed-order (the one  that we drop on) means that there are an overlap in 2 programmed orders
      let checkBorderTop = this.checkIntersectionX(
        left,
        right,
        top,
        "programmed-order-borderdetector"
      );
      let checkBorderBottom = this.checkIntersectionX(
        left,
        right,
        bottom,
        "programmed-order-borderdetector"
      );

      // INTENTAMOS PEDIDO COMBINADO DESDE UN NO PROGRAMADO
      if (checkBorderTop.check || checkBorderBottom.check) {
        // // console.log("intentamos subir a un combinado!")
        // this.cancelDragNoProgrammedOrder();
        //////////////////// BEGIN
        // console.log(checkBorderTop);
        // console.log(checkBorderBottom);

        var boxMatched = undefined;
        if (checkBorderTop.check) {
          // console.log('TOP!');
          // console.log(checkBorderTop);
          // console.log(checkBorderTop.match[0]);
          boxMatched = checkBorderTop.match[0];
        }

        if (checkBorderBottom.check) {
          // console.log('BOTTOM!');
          // console.log(checkBorderBottom);
          // console.log(checkBorderBottom.match[0]);
          boxMatched = checkBorderBottom.match[0];
        }

        // let boxMatched = checkBorderTop.check? checkBorderTop.match[1] :  checkBorderBottom.match[1]
        // console.log('BOX MATCHED');
        // console.log(boxMatched);
        let boxMatchedLeft = boxMatched.getBoundingClientRect().left;
        let boxMatchedTop = boxMatched.getBoundingClientRect().top;
        let cellOrderMatched = document
          .elementsFromPoint(boxMatchedLeft, boxMatchedTop)
          .filter((element) => this.hasClass(element, "minute-cell"))[0].id;

        let matchDate = cellOrderMatched.split(";")[0];
        let matchTRuck = cellOrderMatched.split(";")[1];
        let matchHour = cellOrderMatched.split(";")[2];
        let matchMinute = cellOrderMatched.split(";")[3];

        // let matchOrder = this.programador.programmedOrders.filter(e=> e.truckId==matchTRuck &&
        //  e.startHour==matchHour && e.startMinute==matchMinute && e.startDate==matchDate)[0]
        /*
        let matchOrder = this.programador.pedidos.filter(e=> e.VEHICLE==matchTRuck
          && this.utils.hhmmmmToHour(e.PRO_BEGTI)+""==matchHour
          && this.utils.adjustMinuteToCellResolution(this.utils.hhmmmmToMinute(e.PRO_BEGTI))+""==matchMinute
          && this.utils.yyyymmddToDate(e.PRO_BEGDA)==matchDate)[0]
          */
        let matchOrder = this.programador.pedidos.filter(
          (e) =>
            e.VEHICLE == matchTRuck &&
            this.utils.hhmmmmToHour(e.PRO_BEGTI) + "" == matchHour &&
            this.utils.adjustMinuteToCellResolution(
              this.utils.hhmmmmToMinute(e.PRO_BEGTI)
            ) +
              "" ==
              matchMinute &&
            this.utils.yyyymmddToDate(e.PRO_BEGDA) == matchDate
        )[0];

        // console.log('matchOrder');
        // console.log(matchOrder);
        // // console.log(this.order, matchOrder)
        if (!this.utils.validateSameTypeOrder(this.order, matchOrder)) {
          // const warningMessage = 'No se puede combinar un pedido Dínamo con un pedido no Dinamo';
          this.programador.openAlert(
            "danger",
            MESSAGES.PROGRAMADOR.NO_COMBINE_DINAMO_WITH_NO_DINAMO
          );
          this.cancelDragNoProgrammedOrder();
          return;
        }
        // // console.log(matchOrder.CONFIRMADO)

        if (this.otherOrderConfirmed(matchOrder)) {
          let today_temp = new Date();
          let today_month = String(today_temp.getMonth() + 1).padStart(2, "0");
          let today_day = String(today_temp.getDate()).padStart(2, "0");
          let today_year = today_temp.getFullYear();
          let today_str = today_day + "-" + today_month + "-" + today_year;

          let temp_hour = match[0].id.split(";")[2];
          let temp_min = match[0].id.split(";")[3];
          if (temp_hour.length == 1) {
            temp_hour = "0" + temp_hour;
          }
          if (temp_min.length == 1) {
            temp_min = temp_min + "0";
          }
          let pro_begti_temp = temp_hour + temp_min + "00";

          let notSameBeginTime = false;
          if (pro_begti_temp != matchOrder["PRO_BEGTI"]) {
            notSameBeginTime = true;
            // console.log('VERIFICADO: Al montar pedidos, no quedan con mismo tiempo de inicio');
          }
          let isToday = false;
          if (this.programador.plantData.date == today_str) {
            isToday = true;
            // console.log('VERIFICADO: Se está programando para el mismo día');
          }

          if (isToday && notSameBeginTime) {
            this.programador.openMontarPedidoModal(this, match, 1);
          } else {
            this.programador.openAlert(
              "warning",
              "No se puede combinar un pedido sobre un pedido confirmado."
            );
            this.cancelDragNoProgrammedOrder();
            this.programador.updateCountPedidos();
            return false;
          }
          /*
          // console.log("Aqui poder hacer drop sobre confirmado")
          // console.log(this.programador.plantData.date)
          let isToday = true
          if(isToday){
            this.programador.activarClickOrden();

            // console.log("DROP NP A P")
            // console.log("generateNewProgrammedOrderObject")
            // console.log("newParentDom")
            // console.log(newParentDom)

            let candidateInfo = this.generateNewProgrammedOrderObject(newParentDom);

            // console.log("generated generateNewProgrammedOrderObject")
            // console.log(candidateInfo)


            this.plannedOrder = candidateInfo.candidate;
            let newParentElement = candidateInfo.cellMinuteWhereInsert;

            // console.log("VAMOS A PONER EN ESTA CELL")
            // console.log(newParentElement)

            this.clearWarningError(this.plannedOrder)

            //una vez pasados los checks, hacemos el update del modelo y agregamos a grilla
            var index = this.getIndexProgramadroPlan(this.vbeln)
            //this.programador.pedidos[this.index] = this.plannedOrder;
            this.programador.pedidos[index] = this.plannedOrder;

            this.plannedOrder["CONFIR_START_DATE"] = this.plannedOrder["PRO_BEGDA"]
            this.plannedOrder["CONFIR_START_TIME"] = this.plannedOrder["PRO_BEGTI"]

            newParentElement.attachPlannedOrder(this.plannedOrder)
           var showAlert = false//true
           this.utils.validateOrderConditions(this.programador,newParentElement,this,showAlert);

          }
          else{
            this.programador.openAlert("warning","No se puede combinar un pedido sobre un pedido confirmado.")
            //this.cancelDragProgrammedOrder();
            this.cancelDragNoProgrammedOrder();
            this.programador.updateCountPedidos()
            return false;
          }
          */
        } else {
          this.programador.openCombinarPedidoModalNoProgrammed(
            this,
            matchOrder
          );
        }

        /*
        if(this.otherOrderConfirmed(matchOrder)){
          // console.log("Aqui poder hacer drop")
          this.programador.openAlert("warning","No se puede combinar un pedido sobre un pedido confirmado.")
          //this.cancelDragProgrammedOrder();
          this.cancelDragNoProgrammedOrder();
          this.programador.updateCountPedidos()
          return false;
        }*/

        // this.programador.openCombinarPedidoModalNoProgrammed(this,matchOrder);

        /////////////////// EOF
      } else {
        this.programador.activarClickOrden();

        // console.log('DROP NP A P');
        // console.log('generateNewProgrammedOrderObject');
        // console.log('newParentDom');
        // console.log(newParentDom);

        let candidateInfo = this.generateNewProgrammedOrderObject(newParentDom);

        // console.log('generated generateNewProgrammedOrderObject');
        // console.log(candidateInfo);

        this.plannedOrder = candidateInfo.candidate;
        let newParentElement = candidateInfo.cellMinuteWhereInsert;

        // console.log('VAMOS A PONER EN ESTA CELL');
        // console.log(newParentElement);
        // antes de agregar, se hacen los chequeso sobre newProgrammedOrder
        // TODO CHECKS

        /* Nos da lo mismo que la cell sa de un dia distinto al la fecha del programadro.
        if(!this.utils.dateEquals(newParentElement.date,this.programador.plantData.date)){
          // console.log("CANCEL NO! PROGAMMED ORDER")
          this.cancelDragNoProgrammedOrder();
          return
        }
        */

        // Metodo se prueba para setear errores en los pedidos
        // this.setErrorsToS(newProgrammedOrder)

        // limpiamos los warnings
        this.clearWarningError(this.plannedOrder);

        // una vez pasados los checks, hacemos el update del modelo y agregamos a grilla
        var index = this.getIndexProgramadroPlan(this.vbeln);
        // this.programador.pedidos[this.index] = this.plannedOrder;
        this.programador.pedidos[index] = this.plannedOrder;

        this.plannedOrder["CONFIR_START_DATE"] = this.plannedOrder["PRO_BEGDA"];
        this.plannedOrder["CONFIR_START_TIME"] = this.plannedOrder["PRO_BEGTI"];

        newParentElement.attachPlannedOrder(this.plannedOrder);
        var showAlert = false; // true
        this.utils.validateOrderConditions(
          this.programador,
          newParentElement,
          this,
          showAlert
        );
      }
    } else {
      // console.log('no programmed order: No match. Cancelling.');
      // if no element where to drop (a cell minute) we must put the order item back
      this.cancelDragNoProgrammedOrder();
    }
    // borramos el elemento flotante. Eso si si se cancela y esta el filtro, no se renderiza. Ver por que.
    // console.log(' e.source.element.nativeElement.remove()');

    // console.log(e.source.element);
    // console.log(e.source.element.nativeElement);

    e.source.element.nativeElement.remove();
    this.programador.updateCountPedidos();
  }

  remove() {
    // console.log('TEEESR');
    this.currentDropElement.source.element.nativeElement.remove();
  }

  otherOrderConfirmed(otherOrder) {
    // Si o No
    // if(otherOrder.CONFIRMADO=="S")
    if (this.programadorFunctions.esPedidoConfirmado(otherOrder)) {
      return true;
    }
    return false;
  }
  // detect the div cell, triggers the click event that has binded the add order function
  // should detect if there is other order in order to combine.

  // retorna el candidado de pedido programado a reemplazar en lugar del pedido no programado
  // y tambien retorna el elemento de clase cell-minute en donde hacer el attach para visualizar en grilla
  // Se cambio este metodo a publico para poder acceder desde modal montar pedido
  public generateNewProgrammedOrderObject(newParentDom) {
    // PASAR LA NUEVA InFO a la lista de ordenes programadas del padre
    // se obtiene de la celda donde voy a hacer el drop

    let cellInfo = newParentDom.id.split(";");
    let truckId = cellInfo[1];
    let startDate = cellInfo[0];
    let startHour = parseInt(cellInfo[2]);
    let startMinute = parseInt(cellInfo[3]);

    // newParentElement es la celda donde se hara un attachPlannedOrder del elemento pedeido
    let newParentElement = this.programador.minuteCells.filter(
      (e) =>
        e.date == startDate &&
        e.truck == truckId &&
        e.hour == startHour &&
        e.minute == startMinute
    )[0];

    // vamos a generar un clon del pedido con la info actualizada (recordemos que movemos una orden no programada a la grilla pasando a ser programada)
    // luego, el que reciba este candidato a reemplazar la orden, hace el check de restricciones
    // si pasan, usamos el this.index para reemplazar la orden actual con la candidata
    // hacemos una comparacion igual con // console.log a modo de debug
    let candidateToReplaceOrder = {};
    Object.keys(this.order).map((type) => {
      candidateToReplaceOrder[type] = this.order[type];
    });
    // una vez hecho el clon, agregamos los nuevos datos al candidato
    // candidateToReplaceOrder["ESTADO"] = "3"; //Por lo entendido, un 3 es programado, 1 no programado
    candidateToReplaceOrder["VEHICLE"] = truckId;
    candidateToReplaceOrder["PRO_BEGDA"] = this.utils.dateToyyyymmdd(startDate);
    candidateToReplaceOrder["PRO_BEGTI"] = this.utils.hhmmTohhmmss(
      startHour,
      startMinute
    );

    let fechaHoraFinal = this.utils.sumDates(
      candidateToReplaceOrder["PRO_BEGDA"],
      candidateToReplaceOrder["PRO_BEGTI"],
      candidateToReplaceOrder["DURACION_CALCULA"]
    ); // USAMOS DURACION_CALCULA porque al parecer los pedidos no programados, a diferencia de los programados, su duracion gruopo es 0

    candidateToReplaceOrder["PRO_ENDDA"] = fechaHoraFinal.split("-")[0];
    candidateToReplaceOrder["PRO_ENDTI"] = fechaHoraFinal.split("-")[1];

    // let timePlantClient=this.programador.timePlantClient.filter(e=>e.clientCode==this.order.clientCode)[0].time
    // hh:mm:ss
    // let timePlantClient = this.utils.hhmmssToHHMMSS(this.order["DURACION"])
    let timePlantClient = this.utils.hhmmssSeparateWithColons(
      this.order["DURACION_CALCULA"]
    );

    return {
      candidate: candidateToReplaceOrder,
      cellMinuteWhereInsert: newParentElement,
    };
  }

  // Se cambiaron estos metodos a publicos para poder montar pedidos desde la confirmación del modal
  public cancelDragNoProgrammedOrder(listaVbeln = []) {
    // sacamos el nuestro pedido que no se pudo programar del programador.pedidos y lo volvemos a insertar.
    // esto es para que angular detecte el cambio (por eso el refresh) y al insertarlo nuevamente a
    // programador.pedidos, lo redibuje.
    var index = this.getIndexProgramadroPlan(this.vbeln);
    // delete this.programador.pedidos[this.index];
    delete this.programador.pedidos[index];
    this.programador.pedidos = this.programador.pedidos.filter(
      (elem) => elem != undefined
    );

    this.programador.refresh();
    // insertamos en el indice dado, la orden. El cero es que reemplaza si no me equivoco. Ver doc de splice
    // this.programador.pedidos.splice(this.index,0,this.order)
    this.programador.pedidos.splice(index, 0, this.order);
    // this.programador.pedidos[this.index]=this.order

    // borramos vbelsn
    // patch nos aseguramos que si la lista de vbelns es not empty, es pq se trata de pedidos combinados asi que
    if (listaVbeln.length != 0) {
      listaVbeln.forEach((vbeln) => {
        // console.log(vbeln);
        var pedidos = this.programador.pedidos.filter(
          (p) => p["VBELN"] == vbeln
        );
        // console.log(pedidos[0]['VBELN']);
        // console.log(pedidos[0]['AGRUPADOR'].trim());

        if (pedidos[0]["AGRUPADOR"].trim() == "") {
          // // console.log("set pos agrupador a 0 de vbeln " + pedidos[0]["VBELN"])
          pedidos[0]["POS_AGRUPADOR"] = "0";
        }
      });
    }
  }
  @HostListener("dblclick", ["$event"])
  click(event: MouseEvent) {
    event.preventDefault();
    this.programador.openNoProgramedOrderCommentModal(this.vbeln);
  }

  selectText(event) {
    const selection = window.getSelection();
    const range = document.createRange();
    var a = range.selectNodeContents(event.srcElement);
    selection.removeAllRanges();
    selection.addRange(range);
    window.getSelection();
  }

  generateOrderRowFormat() {
    let retArr = [];
    // iterando asi, consigo ordenar las columnas de acuerdo al orden de headersNotProgrammedOrders
    this.programador.headersNotProgrammedOrders.forEach((hnp) => {
      let colname = hnp.colname;
      let visible = hnp.visible;
      let w = hnp.width;
      if (visible) {
        var value = this.order[colname];

        // // console.log("generate Order row format!!")
        // // console.log("colname " + colname)
        // // console.log("value " + value)

        if (colname == "DETALLE") {
          value = this.utils.delZeros(value);
        }
        if (colname === "CMGST_TEXT") {
          if (value === DICTIONARY.BLOQUEO_CREDITO) {
            value = DICTIONARY.BLOQUEO_CREDITO; // "Bloqueado de crédito"
          } else if (value === DICTIONARY.BLOQUEO_ENTREGA) {
            value = DICTIONARY.BLOQUEO_ENTREGA; // "Bloqueo Entrega"
          } else if (value === DICTIONARY.BLOQUEO_FACTURA) {
            value = DICTIONARY.BLOQUEO_FACTURA; // "Bloqueo Factura"
          } else {
            value = DICTIONARY.SIN_BLOQUEO_CREDITO; // "Sin bloqueo"
          }
        }

        if (colname == "DURACION_CALCULA") {
          value = this.utils.hhmmssToSap(value);
        }

        if (colname === "DINAMO") {
          if (value === ENUMS.IS_DINAMO) {
            value = "SI";
          } else {
            value = "NO";
          }
        }

        // nvo
        // if(colname=="COMENTARIOS"){
        //  value = "aaaa"
        // }

        let item = { name: colname, value: value, columnWidth: w };
        retArr.push(item);
      }
    });
    return retArr;
  }

  hasComment() {
    if (this.order["COMENTARIOS"] != "") return true;
    return false;
  }
  /*
  generateOrderRowFormat_OLD(){
    let retArr = []
    Object.keys(this.order).map((type) => {

      let colname = this.programador.headersNotProgrammedOrders.filter(elem => elem.colname == type)[0]["colname"]
      let visible = this.programador.headersNotProgrammedOrders.filter(elem => elem.colname == type)[0]["visible"]
      let w = this.programador.headersNotProgrammedOrders.filter(elem => elem.colname == type)[0]["width"]

      //add each column to a order to be displayed
      if(this.programador.headersNotProgrammedOrders.filter(elem => elem.colname == type)[0]["visible"]){
        let item = { name:type, value:this.order[type], columnWidth: this.programador.headersNotProgrammedOrders.filter(elem => elem.colname == type)[0]["width"]}
        retArr.push(item);
      }

    })
    return retArr;
  }
  */

  // establece el largo de la orden agendada en px, de acuerdo a su largo en tiempo minutos
  private sizeInPx(orderLenght: string) {
    let splittedTime = orderLenght.split(":");
    let hoursInMinutes = parseInt(splittedTime[0]) * 60;
    let minutes = parseInt(splittedTime[1]);
    let secondsInMinutes = parseInt(splittedTime[2]) >= 30 ? 1 : 0;
    let orderLenghtMinutes = hoursInMinutes + minutes + secondsInMinutes;
    return (
      (orderLenghtMinutes * this.programador.pixelUnitTime) /
      this.programador.unitTime
    );
  }

  hasClass(element, className) {
    return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
  }

  clickComment(event, orderArray) {
    event.preventDefault();
    this.programador.openNoProgramedOrderCommentModal(orderArray);
  }

  setWarningToS(message) {
    this.programador.pedidos.forEach((e) => {
      if (this.plannedOrder["VBELN"] == e.VBELN) {
        e["WARNINGS"] = "S";
        this.plannedOrder["WARNINGS"] = "S";
        e["WARNING_MESSAGE"] = message;
      }
    });
  }
  /*
  setWarningToS(newProgrammedOrder) {
    this.programador.pedidos.forEach(e=>{
      if(newProgrammedOrder.VBELN == e.VBELN){
        e["WARNINGS"]="S";
        newProgrammedOrder["WARNINGS"]="S";
      }
    })
  }
  */
  setErrorsToS(message) {
    this.programador.pedidos.forEach((e) => {
      if (this.plannedOrder["VBELN"] == e.VBELN) {
        e["ERRORS"] = "S";
        this.plannedOrder["ERRORS"] = "S";
        e["ERROR_MESSAGE"] = message;
      }
    });
  }
  /*
setErrorsToS(newProgrammedOrder) {
    this.programador.pedidos.forEach(e=>{
      if(newProgrammedOrder.VBELN == e.VBELN){
        e["ERRORS"]="S";
        newProgrammedOrder["ERRORS"]="S";
      }
    })
  }
  */

  clearWarningError(newProgrammedOrder) {
    this.programador.pedidos.forEach((e) => {
      if (newProgrammedOrder.VBELN == e.VBELN) {
        e["WARNINGS"] = " ";
        newProgrammedOrder["WARNINGS"] = " ";
        e["ERRORS"] = " ";
        newProgrammedOrder["ERRORS"] = " ";
      }
    });
  }
}
