import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TransformService } from "../../pages/administrador/services/transform.service";
import { SharedDataService } from "../../global/services/shared-data.service";
import {
  OPERACIONES,
  ERRORINESPERADO,
} from "../../pages/administrador/constants/operations.const";
import { ApiAdministradorService } from "../../pages/administrador/services/api-administrador.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-modal-for-imputations",
  templateUrl: "./modal-for-imputations.component.html",
  styleUrls: ["./modal-for-imputations.component.css"],
})
export class ModalForImputationsComponent implements OnInit {
  @Input() values;
  @Input() selectedPlant;
  admin;
  numericPattern = new RegExp(/^(0|[1-9][0-9]*)$/); // Patrón numérico para validar inputs numéricos

  imputations = this.fb.group({
    origen: [, [Validators.required, Validators.pattern(this.numericPattern)]],
    destino: [, [Validators.required, Validators.pattern(this.numericPattern)]],
    tiempo_viaje_ida: [
      ,
      [Validators.required, Validators.pattern(this.numericPattern)],
    ],
  });

  constructor(
    private fb: FormBuilder,
    private shared: SharedDataService,
    private api: ApiAdministradorService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    if (this.values !== null) {
      this.imputations.get("origen").setValue(this.values["Origen"]);
      this.imputations.get("destino").setValue(this.values["Destino"]);
      this.imputations
        .get("tiempo_viaje_ida")
        .setValue(this.values["Tiempo viaje ida (minutos)"]);
      this.imputations.get("origen").disable();
      this.imputations.get("destino").disable();
    } else {
      this.imputations.get("origen").setValue(this.selectedPlant);
      this.imputations.get("origen").disable();
    }
    this.spinner.hide();
  }

  campoValido(campo: string) {
    return (
      this.imputations.controls[campo].errors &&
      this.imputations.controls[campo].touched
    );
  }

  submit() {
    this.spinner.show();

    if (this.imputations.invalid) {
      return;
    }

    const data = {
      user: this.shared.getLoginInfo().user_name,
      operation: "",
      payload: {
        origin: +this.imputations.get("origen").value,
        destination: +this.imputations.get("destino").value,
        outward_travel_time: +this.imputations.get("tiempo_viaje_ida").value,
      },
    };

    this.values !== null
      ? (data.operation = OPERACIONES.update)
      : (data.operation = OPERACIONES.create);

    this.sendImputation(data);
  }

  sendImputation(payload) {
    this.api.imputations(payload).subscribe((resp) => {
      if (resp != null) {
        const { code, message } = resp;
        this.activeModal.close({ code, message });
      } else {
        this.activeModal.close(ERRORINESPERADO);
      }
    });
  }
}
