import { Component, OnInit, Input } from "@angular/core";
import { ProgramadorComponent } from "../../pages/programador/programador.component";
import { UtilsService } from "../../global/utils/utils.service";
import { SolicitudAlterarTurno } from "src/app/pages/programador/interfaces/turnos.interfaces";

// This lets me use jquery
declare var $: any;

@Component({
  selector: "modal-shift-switch",
  templateUrl: "./modal-shift-switch.component.html",
  styleUrls: ["./modal-shift-switch.component.css"],
})
export class ModalShiftSwitchComponent implements OnInit {
  truckId: string;
  // motivosBaja: string[];
  shift: any;
  comentarios: string;

  motivo = -1;

  motivosBaja = {};
  motivosAlta = {};
  // motivosBaja = ["En Panne", "No se presenta camión/ conductor bloqueado",
  // "No se presenta conductor", "En mantención", "Falla GPS/Equipo mal asociado",
  // "Conductor rechaza única vuelta"];
  // motivosAlta = ["Conductor se presenta tarde","Conductor de reemplazo", "Solucionó panne", "Error humano de bloqueo"]

  motivos = {};

  // clearCellAvailability: any;
  // minuteCells: any;
  shiftComponent: any;
  isActive: boolean;
  label: string;
  errorComments: boolean;
  errorNoSelect: boolean;
  programador: ProgramadorComponent;
  errorCommentsContent: boolean;
  warningMessage: string;

  // constructor(private programador: ProgramadorComponent) { }
  constructor(private utils: UtilsService) {}

  ngOnInit() {}
  hasWarning() {
    if (this.warningMessage.trim() != "") {
      return true;
    }
    return false;
  }
  // escribe los pedidos confirmados al turno
  /*
  setPedidosConfirmados(pedidos:any){
    // console.log("setting pedids")
    this.listaPedidos = pedidos;
    // console.log(this.listaPedidos)
  }
  */
  // each time we open the modal, set default params
  prepareForm() {
    this.isActive = this.shift.active ? true : false;
    this.motivos = this.isActive ? this.motivosBaja : this.motivosAlta;
    this.label = this.isActive ? "baja" : "alta";
    this.motivo = -1;
    this.comentarios = "";
    this.warningMessage = "";
  }

  show() {
    this.prepareForm();
    $("#shift-truck-modal").modal("show");
  }

  close() {
    $("#shift-truck-modal").modal("hide");
  }

  // setters
  setTruckId(truckId: string) {
    this.truckId = truckId;
  }
  setShift(shift: any) {
    this.shift = shift;
  }

  setShiftComponent(shiftComponent) {
    this.shiftComponent = shiftComponent;
  }

  // this methods fixes the error messages in dialog
  selectChanged() {
    this.errorNoSelect = false;
  }
  textAreaChanged() {
    this.errorComments = false;
    this.errorCommentsContent = false;
  }

  // depending if shift is active or not, we will set active or not the shift
  toggleShift() {
    // console.log('Se está tratando de subir/bajar turno');
    this.errorNoSelect = false;
    this.errorComments = false;

    if (this.motivo == -1) {
      this.errorNoSelect = true;
      return false;
    }
    if (this.comentarios == undefined || this.comentarios.trim() === "") {
      this.errorComments = true;
      return false;
    }

    if (!this.utils.validateComments(this.comentarios)) {
      this.errorCommentsContent = true;
      return false;
    }

    this.shift.active = this.shift.active ? false : true;

    // si el turno esta activo, lo dejamos chequeado. Si no, lo dejamos como desmarcado.
    this.shiftComponent.checked = this.shift.active ? true : false;
    this.programador.truckIdModalShiftSwitch = this.truckId;

    // ver si los motivos son tambien pa dar de alta. en ese caso especificar al metodo si se trata de dada de baja o alta.
    var tipo_operacion = "dar de baja";
    if (this.shift.active) {
      tipo_operacion = "dar de alta";
    }

    var reason_code = this.motivo;
    var reason = this.programador.messages_combo["ET_MOTIVOS"]["item"].filter(
      (e) => e.MOTIVO == reason_code
    )[0].DESCRIPCION;
    this.programador.register_shift_truck(
      this.truckId,
      reason,
      reason_code,
      tipo_operacion,
      this.comentarios
    );
    var fechaIni = this.utils.dateToSapformat(this.shift.dayInit);
    var fechaFin = this.utils.dateToSapformat(this.shift.dayEnd);
    var horaIni = this.utils.getHHMMSS(
      this.shift.hourInit,
      this.shift.minutesInit,
      this.shift.secondsInit
    );
    var horaFin = this.utils.getHHMMSS(
      this.shift.hourEnd,
      this.shift.minutesEnd,
      this.shift.secondsEnd
    );

    let solicitud: SolicitudAlterarTurno = {
      truckId: this.truckId,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      horaIni: horaIni,
      horaFin: horaFin,
      message_code: reason_code,
      comentarios: this.comentarios,
      shift: this.shift,
    };
    if (tipo_operacion == "dar de baja") {
      this.programador.darDeBajaTurno(solicitud);
    } else {
      this.programador.darDeAltaTurno(solicitud);
    }

    // Esto estaba descomentado
    // TODO FIX orderNoprogramedOrdersAsc
    // this.programador.orderNoprogramedOrdersAsc();
    /*
    //Dado que se bajo un turno, hay que recalcular la disponibilidad de las celdas. Para ello se
    //limpian todas las celdas (se dejan como no disponibles), y luego se recalcula la disponibilidad
    //deja todas las celdas como  no disponibles
    this.programador.clearCellAvailability();
    //define las celdas que estaran disponibles para los turnos de los camiones
    this.programador.minuteCells.forEach(element => {
      element.checkCellTruckAvailability();
    });
    //los elementos que estaban sobre las celdas otrora disponibles, se eleiminan.
    this.programador.checkPlannedCellAvailability();
    */
    /**/
    this.close();
  }

  isConfirmedInShift() {
    return this.programador.checkConfirmedInShift(this.shift, this.truckId);
  }

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
    // // console.log(this.programador.messages_combo["ET_MOTIVOS"])
    this.motivosBaja = this.programador.messages_combo["ET_MOTIVOS"][
      "item"
    ].filter((e) => e["TIPO"] == "B");
    this.motivosAlta = this.programador.messages_combo["ET_MOTIVOS"][
      "item"
    ].filter((e) => e["TIPO"] == "A");
  }
}
