import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-close-session-programmate",
  templateUrl: "./modal-close-session-programmate.component.html",
  styleUrls: ["./modal-close-session-programmate.component.css"],
})
export class ModalCloseSessionProgrammateComponent implements OnInit {
  time: number;
  interval: any;
  countdown: number;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.startTimer();
  }

  close(val) {
    this.activeModal.close(val);
  }

  startTimer() {
    let initialTime = new Date().getTime();
    this.interval = setInterval(() => {
      let now = new Date().getTime();
      let time = now - initialTime + 10;
      this.countdown = this.time - Math.floor(time / 1000);
      if (this.countdown <= 0) {
        clearInterval(this.interval);
        this.activeModal.dismiss("no click");
      }
    }, 1000);
  }
}
