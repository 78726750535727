import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedDataService } from "src/app/global/services/shared-data.service";
import { OPERACIONES } from "src/app/pages/administrador/constants/operations.const";
import { Constraint } from "src/app/pages/administrador/models/requests.model";
import { AlertService } from "src/app/pages/administrador/services/alert.service";
import { ApiAdministradorService } from "src/app/pages/administrador/services/api-administrador.service";
import { ERRORINESPERADO } from "../../pages/administrador/constants/operations.const";

@Component({
  selector: "app-modal-constraint-flota",
  templateUrl: "./modal-constraint-flota.component.html",
  styleUrls: ["./modal-constraint-flota.component.css"],
})
export class ModalConstraintFlotaComponent implements OnInit {
  @Input() values;
  @Input() plantInfo;
  admin;
  vigente;
  vehiculos;

  numericPattern = new RegExp(/^(0|[1-9][0-9]*)$/);

  constraintFlota = this.fb.group({
    vigencia: [, Validators.required],
    cliente: [, [Validators.required, Validators.pattern(this.numericPattern)]],
    vehiculo: [
      ,
      [Validators.required, Validators.pattern(this.numericPattern)],
    ],
    comentario: [],
  });

  constructor(
    private shared: SharedDataService,
    private api: ApiAdministradorService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.admin = this.shared.getLoginInfo().user_name;

    const data = {
      user: this.admin,
      operation: OPERACIONES.getBatch,
      payload: {},
    };

    this.api.get_constraints_master(data).subscribe((resp) => {
      this.vigente = resp.data.filter((e) => e.field_name === "vigente")[0];
      if (this.values == undefined) {
        this.spinner.hide();
        this.constraintFlota.get("vigencia").setValue("SI");
      }
    });

    if (this.values != null && this.plantInfo == undefined) {
      this.plantInfo = {
        code: this.values["ID planta"],
        name: this.values["Planta"],
        restriction: this.values["Tipo de restricción"],
      };

      data.operation = OPERACIONES.read;
      data["payload"]["id_planta"] = this.values["ID planta"];
      data["payload"]["constraint_id"] = this.values["ID restricción"];

      this.api.constraints(data).subscribe((resp) => {
        if (resp.code === 200) {
          this.constraintFlota.patchValue({
            vigencia: resp.data.vigente,
            cliente: resp.data.cliente_id_copec,
            vehiculo: resp.data.vehiculo,
            comentario: resp.data.comentario,
          });

          this.constraintFlota.get("cliente").disable();
        } else {
          let alert = {
            header: this.alert.setAlert(resp.code),
            code: resp.code,
            body: resp.message,
          };
          this.alert.show(alert);
        }
        this.spinner.hide();
      });
    }
  }

  send() {
    this.spinner.show();

    let data: Constraint = {
      user: this.admin,
      operation: "",
      payload: {
        id_planta: this.plantInfo.code,
        planta: this.plantInfo.name,
        tipo_restriccion: this.plantInfo.restriction,
        vigente: this.constraintFlota.get("vigencia").value,
        cliente_id_copec: this.constraintFlota.get("cliente").value,
        vehiculo: this.constraintFlota.get("vehiculo").value,
        comentario:
          this.constraintFlota.get("comentario").value === ""
            ? " "
            : this.constraintFlota.get("comentario").value,
        constraint_id:
          this.values == null ? undefined : this.values["ID restricción"],
      },
    };

    this.values !== null
      ? (data.operation = OPERACIONES.update)
      : (data.operation = OPERACIONES.create);

    this.sendConstraint(data);
  }

  sendConstraint(payload) {
    this.api.constraints(payload).subscribe((resp) => {
      if (resp != null) {
        const { code, message } = resp;
        this.activeModal.close({ code, message });
      } else {
        this.activeModal.close(ERRORINESPERADO);
      }
    });
  }

  campoValido(campo: string) {
    return (
      this.constraintFlota.controls[campo].errors &&
      this.constraintFlota.controls[campo].touched
    );
  }
}
