import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { environment } from "src/environments/environment";
import { DatosPlantaFecha } from "../interfaces/datos-planta-fecha";
import {
  ObjetoModificacion,
  RespuestaApiModificaciones,
} from "../interfaces/respuesta-api-modificaciones";
import { ApiProgramadorService } from "./api-programador.service";
import { SolicitudConfirmarLectura } from "../interfaces/solicitud-confirmar-lectura";

@Injectable({
  providedIn: "root",
})
export class ModificacionesTurnosService {
  private _modificacionRecibida = new ReplaySubject<ObjetoModificacion[]>();
  private configuracionActual = <DatosPlantaFecha>{};
  private username = "";
  private INTERVALO_CONSULTA_MS = 1000 * 5;
  private servicioIniciado = false;
  public procesandoRespuesta = false;
  private callbackDetenerServicio: (value: null) => void;
  private printdbg(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
      console.log(message, ...optionalParams);
    }
  }

  // Canal por el cual los subscriptores obtienen modificaciones
  onModificacionRecibida = this._modificacionRecibida.asObservable();

  constructor(public api: ApiProgramadorService) {
    // Esto debería invocarse solo una vez en toda la vida de aplicación
    this.printdbg("ModificacionesTurnosService.constructor called!");
  }

  async detenerServicio() {
    this.printdbg("ModificacionesTurnosService.detenerServicio called!");
    // Si el servicio no se ha iniciado, no hacemos nada
    if (!this.servicioIniciado) {
      this.printdbg("El servicio no se está ejecutando");
      return;
    }
    this.servicioIniciado = false;
    // Espera a cualquier petición que aún se encuentra en progreso en la función `iniciarServicio`
    await new Promise((resolve) => {
      this.callbackDetenerServicio = resolve;
    });
    this.printdbg("ModificacionesTurnosService.detenerServicio done!");
  }

  cambiarPlantaFecha(data: DatosPlantaFecha, username: string) {
    // Actualiza los datos utilizados para consultar a la API
    this.configuracionActual = data;
    this.username = username;
    this.printdbg("ModificacionesTurnosService.cambiarPlantaFecha called!");
  }

  async iniciarServicio() {
    // Ejecuta cada `INTERVALO_CONSULTA_MS` la consulta a la API de modificaciones
    this.printdbg("ModificacionesTurnosService.iniciarServicio called!");
    if (this.servicioIniciado) {
      // Si ya se ha iniciado no hacemos nada
      this.printdbg("El servicio ya se encuentra en progreso");
      return;
    }
    this.servicioIniciado = true;
    while (this.servicioIniciado) {
      // Ejecutamos la consulta y enviamos las modificaciones a los subscriptores en `onModificacionRecibida`
      await this.consultarModificaciones();
      // Esperamos `INTERVALO_CONSULTA_MS` para la siguiente ejecución
      await new Promise((resolve) =>
        setTimeout(resolve, this.INTERVALO_CONSULTA_MS)
      );
    }
    // Se ha detenido el servicio y ya no hay llamadas en progreso, ejecutamos callback para notificar
    if (this.callbackDetenerServicio) {
      this.printdbg(
        "ModificacionesTurnosService.callbackDetenerServicio called!"
      );
      this.callbackDetenerServicio(null);
    }
    this.printdbg("ModificacionesTurnosService.iniciarServicio done!");
  }

  private async consultarModificaciones() {
    this.printdbg(
      "ModificacionesTurnosService.consultarModificaciones called!"
    );
    this.printdbg(this.configuracionActual);
    const cadenaSplit = this.configuracionActual.date.split("-");
    const payloadModifications = {
      user: this.username,
      operation: "read",
      payload: {
        codigo_planta: this.configuracionActual.code,
        codigo_fecha:
          cadenaSplit[2] + "-" + cadenaSplit[1] + "-" + cadenaSplit[0],
      },
    };
    const response = this.api
      .getSqsModifications(payloadModifications)
      .toPromise();
    response.then((data: RespuestaApiModificaciones) => {
      if (data.data.actualizaciones.length > 0) {
        this.printdbg("Tenemos actualizaciones, invocando subscriptores");
        this._modificacionRecibida.next(data.data.actualizaciones);
      } else {
        this.printdbg("Actualizaciones vacias");
      }
    });
    this.printdbg(new Date());
    this.printdbg("ModificacionesTurnosService.consultarModificaciones done!");
  }

  async confirmarMultiplesLecturas(solicitudes: SolicitudConfirmarLectura[]) {
    this.printdbg(
      "ModificacionesTurnosService.confirmarMultiplesLecturas called!"
    );
    for (let solicitud of solicitudes) {
      await this._confirmarLectura(solicitud);
    }
  }

  async _confirmarLectura(solicitud: SolicitudConfirmarLectura) {
    this.printdbg("ModificacionesTurnosService._confirmarLectura called!");
    await this.api.postApiConfirmaLectura(solicitud).toPromise();
  }
}
