import { UtilsService } from "src/app/global/utils/utils.service";
import { TruckData } from "../models/general.model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PedidoService {
  constructor(private utils: UtilsService) {}

  getPedidosNoConfirmadosEnTurnoABajar(
    deconfirm_truck_data: TruckData,
    pedidos: any
  ) {
    let pedidosABajarYGuardar = [];
    let pedidosEnCamion = pedidos.filter(
      (p) => p.VEHICLE == deconfirm_truck_data.Vehicle
    );
    pedidosEnCamion.forEach((elm) => {
      // obtenemos los timestamp del inicio y termino del pedido programado
      // tiene que quedar en formato dd-mm-yyyy
      let progBegTime = this.utils.dbDateTimeToTimestamp(
        elm.PRO_BEGDA,
        elm.PRO_BEGTI
      );
      let progEndTime = this.utils.dbDateTimeToTimestamp(
        elm.PRO_ENDDA,
        elm.PRO_ENDTI
      );
      // si progBegTime/Endtime se encuentra dentro del turno,
      // determinado por econfirm_truck_data["TurnoFechaDesde"] y similares, se agrega a la lista de pedidos a bajarYGuardar
      let dateTurnoIni = this.utils.dateToyyyymmdd(
        this.utils.swapDate(deconfirm_truck_data.TurnoFechaDesde)
      );
      let dateTurnoEnd = this.utils.dateToyyyymmdd(
        this.utils.swapDate(deconfirm_truck_data.TurnoFechaHasta)
      );
      let hourIni = deconfirm_truck_data.TurnoHoraDesde.replace(/:/g, "");
      let hourEnd = deconfirm_truck_data.TurnoHoraHasta.replace(/:/g, "");
      let turnoBegTime = this.utils.dbDateTimeToTimestamp(
        dateTurnoIni,
        hourIni
      );
      let turnoEndTime = this.utils.dbDateTimeToTimestamp(
        dateTurnoEnd,
        hourEnd
      );
      let cond1 = progBegTime >= turnoBegTime && progBegTime <= turnoEndTime;
      let cond2 = progEndTime >= turnoBegTime && progEndTime <= turnoEndTime;
      // desasignamos el VEHICULO y AGRUPADOR ya que vamos a dar de baja el pedido
      // en caso que se nos cuele un confirmado, solo tenemos que considerar los de
      // ESTADO 1, (no 3 que es confirmado. De eso se encarga SAP de desconfirmar al bajar el turno)
      if (cond1 && cond2 && elm.ESTADO === "1") {
        pedidosABajarYGuardar.push(elm);
      }
    });
    return pedidosABajarYGuardar;
  }
}
