import { Component, OnDestroy, OnInit } from "@angular/core";
import { ENUMS } from "src/app/global/enums/enums";
import { ProgramadorComponent } from "../../pages/programador/programador.component";
import { UtilsService } from "../../global/utils/utils.service";
import _ from "lodash";
import { ListadoDistribucion } from "src/app/pages/programador/interfaces/listado-distribucion";
import { ListadoMotivos } from "../../pages/programador/interfaces/motivos-distribucion";
import { MESSAGES } from "src/app/global/constants/messages.const";
import { SessionService } from "src/app/global/services/session.service";
import { GLOBAL } from "src/app/global/constants/global.const";
import { ProgramadorFunctions } from "src/app/pages/programador/functions/programador.functions";
import { environment } from "src/environments/environment";
// This lets me use jquery
declare var $: any;

@Component({
  selector: "modal-details-order",
  templateUrl: "./modal-details-order.component.html",
  styleUrls: ["./modal-details-order.component.css"],
})
export class ModalDetailsOrderComponent implements OnInit, OnDestroy {
  order: any;
  InfoColOne: any[];
  InfoColTwo: any[];
  OrderProgrammedDetailsColOne: any[];
  OrderProgrammedDetailsColTwo: any[];
  OrderProgrammedDetailsColThree: any[];
  programador: ProgramadorComponent;
  errorCommentsDarBaja: boolean;
  errorNoSelectDarBaja: boolean;
  comentariosDarBaja: string;
  errorCommentsUncorfirm: boolean;
  errorNoSelectUncorfirm: boolean;
  comentariosUncorfirm: string;
  motivoBaja = -1;
  motivoDesconfirmar = -1;
  warning: string;
  isDinamo: boolean;
  showRedisButton: boolean;
  tipoDocumentoDinamo: string[] = ["ZC01", "ZCIN"]; //ver comentario linea 146
  truckSpaces: any;
  listadoDistribucion: ListadoDistribucion[];
  motivoDistribucionValue = "-1";
  distrMessage: string;
  showDistrWarning: boolean;
  showDistrSuccess: boolean;
  listaMotivos: ListadoMotivos = this.sessionService.getDistrMotives();
  motivoDistribucion = undefined;
  truckLength: number;
  msgDistr: string = MESSAGES.PROGRAMADOR.DISTR_MESSAGE;
  errorMotivo: boolean;

  motivosBaja = GLOBAL.MOTIVOS_BAJA;

  motivosDesconfirmacion = GLOBAL.MOTIVOS_DESCONFIRMACION;
  showRedisButtonFromEnv = environment.showRedisBtnForDinamo;

  errorCommentsDarBajaContent: boolean;
  errorCommentsUncorfirmContent: boolean;
  error: any;
  comentarios: any;

  sessionEditPermission: boolean;

  private eventListener: any;

  constructor(
    private utils: UtilsService,
    private sessionService: SessionService,
    private programadorFunctions: ProgramadorFunctions
  ) {}

  ngOnInit() {
    // this.show();
    // console.log('Motivos distribucion: ', this.listaMotivos);
    this.sessionEditPermission = this.programador.sessionEditPermission;

    this.eventListener = window.addEventListener(
      "keyup",
      (e: KeyboardEvent) => {
        if (e.code == "Escape") {
          $("#order-details-modal").modal("hide");
        }
      }
    );
  }

  prepareForm() {
    this.motivoBaja = -1;
    this.motivoDesconfirmar = -1;
    this.comentariosDarBaja = "";
    this.comentariosUncorfirm = "";
    this.errorCommentsDarBajaContent = false;
    this.errorCommentsUncorfirmContent = false;
    this.errorCommentsDarBaja = false;
    this.errorCommentsUncorfirm = false;
  }
  isOrderLoaded() {
    if (this.order == undefined) {
      return false;
    }
    return true;
  }
  show() {
    this.showDistrWarning = false;
    this.prepareForm();
    $("#carruselDetallePedido").carousel(0);
    $("#order-details-modal").modal("show");
  }
  // setOrderObject(order: any, programador: ProgramadorComponent){
  setOrderObject(order: any) {
    this.showOrder(order);
  }
  showOrder(order: any) {
    this.showDistrSuccess = false;
    this.errorMotivo = false;
    this.motivoDistribucionValue = "-1";
    this.motivoDistribucion = undefined;
    // order.order es un array. Si se llama modal details order component y no se llama modal details combined order,
    // es porque hay una orden por eso order.order es order.order[0]
    this.order = order;

    // Validamos si la orden es Dinamo.
    this.isDinamo = order.DINAMO === ENUMS.IS_DINAMO ? true : false;

    this.warning = order.WARNING_MESSAGE;
    this.error = order.ERROR_MESSAGE;

    /**
     * Recuperamos el camión asociado al pedido para obtener el detalle de sus comaprtimentos
     */
    this.truckSpaces = _.find(
      this.programador.trucks,
      (truck) => truck.truckId * 1 === order.VEHICLE * 1
    );
    this.truckLength = this.truckSpaces.capacity.length;

    /**
     * Seteamos el largo de la interface que se encargara de registrar la distribución del pedido
     */
    this.listadoDistribucion = Array(this.truckLength).fill({
      OPT: null,
      VBELN: null,
      MATNR: null,
      MAKTX: null,
      KWMENG: null,
      MOTIVO: null,
    });

    /**
     * Verificamos si existe info en la sesión respecto a la distribución para cargar la info en caso de que
     * se desee realizar modificaciones
     */
    const dataListPedidos = this.sessionService.getDistrOrders();
    if (dataListPedidos !== null) {
      const dataPedido = _.filter(dataListPedidos, { VBELN: this.order.VBELN });
      if (dataPedido.length > 0) {
        this.listadoDistribucion = dataPedido;
        this.motivoDistribucionValue = this.listadoDistribucion[0].MOTIVO;
        this.motivoDistribucion = _.get(
          _.find(this.listaMotivos.motivos, {
            motivo: this.motivoDistribucionValue,
          }),
          "texto"
        );
        this.showDistrSuccess = true;
      }
    }

    /**
     * Validamos si en ERROR_MESSAGE se incluye el mensaje de que el pedido sobrepasa la capacidad del
     * camión y hacemos la condición junto con isDinamo para desplegar o no el botón y
     * que el pedido no se encuentre confirmado (ESTADO !== 3)
     * 28-09-2011 Se agrego propiedad tipoDocumentodinamo para alojar los valores permitidos en el tipo de documento,
     * contando con un filtro adicional de acuerdo al tipo de documento del pedido. Actualmente se encuentra comentando el trozo de código
     * que haría esta verificación, línea 151
     */
    if (
      !this.utils.checkToShowRedisButton(this.truckSpaces, this.order) &&
      this.isDinamo &&
      this.order.ESTADO !== "3" &&
      this.tipoDocumentoDinamo.includes(this.order.AUART)
    ) {
      this.showRedisButton = true;
    } else {
      this.showRedisButton = false;
    }

    // dado que aun no sabemos bien cual manda si DURACION o DURACION_GRUPO, vemos primero la
    // duracion grupo y si es cero, ocupamos duracion
    // let duracion = order["DURACION_GRUPO"]*1 != 0? this.utils.hhmmssToHHMMSS(order["DURACION_GRUPO"]) :
    // this.utils.hhmmssToHHMMSS(order["DURACION"])
    let duracion =
      order["DURACION_CALCULA"] * 1 != 0
        ? this.utils.hhmmssSeparateWithColons(order["DURACION_CALCULA"])
        : this.utils.hhmmssSeparateWithColons(order["DURACION_CALCULA"]);

    this.InfoColOne = [];
    if (environment.showDinamoFunctionalities) {
      this.InfoColOne.push({
        name: "Pedido Dínamo",
        value: this.order.DINAMO === ENUMS.IS_DINAMO ? "SI" : "NO",
      });
    }
    this.InfoColOne.push({ name: "Duración", value: duracion });
    this.InfoColOne.push({
      name: "Número de pedido",
      value: this.order.VBELN * 1,
    });
    this.InfoColOne.push({ name: "Clase Documento", value: this.order.AUART });
    this.InfoColOne.push({ name: "Clase pedido", value: this.order.BSARK });
    this.InfoColOne.push({
      name: "Tipo de material",
      value: this.order.GROUPTEXT,
    });
    this.InfoColOne.push({
      name: "Ventana horaria",
      value: this.order.BEZEI_DELCO,
    });

    this.InfoColTwo = [];
    this.InfoColTwo.push({
      name: "Nombre Cliente",
      value: this.order.NAME1_KUNAG,
    });
    this.InfoColTwo.push({
      name: "Código Cliente",
      value: this.order.KUNAG * 1,
    }); // agregar
    this.InfoColTwo.push({ name: "Nombre Localidad", value: this.order.VTEXT });
    let detalle = this.order.DETALLE.split("=")[0]; // a veces detalle viene con un igual = y la suma. no queremos eso.
    this.InfoColTwo.push({ name: "Detalle", value: detalle }); // ver bien si detalle es el volumen de litro
    let detalle_programado = this.order.DETALLE_PROGRAMADO.split("=")[0];
    this.InfoColTwo.push({
      name: "Detalle Programado",
      value: detalle_programado,
    });
    this.InfoColTwo.push({
      name: "Cuenta Litros",
      value: this.order.CUENTA_LITROS,
    });
    // this.InfoColTwo.push({name:"Bloqueo Crédito", value:this.order.CMGST=="B"?"Bloqueado de crédito":"Sin bloqueo"});
    this.InfoColTwo.push({
      name: "Bloqueo Crédito",
      value: this.order.CMGST_TEXT,
    });

    this.OrderProgrammedDetailsColOne = [];
    this.OrderProgrammedDetailsColOne.push({
      name: "Camión",
      value: this.order.VEHICLE * 1,
    });
    let startHour = this.utils.hhmmmmToHour(this.order.PRO_BEGTI);
    let startMinute = this.utils.hhmmmmToMinute(this.order.PRO_BEGTI);
    let startSecond = this.utils.hhmmmmToSeconds(this.order.PRO_BEGTI);
    this.OrderProgrammedDetailsColOne.push({
      name: "Hora inicio carga",
      value:
        this.fixZeroTime(startHour) +
        ":" +
        this.fixZeroTime(startMinute) +
        ":" +
        this.fixZeroTime(startSecond),
    });

    this.OrderProgrammedDetailsColTwo = [];
    let startDate = this.utils.yyyymmddToDate(this.order.PRO_BEGDA);
    this.OrderProgrammedDetailsColTwo.push({
      name: "Fecha inicio carga",
      value: startDate,
    });

    // this.OrderProgrammedDetailsColTwo.push({name:"Duración", value:this.order.order[index].length});
    this.OrderProgrammedDetailsColTwo.push({
      name: "Duración",
      value: duracion,
    });
    this.OrderProgrammedDetailsColThree = [];
    // this.OrderProgrammedDetailsColThree.push({name:"Hora Término", value:this.getEndHour(this.order.startHour,
    // this.order.startMinute,this.order.order[index].length)});
    const backHour: string =
      this.order.PRO_ENDTI.substring(0, 2) +
      ":" +
      this.order.PRO_ENDTI.substring(2, 4) +
      ":" +
      this.order.PRO_ENDTI.substring(4, 6);
    this.OrderProgrammedDetailsColThree.push({
      name: "Hora regreso planta",
      value: backHour,
    });
    this.OrderProgrammedDetailsColThree.push({
      name: "Fecha regreso planta",
      value: this.utils.yyyymmddToDate(
        this.utils
          .sumDates(
            this.order.PRO_BEGDA,
            this.order.PRO_BEGTI,
            this.order.DURACION_CALCULA
          )
          .split("-")[0]
      ),
    });

    this.show();
  }
  fixZeroTime(unit: number) {
    let fixedUnit = unit <= 9 ? "0" + unit : unit;
    return fixedUnit;
  }

  // must be testeable
  private getEndHour(startHour: number, startMinute: number, lenght: string) {
    // lenght always seconds in 0 and this format HH:MM:00
    var deltaHour = parseInt(lenght.split(":")[0], 10);
    var deltaMinute = parseInt(lenght.split(":")[1], 10);

    var endMinute = startMinute + deltaMinute;

    if (endMinute >= 60) {
      endMinute = endMinute - 60;
      deltaHour++;
    }

    var endHour = startHour + deltaHour;

    var quotient = Math.ceil(deltaHour / 24);
    if (endHour > 24 * quotient - 1) {
      endHour = endHour - 24 * quotient;
    }

    return (
      this.fixZeroTime(endHour) + ":" + this.fixZeroTime(endMinute) + ":00"
    );
  }

  desconfirmarActivado() {
    // if(this.order.CONFIRMADO == 'S')
    if (this.programadorFunctions.esPedidoConfirmado(this.order)) {
      return true;
    }
    return false;
  }

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
  }

  showDistributionSlide() {
    $("#carruselDetallePedido").carousel("next");
  }

  showDetailsSlide() {
    $("#carruselDetallePedido").carousel("prev");
    this.showDistrWarning = false;
  }

  saveProductDistribution() {
    const validateDistr = this.validateDistribution(this.listadoDistribucion);
    this.distrMessage = _.get(validateDistr, "message");
    if (
      this.motivoDistribucionValue === "-1" &&
      this.motivoDistribucion === undefined
    ) {
      this.errorMotivo = true;
      return;
    }
    if (_.get(validateDistr, "ok") === false) {
      this.showDistrWarning = true;
      return;
    }
    this.showDistrWarning = false;
    let distrPedidos: any = this.sessionService.getDistrOrders();
    let sumapedidos = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.listadoDistribucion.length; i++) {
      this.listadoDistribucion[i].MOTIVO = this.motivoDistribucionValue;

      sumapedidos = sumapedidos + parseInt(this.listadoDistribucion[i].KWMENG);
    }

    // Calculamos el volumen por material
    const volumePerMaterial = {};
    this.listadoDistribucion.forEach((compartimento) => {
      volumePerMaterial[compartimento.MATNR] =
        (volumePerMaterial[compartimento.MATNR] || 0) +
        parseInt(compartimento.KWMENG);
    });

    // Al redistribuir un pedido se iguala el volumen programado al de
    // la redistribución
    this.order.DETALLE_PROGRAMADO =
      Intl.NumberFormat("de-DE").format(sumapedidos) + ",000";
    this.InfoColTwo[4].value = this.order.DETALLE_PROGRAMADO;

    // Al bajar un pedido se resetean los valores de redistribución por material
    this.order.DETALLE_PEDIDO.forEach(
      (material: {
        VBELN: String;
        MATNR: String;
        MAKTX: String;
        KWMENG: String;
        MEINS: String;
        BMENG: String;
        VRKME: String;
      }) => {
        material.BMENG = volumePerMaterial[material.MATNR.toString()] + ".0";
      }
    );

    if (distrPedidos === null) {
      distrPedidos = this.listadoDistribucion;
    } else {
      // TODO: RACG Verificar si hay que limpiar los compartimentos sin carga antes de guardar la distribución
      _.remove(distrPedidos, (remover) => {
        return remover.VBELN === this.listadoDistribucion[0].VBELN;
      });
      distrPedidos.push(...this.listadoDistribucion);
    }
    this.sessionService.saveDistrOrders(distrPedidos);
    _.set(this.order, "ERRORS", " ");
    _.set(this.order, "ERROR_MESSAGE", " ");
    if (
      !_.includes(
        _.get(this.order, "WARNING_MESSAGE"),
        MESSAGES.PROGRAMADOR.WARNING_DISTR_MESSAGE
      )
    ) {
      const warnMsg =
        _.get(this.order, "WARNING_MESSAGE") +
        MESSAGES.PROGRAMADOR.WARNING_DISTR_MESSAGE;
      _.set(this.order, "WARNING_MESSAGE", warnMsg);
      this.warning = warnMsg;
    }

    this.error = " ";
    this.showDistrSuccess = true;
    this.showDetailsSlide();
  }

  changeDistribution(event) {
    this.showDistrWarning = false;
    const paramName = _.split(event.srcElement.name, "_");
    const nameIndex: number = Number(paramName[1]);
    const paramValue = event.srcElement.value;
    const dataPedido: any = _.split(paramValue, "_");
    this.listadoDistribucion[nameIndex] = {
      OP: event.srcElement.name,
      VBELN: dataPedido[0],
      MATNR: dataPedido[1],
      MAKTX: dataPedido[2],
      KWMENG: dataPedido[3],
      MOTIVO: "",
    };
  }

  changeMotive(value: string) {
    if (value !== "-1") {
      this.errorMotivo = false;
    }
    this.motivoDistribucionValue = value;
    this.motivoDistribucion = _.get(
      _.find(this.listaMotivos.motivos, { motivo: value }),
      "texto",
      undefined
    );
  }

  hideWarning() {
    this.showDistrWarning = false;
  }

  /**
   * Ejecuta varias validaciones en la distribución realizada para cumplir con las reglas de negocio
   * @param distList Lista con la distribución realizada
   * @returns objeto con resultado de la validación
   */
  private validateDistribution(distList: ListadoDistribucion[]): any {
    const result = {
      ok: true,
      message: MESSAGES.PROGRAMADOR.DISTR_REGISTER_SUCCESS,
    };

    // Validamos que todos los compartimentos no se encuentren nulos
    if (_.findIndex(distList, { VBELN: null }) !== -1) {
      result.message = MESSAGES.PROGRAMADOR.ERROR_EMPTY;
      result.ok = false;
      return result;
    }

    // Validamos si el pedido posee más de un material, si es así verificamos que ambos materiales
    // cuenten con distribución
    if (this.order.DETALLE_PEDIDO.length > 1) {
      // var i = 0
      for (const detalle of this.order.DETALLE_PEDIDO) {
        const findOrder = _.find(distList, { MATNR: detalle.MATNR });

        if (findOrder === undefined) {
          result.message = _.replace(
            MESSAGES.PROGRAMADOR.ERROR_NO_ORDER_EMPTY,
            "{mat}",
            detalle.MAKTX
          );
          result.ok = false;
          return result;
          // var op = "E_" + i
          // var order = {"KWMENG":"0", "MAKTX":detalle.MAKTX, "MATNR":detalle.MATNR, "MOTIVO":"", "OP":op, "VBELN":detalle.VBELN}
          // distList.push(order)
        }
        // i++
      }
    }

    // Validamos que la sumatoria de la distribución no supere el pedido
    for (const detalle of this.order.DETALLE_PEDIDO) {
      const totalOrder: number = detalle.KWMENG.split(".")[0] * 1;
      const detMatnr = detalle.MATNR;
      const material = detalle.MAKTX;
      let totalDistr = 0;
      for (const dist of distList) {
        if (dist.MATNR === detMatnr) {
          totalDistr = totalDistr + Number(dist.KWMENG);
        }
      }
      if (totalDistr > totalOrder) {
        result.message = _.replace(
          MESSAGES.PROGRAMADOR.ERROR_DISTR_EXCEEDS_ORDER,
          "{mat}",
          material
        );
        result.ok = false;
        return result;
      }
    }

    return result;
  }

  checkRadio(op: string, maktx: string): boolean {
    let check = false;
    const resultado = _.filter(this.listadoDistribucion, {
      OP: "E_" + op,
      MAKTX: maktx,
      VBELN: this.order.VBELN,
    });
    if (resultado.length > 0) {
      check = true;
    }
    return check;
  }

  desconfirmarPedido() {
    // console.log('Desconfirmando Pedido');
    var plantDate = new Date(
      this.utils.swapDate(this.programador.plantData.date) + "T00:00:00"
    );
    var today = new Date();
    var commentAndReasonRequired = false;

    if (this.utils.sameDay(today, plantDate)) {
      commentAndReasonRequired = true;
    }

    this.errorNoSelectUncorfirm = false;

    if (this.motivoDesconfirmar == -1) {
      this.errorNoSelectUncorfirm = true;
      return true;
    }
    var infoDesconfirmar = {};
    infoDesconfirmar["VBELN"] = this.order["VBELN"];
    infoDesconfirmar["VBTYP"] = this.order["VBTYP"];
    infoDesconfirmar["MOTIVO"] = ""; // sacarla de motivos SAP
    infoDesconfirmar["SUBRC"] = "";
    infoDesconfirmar["MESSAGE"] = "";
    // infoDesconfirmar["DURACION_CALCULA"] = ""
    infoDesconfirmar["TIPO"] = "P";
    infoDesconfirmar["OBSERVACION"] = this.comentariosUncorfirm;

    this.order.UNCONFIRM_MOTIVE = this.motivoDesconfirmar;
    this.order.UNCONFIRM_COMMENT = this.comentariosUncorfirm;

    infoDesconfirmar["OBJETO_PEDIDO"] = [];
    infoDesconfirmar["OBJETO_PEDIDO"].push(this.order);
    //infoDesconfirmar['DETALLE_PROGRAMADO'].push(this.order);
    var dataDesconfirmar = [];
    dataDesconfirmar.push(infoDesconfirmar);
    // entrego array.
    this.programador.deconfirm(dataDesconfirmar);
    if (this.order.DINAMO == "X") {
      this.order.DETALLE_PROGRAMADO = this.order.DETALLE;
      this.order.DETALLE_PEDIDO.forEach((e) => {
        e["BMENG"] = e["KWMENG"];
      });
    }
    this.close();
  }

  darBajaPedido() {
    this.errorNoSelectDarBaja = false;
    this.errorCommentsDarBaja = false;
    this.errorCommentsDarBajaContent = false;

    this.programador.darBaja(
      this.order,
      this.comentariosDarBaja,
      this.motivoBaja
    );

    if (this.order["overlapped"] == true) {
      this.order["overlapped"] = false;
      this.programador.overlapped_dict[this.order["VBELN"]].forEach((e) => {
        // console.log(e);
        e["overlapped"] = false;
        e["ERRORS"] = " ";
        e["ERROR_MESSAGE"] = " ";
        delete this.programador.overlapped_dict[e["VBELN"]];
      });

      delete this.programador.overlapped_dict[this.order["VBELN"]];
      // console.log('Overlapped Dict');
      // console.log(this.programador.overlapped_dict);
    }
    // Al bajar un pedido se resetean los valores de redistribución total
    this.order.DETALLE_PROGRAMADO = this.order.DETALLE;

    // Al bajar un pedido se resetean los valores de redistribución por material
    this.order.DETALLE_PEDIDO.forEach(
      (material: {
        VBELN: String;
        MATNR: String;
        MAKTX: String;
        KWMENG: String;
        MEINS: String;
        BMENG: String;
        VRKME: String;
      }) => {
        material.BMENG = material.KWMENG;
      }
    );

    /**
     * Eliminamos la distribución de la sesion en caso de que se encuentre
     */
    let distrPedidos: any = this.sessionService.getDistrOrders();
    // tslint:disable-next-line: prefer-for-of
    if (distrPedidos !== null) {
      // TODO: RACG Verificar si hay que limpiar los compartimentos sin carga antes de guardar la distribución
      _.remove(distrPedidos, (remover) => {
        return remover.VBELN === this.order.VBELN;
      });
    }
    this.sessionService.saveDistrOrders(distrPedidos);
    this.close();
  }

  close() {
    $("#order-details-modal").modal("hide");
  }

  textAreaDarBajaChanged() {
    this.errorCommentsDarBaja = false;
    this.errorCommentsDarBajaContent = false;
  }

  selectChangedDarBaja() {
    this.errorCommentsDarBaja = false;
    this.errorCommentsDarBajaContent = false;
  }

  selectChangedUnConfirm() {
    this.errorCommentsUncorfirm = false;
    this.errorCommentsUncorfirmContent = false;
  }

  textAreaUnConfirmChanged() {
    this.errorCommentsUncorfirm = false;
    this.errorCommentsUncorfirmContent = false;
  }

  ngOnDestroy(): void {
    window.removeEventListener("keyup", this.eventListener);
  }
}
