import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalDetailsOrderComponent } from "./modal-details-order/modal-details-order.component";
import { ModalShiftSwitchComponent } from "./modal-shift-switch/modal-shift-switch.component";
import { ModalDetailsTruckComponent } from "./modal-details-truck/modal-details-truck.component";
import { ModalNuevoPedidoCombinadoComponent } from "./modal-nuevo-pedido-combinado/modal-nuevo-pedido-combinado.component";
import { ModalMontarPedidosComponent } from "./modal-montar-pedidos/modal-montar-pedidos.component";
import { ModalDetallePedidoCombinadoComponent } from "./modal-detalle-pedido-combinado/modal-detalle-pedido-combinado.component";
import { ModalNoProgramedOrdersCommentsComponent } from "./modal-no-programed-orders-comments/modal-no-programed-orders-comments.component";
import { ModalCleanButtonComponent } from "./modal-clean-button/modal-clean-button.component";
import { ModalCambioPlantafechaComponent } from "./modal-cambio-plantafecha/modal-cambio-plantafecha.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LeyendaModalComponent } from "./leyenda-modal/leyenda-modal.component";
import { ModalAdminEditComponent } from "./modal-admin-edit/modal-admin-edit.component";
import { ModalForDeleteComponent } from "./modal-for-delete/modal-for-delete.component";
import { ModalEditParametrosComponent } from "./modal-edit-parametros/modal-edit-parametros.component";
import { ModalConfirmChangePlantComponent } from "./modal-confirm-change-plant/modal-confirm-change-plant.component";
import { ModalForImputationsComponent } from "./modal-for-imputations/modal-for-imputations.component";
import { ModalConstraintCapacidadComponent } from "./modal-constraint-capacidad/modal-constraint-capacidad.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalConstraintFlotaComponent } from "./modal-constraint-flota/modal-constraint-flota.component";
import { ModalConstraintHorarioComponent } from "./modal-constraint-horario/modal-constraint-horario.component";
import { ModalConstraintMotoCuentaComponent } from "./modal-constraint-moto-cuenta/modal-constraint-moto-cuenta.component";
import { FirstWordTitleCasePipe } from "../global/pipes/first-word-title-case.pipe";
import { ModalStatusPropuestaComponent } from "./modal-status-propuesta/modal-status-propuesta.component";
import { ModalCloseSessionProgrammateComponent } from "./modal-close-session-programmate/modal-close-session-programmate.component";
import { ModalLargeOrderInfoComponent } from "./modal-large-order-info/modal-large-order-info.component";
import { ModalGetsModificationsComponent } from "./modal-gets-modifications/modal-gets-modifications.component";

@NgModule({
  declarations: [
    ModalDetailsOrderComponent,
    ModalDetailsTruckComponent,
    ModalShiftSwitchComponent,
    ModalDetailsTruckComponent,
    ModalNuevoPedidoCombinadoComponent,
    ModalMontarPedidosComponent,
    ModalDetallePedidoCombinadoComponent,
    ModalNoProgramedOrdersCommentsComponent,
    ModalCleanButtonComponent,
    ModalCambioPlantafechaComponent,
    LeyendaModalComponent,
    ModalAdminEditComponent,
    ModalForDeleteComponent,
    ModalEditParametrosComponent,
    ModalConfirmChangePlantComponent,
    ModalForImputationsComponent,
    ModalConstraintCapacidadComponent,
    ModalConstraintFlotaComponent,
    ModalConstraintHorarioComponent,
    ModalConstraintMotoCuentaComponent,
    FirstWordTitleCasePipe,
    ModalStatusPropuestaComponent,
    ModalCloseSessionProgrammateComponent,
    ModalLargeOrderInfoComponent,
    ModalGetsModificationsComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  entryComponents: [
    ModalDetailsOrderComponent,
    ModalShiftSwitchComponent,
    ModalDetailsTruckComponent,
    ModalNuevoPedidoCombinadoComponent,
    ModalMontarPedidosComponent,
    ModalDetallePedidoCombinadoComponent,
    ModalNoProgramedOrdersCommentsComponent,
    ModalCleanButtonComponent,
    ModalCambioPlantafechaComponent,
    LeyendaModalComponent,
    ModalAdminEditComponent,
    ModalForDeleteComponent,
    ModalEditParametrosComponent,
    ModalConfirmChangePlantComponent,
    ModalForImputationsComponent,
    ModalConstraintCapacidadComponent,
    ModalConstraintFlotaComponent,
    ModalConstraintHorarioComponent,
    ModalConstraintMotoCuentaComponent,
    ModalStatusPropuestaComponent,
    ModalCloseSessionProgrammateComponent,
    ModalLargeOrderInfoComponent,
    ModalGetsModificationsComponent,
  ],
})
export class ModalsModule {}
