import { Injectable } from "@angular/core";
import { SessionService } from "src/app/global/services/session.service";
import { SharedDataService } from "src/app/global/services/shared-data.service";
import _ from "lodash";
import { KEYS } from "src/app/global/keys/keys";

@Injectable({
  providedIn: "root",
})
export class ProgramadorFunctions {
  constructor(
    private sharedService: SharedDataService,
    private sessionService: SessionService
  ) {}

  // los turnos vienen ordenados (quicksort) desde el server, por lo que si hay dos turnos,
  // el primero cronologicamente esta antes que el segundo
  getShiftT1(shifts) {
    // // console.log("T1")
    const plantData = this.sharedService.getPlantInfo();
    const todayShifts = shifts.filter((e) => e.dayInit == plantData.date);
    // // console.log(todayShifts)
    if (todayShifts.length == 0) {
      return -1;
    }
    // retornamos el primero, que es el menor en tiempo
    return todayShifts[0];
  }

  getShiftT2(shifts) {
    const plantData = this.sharedService.getPlantInfo();
    const todayShifts = shifts.filter((e) => e.dayInit == plantData.date);
    if (todayShifts.length > 1) {
      return todayShifts[1];
    }
    return -1;
  }

  /**
   * Genera el listado de distribución retornando un objeto con la estructura requerida por el servicio
   * guardar/confirmar.
   * @returns Listado con la distribución de combustible para pedidos Dínamo
   */
  addDistrList() {
    const dataDistribucion: any = this.sessionService.getDistrOrders();
    if (dataDistribucion !== null && dataDistribucion.length > 0) {
      const newDataDistribucion = _.map(dataDistribucion, (data) => {
        return {
          VBELN: data.VBELN,
          MATNR: data.MATNR,
          MAKTX: data.MAKTX,
          KWMENG: data.KWMENG,
          MOTIVO: data.MOTIVO,
        };
      });
      return newDataDistribucion;
    } else {
      return {};
    }
  }

  /**
   * Elimina el registro de sesión donde se almacena el listado de distribución.
   */
  delDistrListReg(): void {
    sessionStorage.removeItem(KEYS.SESSION_STORAGE_KEYS.DISTR_ORDERS);
  }

  esPedidoConfirmado(pedido: any) {
    if (pedido == undefined) {
      return false;
    }
    if (pedido.ESTADO == undefined) {
      return false;
    } else if (pedido.ESTADO == "3") {
      return true;
    }
    return false;
  }

  esPedidoProgramado(pedido: any) {
    if (pedido == undefined) {
      // console.log('esPedidoProgramado detecta pdeido undefined. Puede ser tema de asincronia mientras se carga pedido');
      return false;
    }
    if (pedido.ESTADO == undefined) {
      return false;
    } else if (
      pedido.ESTADO == "1" &&
      pedido.VEHICLE.trim() != this.emptyValue().trim()
    ) {
      return true;
    }
    return false;
  }

  esPedidoNoProgramado(pedido: any) {
    if (pedido == undefined) {
      // console.log('esPedidoNoProgramado detecta pdeido undefined. Puede ser tema de asincronia mientras se carga pedido');
      return false;
    }
    if (pedido.ESTADO == undefined) {
      return false;
    } else if (
      pedido.ESTADO == "1" &&
      pedido.VEHICLE.trim() == this.emptyValue().trim()
    ) {
      return true;
    }
    return false;
  }

  private emptyValue() {
    return " ";
  }
}
