/**
 * endpoints de la API
 * DEFAULTPLANT corresponde a la planta por defecto en restricciones y tiempos de viaje. Cambiar aquí si se srequiere otra planta por defecto
 */

export const ENDPOINT_ADMINISTRADOR = {
  ADMINS: "copec-api-planificador-administrators",
  PARAMETERS: "copec-api-planificador-parameters",
  CONSTRAINTS: "copec-api-planificador-constraints",
  CONSTRAINTS_MASTER: "copec-api-planificador-constraints-master",
  IMPUTATIONS: "copec-api-planificador-imputations",
  SPREADSHEET: "/copec-api-planificador-downloads",
  SESSION_STATE: "/copec-api-planificador-session-state",
};

export const DEFAULTPLANT = "1201";
