import { Injectable } from "@angular/core";

export interface AlertInfo {
  header: string;
  body: string;
  code?: number;
  delay?: number;
}

@Injectable({
  providedIn: "root",
})
export class AlertService {
  /**
   * Servicio para desplegar alertas
   * El método show muestra las alertas, dándole la orden de quitarla pasados 5 segundos (en ms). Para acortar o ampliar este tiempo, modificar el valor
   *  línea 29 (en ms).
   *
   * El método setAlert( a ) retorna un mensaje esperado de acuerdo al código recibido en la respuesta de cada solicitud http.
   */

  alerts: AlertInfo[] = [];

  show(alert: AlertInfo) {
    // // console.log(alert)
    this.alerts.push(alert);
    setTimeout(() => {
      this.remove(alert);
    }, 5000);
  }

  remove(alert: AlertInfo) {
    this.alerts = this.alerts.filter((t) => t != alert);
  }

  setAlert(val) {
    let response;
    switch (val) {
      case 200:
        response = "Operación completada";
        break;
      case 400:
        response = "Operación fallida - Error en la consulta";
        break;
      case 500:
        response = "Error en el servidor";
        break;
      default:
        response = "Error inesperado";
        break;
    }
    return response;
  }
}
