import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-confirm-change-plant",
  templateUrl: "./modal-confirm-change-plant.component.html",
  styleUrls: ["./modal-confirm-change-plant.component.css"],
})
export class ModalConfirmChangePlantComponent implements OnInit {
  @Input() plantCode;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    // console.log(this.plantCode)
  }

  accept() {
    this.activeModal.close("confirm");
  }

  cancel() {
    this.activeModal.close("cancel");
  }
}
