import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild,
  Route,
  UrlSegment,
} from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "../services/session.service";

@Injectable({
  providedIn: "root",
})
export class AdministradorGuard
  implements CanActivate, CanLoad, CanActivateChild
{
  constructor(private session: SessionService, private router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const loginData = this.session.getLoginData();
    const roles = loginData.roles;

    if (
      loginData !== null &&
      loginData.user_name !== null &&
      loginData.isAdmin
    ) {
      const res = this.checkRouteRole(childRoute, state, "roleEdit", roles);
      return res;
    } else if (
      loginData !== null &&
      loginData.user_name !== null &&
      loginData.readerOnly
    ) {
      const res = this.checkRouteRole(childRoute, state, "roleRead", roles);
      return res;
    }
    // this.router.navigate(['/selector'])
    // return false;
  }

  canLoad(route?: Route): boolean {
    return this.accessGranted();
  }

  canActivate(next?: ActivatedRouteSnapshot): boolean {
    return this.accessGranted();
  }

  accessGranted() {
    const loginData = this.session.getLoginData();
    if (
      loginData !== null &&
      loginData.user_name !== null &&
      loginData.roles !== null
    ) {
      return true;
    } else {
      this.router.navigate(["/selector"]);
      return false;
    }
  }

  checkPropuestas(param: string): boolean {
    return param.includes("propuestas");
  }

  checkRouteRole(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    param,
    roles
  ): boolean {
    const propuestas = this.checkPropuestas(state.url);
    if (propuestas) {
      return true;
    }

    if (roles.indexOf(childRoute.data[param]) === -1) {
      this.router.navigate(["/selector"]);
      return false;
    }
    return true;
  }
}
