import { Component, Input, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedDataService } from "src/app/global/services/shared-data.service";
import { Admin } from "../../pages/administrador/models/requests.model";
import {
  OPERACIONES,
  ERRORINESPERADO,
} from "../../pages/administrador/constants/operations.const";
import { ApiAdministradorService } from "../../pages/administrador/services/api-administrador.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-modal-admin-edit",
  templateUrl: "./modal-admin-edit.component.html",
  styleUrls: ["./modal-admin-edit.component.css"],
})
export class ModalAdminEditComponent implements OnInit {
  @Input() values;
  admin;
  edit: boolean = false;
  changeEdit: boolean;

  rolesData = [
    "Tiempos de Viaje",
    "Restricciones",
    "Parámetros",
    "Administradores",
    "Sesiones",
  ];

  adminData = this.fb.group({
    username: ["", Validators.required],
    roles: this.fb.array([], this.seleccionMinima(1)),
  });

  get rolesAsArray() {
    return this.adminData.controls.roles as FormArray;
  }

  constructor(
    private shared: SharedDataService,
    private api: ApiAdministradorService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.admin = this.shared.getLoginInfo();

    if (this.values) {
      this.loadInfo();
      this.edit = true;
    } else {
      this.addChecks();
      this.edit = false;
    }
    this.spinner.hide();
  }

  loadInfo() {
    this.adminData.get("username").setValue(this.values["Usuario SAP"]);
    this.addChecks();
    this.rolesData.forEach((rol, idx) => {
      // console.log(this.rolesData.indexOf(this.values['Permisos'][idx]))
      // console.log(this.values['Permisos'][idx])
      if (this.rolesData.indexOf(this.values["Permisos"][idx]) > -1) {
        this.rolesAsArray
          .at(this.rolesData.indexOf(this.values["Permisos"][idx]))
          .patchValue(true);
      }
    });

    if (this.values["Usuario SAP"] != "") {
      this.adminData.get("username").disable();
    }
  }

  addChecks() {
    this.rolesData.forEach(() =>
      this.rolesAsArray.push(new FormControl(false))
    );
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      // console.log(e.target.value)
      this.rolesAsArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      this.rolesAsArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          this.rolesAsArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  campoValido(campo: string) {
    return (
      this.adminData.controls[campo].errors &&
      this.adminData.controls[campo].touched
    );
  }

  seleccionMinima(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map((control) => control.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  submit() {
    this.adminData.pristine
      ? (this.changeEdit = true)
      : (this.changeEdit = false);

    if (this.adminData.invalid || this.adminData.pristine) {
      this.adminData.markAllAsTouched();
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    const selectedRoles = this.adminData.value.roles
      .map((checked, i) => (checked ? this.rolesData[i] : null))
      .filter((v) => v !== null);

    const data: Admin = {
      user: this.admin.user_name,
      operation: "",
      payload: {
        sap_user: this.adminData.get("username").value,
        roles: selectedRoles,
      },
    };

    this.edit
      ? (data.operation = OPERACIONES.update)
      : (data.operation = OPERACIONES.create);

    this.sendAdmin(data);
  }

  sendAdmin(payload: Admin) {
    this.api.admins(payload).subscribe((resp) => {
      if (resp != null) {
        const { code, message } = resp;
        this.activeModal.close({ code, message });
      } else {
        this.activeModal.close(ERRORINESPERADO);
      }
    });
  }
}
