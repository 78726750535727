import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

declare var $: any;

@Component({
  selector: "app-leyenda-modal",
  templateUrl: "./leyenda-modal.component.html",
  styleUrls: ["./leyenda-modal.component.css"],
})
export class LeyendaModalComponent implements OnInit {
  showDinamo = environment.showDinamoFunctionalities;

  constructor() {}

  ngOnInit() {
    window.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.code == "Escape") {
        $("#leyenda-modal").modal("hide");
      }
    });
  }

  show() {
    $("#leyenda-modal").modal("show");
  }
}
