import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import _ from "lodash";
import { SharedDataService } from "../services/shared-data.service";
import { GLOBAL } from "../constants/global.const";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BearerInterceptor implements HttpInterceptor {
  constructor(private sharedService: SharedDataService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      _.includes(req.url, "/sap-integ-login") ||
      _.includes(req.url, GLOBAL.SIGNED_URL)
    ) {
      req = req.clone();
      return next.handle(req);
    } else if (_.includes(req.url, environment.optimizerUrl)) {
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Acess-Control-Allow-Headers":
          "X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method",
        Allow: "GET, POST, OPTIONS, PUT, DELETE",
      });

      req = req.clone({ headers });
      return next.handle(req);
    } else {
      const token = this.sharedService.getLoginInfo().bearer;
      const headers = new HttpHeaders({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      });

      req = req.clone({ headers });
      return next.handle(req);
    }
  }
}
