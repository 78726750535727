export const KEYS = {
  CRYPTO: "&E)H@McQeThWmZq4t7w!z%C*F-JaNdRgUkXn2r5u8x/A?D(G+KbPeShVmYq3s6v9",
  SESSION_STORAGE_KEYS: {
    LOGIN_DATA: "loginData",
    PLANT_LIST: "plantList",
    PLANT_INFO: "plantInfo",
    MOTIVES: "motivos",
    DISTR_ORDERS: "distrPedidos",
    ORDERS_MOTIVES: "ordersMotives",
  },
};
