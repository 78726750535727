import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
} from "@angular/core";
import { ProgramadorComponent } from "../../programador.component";
import { MinuteCellComponent } from "../minute-cell/minute-cell.component";

@Component({
  selector: "cell",
  templateUrl: "./cell.component.html",
  styleUrls: ["./cell.component.css"],
})
export class CellComponent implements OnInit {
  @Input() truck: string;
  @Input() hour: string;
  @Input() date: string;

  @ViewChild("cell_0", { static: false, read: ViewContainerRef })
  containerCell_0;
  @ViewChild("cell_30", { static: false, read: ViewContainerRef })
  containerCell_30;

  cell_0: any;
  cell_30: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public programador: ProgramadorComponent
  ) {}

  ngOnInit() {}

  /*
  ngAfterViewInit(){
    this.buildMinuteCells()
  }

  clean(){
    this.cell_0.destroy()
    this.cell_30.destroy()
    this.cell_0.instance = undefined;
    this.cell_30.instance = undefined;
    //this.cell_0 = undefined;
    //this.cell_30 = undefined;
  }
  //can be called in a refresh
  buildMinuteCells(){
    //if(this.cell_10 == undefined){
      const componentFactory_0 = this.componentFactoryResolver.resolveComponentFactory(MinuteCellComponent);
      this.cell_0 = this.containerCell_0.createComponent(componentFactory_0); //la programacion asociada a la celda
      this.cell_0.instance.init(this.truck,this.date,this.hour,"0",this,this.programador)
    //}

    //if(this.cell_30 == undefined){
      const componentFactory_30 = this.componentFactoryResolver.resolveComponentFactory(MinuteCellComponent);
      this.cell_30 = this.containerCell_30.createComponent(componentFactory_30); //la programacion asociada a la celda
      this.cell_30.instance.init(this.truck,this.date,this.hour,"30",this,this.programador)
    //}
    //this.componentRefOrderCombinadoModal.instance.setOrderList(order)
    //this.componentRefOrderCombinadoModal.instance.show();
  }
  */
}
