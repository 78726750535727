import { Component } from "@angular/core";
import { ProgramadorComponent } from "../../pages/programador/programador.component";
import { SubscribersProgramadorServices } from "src/app/pages/programador/services/subscribers.programador.services";

declare var $: any;

@Component({
  selector: "app-modal-gets-modifications",
  templateUrl: "./modal-gets-modifications.component.html",
  styleUrls: ["./modal-gets-modifications.component.css"],
})
export class ModalGetsModificationsComponent {
  programador: ProgramadorComponent;
  detailsModificacions: any[];
  pedidosModificados: any[];

  constructor(private spservices: SubscribersProgramadorServices) {}

  setProgramador(programador: ProgramadorComponent) {
    this.programador = programador;
  }

  show() {
    $("#details-gets-modifications-modal").modal("show");
  }
  // close() {
  //   $('#details-gets-modifications-modal').modal('hide');
  // }

  aceptar() {
    this.spservices.modalToProgramador();
  }

  setDetailsModificacions(details, pedidos) {
    console.log({ details, pedidos });
    this.detailsModificacions = details;
    this.pedidosModificados = pedidos;

    for (var i = 0; i < details.length; i++) {
      if ("procesado" in details[i]) {
        return;
      }

      details[i].procesado = true;

      details[i].fecha_inicio = new Date(details[i].fecha_inicio);
      details[i].fecha_inicio = details[i].fecha_inicio.toLocaleString();

      details[i].fecha_fin = new Date(details[i].fecha_fin);
      details[i].fecha_fin = details[i].fecha_fin.toLocaleString();

      details[i].fecha_inicio_anterior = new Date(
        details[i].fecha_inicio_anterior
      );
      details[i].fecha_inicio_anterior =
        details[i].fecha_inicio_anterior.toLocaleString();

      details[i].fecha_fin_anterior = new Date(details[i].fecha_fin_anterior);
      details[i].fecha_fin_anterior =
        details[i].fecha_fin_anterior.toLocaleString();
    }
  }
}
