export const PROGRAMADOR_CONST = {
  HIGH_LIGHT_COLOR: "#DCDCDC",
  SPINNER_TEXT: "Por favor espere...",
  SPINNER_TEXT_WAIT_ORDERS_CONFIRMATION:
    "Por favor espere a que se confirmen los pedidos",
  SPINNER_TEXT_SUCCESS_SAP:
    "Respuesta exitosa de SAP. Actualizando Programador...",
  RATIO: 0.8,
  UNIT_TIME: 30,
  HOURS_YESTERDAY: 10,
  HOURS_DAY: 24,
  HOURS_TOMORROW: 10,
  FILTROS_CAMION: [
    { id: 1, name: "Volumen" },
    { id: 2, name: "Tipo producto" },
    { id: 3, name: "Número camión" },
  ],
  SOLVENTES_LIST: [
    "1203",
    "1206",
    "1335",
    "1336",
    "1261",
    "1265",
    "1263",
    "1245",
  ],
  WAIT_SEC: 3,
  MAX_TRIES: 100,
};
